export default (context, inject) => {
  var theHelp = Chart.helpers;
  const constants = {
    CHART_OPTIONS: {
      HORIZONTAL: {
        responsive: true,
        maintainAspectRatio: false,
        title: {
          display: false,
        },
        legend: {
          display: false,
        },
        scales: {
          yAxes: [
            {
              ticks: {
                suggestedMin: 0,
                stepSize: 10,
                callback: (value, index, values) => {
                  return value;
                },
                fontColor: "rgba(255, 255, 255, 0.8)",
              },
              gridLines: {
                zeroLineColor: "rgba(255, 255, 255, 0.2)",
                color: "rgba(255, 255, 255, 0.2)",
                fontColor: "rgba(255, 255, 255, 0.8)",
                borderDash: [3],
              },
            },
          ],
          xAxes: [
            {
              ticks: {
                suggestedMin: 0,
                stepSize: 10,
                callback: (value, index, values) => {
                  return value;
                },
                fontColor: "rgba(255, 255, 255, 0.8)",
              },
              gridLines: {
                zeroLineColor: "rgba(255, 255, 255, 0.2)",
                color: "rgba(255, 255, 255, 0.2)",
                borderDash: [3],
              },
            },
          ],
        },
        plugins: {
          colorschemes: {
            scheme: "tableau.Blue20",
          },
        },
      },
      BAR: {
        responsive: true,
        maintainAspectRatio: false,
        title: {
          display: false,
        },
        legend: {
          display: false,
        },
        scales: {
          yAxes: [
            {
              ticks: {
                suggestedMin: 0,
                stepSize: 20000,
                callback: (value, index, values) => {
                  return value + "t";
                },
                fontColor: "rgba(9, 31, 92, 0.8)",
              },
              gridLines: {
                zeroLineColor: "rgba(255, 255, 255, 0.2)",
                color: "rgba(96, 125, 139, 0.2)",
                fontColor: "rgba(255, 255, 255, 0.8)",
                borderDash: [3],
              },
            },
          ],
          xAxes: [
            {
              ticks: {
                suggestedMin: 0,
                stepSize: 0,
                callback: (value, index, values) => {
                  return value;
                },
                fontColor: "rgba(9, 31, 92, 0.8)",
              },
              gridLines: {
                zeroLineColor: "rgba(255, 255, 255, 0.2)",
                color: "rgba(96, 125, 139, 0.2)",
                borderDash: [3],
              },
            },
          ],
        },
        plugins: {
          colorschemes: {
            scheme: "brewer.SetThree12",
          },
        },
      },
      STACKED_BAR: (stepSize = 50000000) => {
        const res = {
          responsive: true,
          maintainAspectRatio: false,
          title: {
            display: false,
          },
          legend: {
            display: false,
          },
          scales: {
            yAxes: [
              {
                stacked: true,
                ticks: {
                  suggestedMin: 0,
                  stepSize: stepSize,
                  callback: (value, index, values) => {
                    return value + "t";
                  },
                  fontColor: "rgba(9, 31, 92, 0.8)",
                },
                gridLines: {
                  zeroLineColor: "rgba(255, 255, 255, 0.2)",
                  color: "rgba(96, 125, 139, 0.2)",
                  fontColor: "rgba(255, 255, 255, 0.8)",
                  borderDash: [3],
                },
              },
            ],
            xAxes: [
              {
                stacked: true,
                ticks: {
                  suggestedMin: 0,
                  stepSize: 0,
                  callback: (value, index, values) => {
                    return value;
                  },
                  fontColor: "rgba(9, 31, 92, 0.8)",
                },
                gridLines: {
                  zeroLineColor: "rgba(255, 255, 255, 0.2)",
                  color: "rgba(96, 125, 139, 0.2)",
                  borderDash: [3],
                },
              },
            ],
          },
          plugins: {
            colorschemes: {
              scheme: "brewer.SetThree12",
            },
          },
          tooltips: {
            callbacks: {
              label: (tooltipItem, data) => {
                const prefix = data.datasets[tooltipItem.datasetIndex].label;
                const value = context.$utils.formatFixed(
                  data.datasets[tooltipItem.datasetIndex].data[tooltipItem.index],
                  3
                );
                let label = `${prefix}: ${value}`;
                return label;
              },
            },
          },
        }
        return res
      },
      LINE: {
        responsive: true,
        maintainAspectRatio: false,
        title: {
          display: false,
        },
        legend: {
          display: true,
          labels: {
            fontColor: "rgba(255, 255, 255, 0.8)",
          },
        },
        scales: {
          yAxes: [
            {
              ticks: {
                suggestedMin: 0,
                stepSize: 200,
                callback: (value, index, values) => {
                  return value;
                },
                fontColor: "rgba(255, 255, 255, 0.8)",
              },
              gridLines: {
                zeroLineColor: "rgba(255, 255, 255, 0.2)",
                color: "rgba(255, 255, 255, 0.2)",
                fontColor: "rgba(255, 255, 255, 0.8)",
                borderDash: [3],
              },
            },
          ],
          xAxes: [
            {
              ticks: {
                suggestedMin: 0,
                stepSize: 10,
                callback: (value, index, values) => {
                  return value;
                },
                fontColor: "rgba(255, 255, 255, 0.8)",
              },
              gridLines: {
                zeroLineColor: "rgba(255, 255, 255, 0.2)",
                color: "rgba(255, 255, 255, 0.2)",
                borderDash: [3],
              },
            },
          ],
        },
        plugins: {
          colorschemes: {
            scheme: "brewer.Greens7",
          },
        },
      },
      PIE: (limit, lengthOfLabel, position = "right", openPieChart = () => { }, usePlugin = false, useDefaultTooltip = false) => {
        const res = {
          onClick: (event, element) => {
            if (element.length > 0) {
              openPieChart(element)
            }
          },
          responsive: true,
          maintainAspectRatio: false,
          title: {
            display: false,
          },
          legend: {
            display: usePlugin ? false : true,
            position: position,
            labels: {
              generateLabels: function (chart) {
                var data = chart.data;
                if (data.labels.length && data.datasets.length) {
                  return data.labels.map(function (label, i) {
                    var meta = chart.getDatasetMeta(0);
                    var ds = data.datasets[0];
                    var arc = meta.data[i];
                    var custom = (arc && arc.custom) || {};
                    var getValueAtIndexOrDefault =
                      theHelp.getValueAtIndexOrDefault;
                    var arcOpts = chart.options.elements.arc;
                    var fill = custom.backgroundColor
                      ? custom.backgroundColor
                      : getValueAtIndexOrDefault(
                        ds.backgroundColor,
                        i,
                        arcOpts.backgroundColor
                      );
                    var stroke = custom.borderColor
                      ? custom.borderColor
                      : getValueAtIndexOrDefault(
                        ds.borderColor,
                        i,
                        arcOpts.borderColor
                      );
                    var bw = custom.borderWidth
                      ? custom.borderWidth
                      : getValueAtIndexOrDefault(
                        ds.borderWidth,
                        i,
                        arcOpts.borderWidth
                      );
                    return {
                      text:
                        lengthOfLabel !== undefined
                          ? label.substring(0, lengthOfLabel)
                          : label,
                      fillStyle: fill,
                      strokeStyle: stroke,
                      lineWidth: bw,
                      hidden: isNaN(ds.data[i]) || meta.data[i].hidden,
                      index: i,
                    };
                  });
                }
                return [];
              },
              filter: (item, chart) => {
                if (limit === undefined) return true;
                return item.index < limit;
              },
            },
          },
          gridLines: {
            display: false,
          },
          plugins: {
            colorschemes: {
              scheme: "brewer.SetThree12",
            },
          },
          tooltips: {
            enabled: useDefaultTooltip,
            custom: function (tooltipModel) {
              if (useDefaultTooltip) return
              // Tooltip Element
              var tooltipEl = document.getElementById('chartjs-tooltip');
              // Create element on first render
              if (!tooltipEl) {
                tooltipEl = document.createElement('div');
                tooltipEl.id = 'chartjs-tooltip';
                tooltipEl.innerHTML = '<table></table>';
                document.body.appendChild(tooltipEl);
              }

              // Hide if no tooltip
              if (tooltipModel.opacity === 0) {
                tooltipEl.style.opacity = 0;
                return;
              }

              // Set caret Position
              tooltipEl.classList.remove('above', 'below', 'no-transform');
              if (tooltipModel.yAlign) {
                tooltipEl.classList.add(tooltipModel.yAlign);
              } else {
                tooltipEl.classList.add('no-transform');
              }

              function getBody(bodyItem) {
                return bodyItem.lines;
              }

              // Set Text
              if (tooltipModel.body) {
                var titleLines = tooltipModel.title || [];
                var bodyLines = tooltipModel.body.map(getBody);

                var innerHtml = '<thead>';

                titleLines.forEach(function (title) {
                  innerHtml += '<tr><th>' + title + '</th></tr>';
                });
                innerHtml += '</thead><tbody>';

                bodyLines.forEach(function (body, i) {
                  const b = `<span>${body}</span>`
                  var colors = tooltipModel.labelColors[0];
                  var style = 'background:' + colors.backgroundColor;
                  style += '; border-color:' + colors.borderColor;
                  style += '; border: 1px solid;width: 13px; height: 13px;display: inline-flex';
                  var span = '<span style="' + style + '"></span>';
                  innerHtml += '<tr><td style="color: white;background-color: black;padding:1px 4px;border-radius: 6px"><div style="display:flex;align-items:center">' + span + '&nbsp;' + b + '</div></td></tr>';
                });
                innerHtml += '</tbody>';

                var tableRoot = tooltipEl.querySelector('table');
                tableRoot.innerHTML = innerHtml;
              }

              // `this` will be the overall tooltip
              var position = this._chart.canvas.getBoundingClientRect();

              // Display, position, and set styles for font
              tooltipEl.style.opacity = 1;
              tooltipEl.style.position = 'absolute';
              tooltipEl.style.left = position.left + window.pageXOffset + tooltipModel.caretX + 'px';
              tooltipEl.style.top = position.top + window.pageYOffset + tooltipModel.caretY + 'px';
              tooltipEl.style.fontFamily = tooltipModel._bodyFontFamily;
              tooltipEl.style.fontSize = tooltipModel.bodyFontSize + 'px';
              tooltipEl.style.fontStyle = tooltipModel._bodyFontStyle;
              tooltipEl.style.padding = tooltipModel.yPadding + 'px ' + tooltipModel.xPadding + 'px';
              tooltipEl.style.pointerEvents = 'none';
            },
            callbacks: {
              label: (tooltipItem, data) => {
                const prefix = data.labels[tooltipItem.index];
                console.log("context", context.app.i18n.messages[context.app.i18n.locale].user_registration.user_id)
                const value = context.$utils.formatFixed(
                  data.datasets[0].data[tooltipItem.index],
                  3
                );
                let label = `${prefix}: ${value}`;
                return label;
              },
            },
          },
        };
        return res;
      },
    },
    SCOPE_ONE_INPUT_TABLE_HEADER: [
      {
        text: "算定対象",
        value: "activityName",
        width: "20%",
        align: "center",
      },
      {
        text: "算定方法",
        value: "calc_source",
        width: "20%",
        align: "center",
      },
      {
        text: "活動量",
        value: "activity_values",
        width: "30%",
        align: "center",
      },
      {
        text: "排出原単位",
        value: "coef",
        width: "20%",
        align: "right",
      },
      {
        text: "排出量",
        value: "score",
        width: "20%",
        align: "center",
      },
      {
        align: "center",
        text: "",
        value: "action",
        width: "20%",
      },
    ],
    SCOPE_TWO_INPUT_TABLE_HEADER: [
      {
        text: "算定対象",
        value: "activity_name",
        width: "20%",
        align: "center",
      },
      {
        text: "算定方法",
        value: "calc_source",
        width: "10%",
        align: "center",
      },
      {
        text: "電力会社/供給メニュー",
        value: "electric_company",
        width: "20%",
        align: "center",
      },
      {
        text: "活動量",
        value: "activity_values",
        width: "10%",
        align: "center",
      },
      {
        text: "排出原単位",
        value: "coef",
        width: "20%",
        align: "right",
      },
      {
        text: "排出量",
        value: "score",
        width: "20%",
        align: "center",
      },
    ],
    SCOPE_THREE_INPUT_TABLE_HEADER: {
      other: [
        {
          text: "活動名",
          value: "activity_name",
          align: "center",
          width: "20%",
        },
        {
          text: "算定方法",
          value: "calc_source",
          align: "center",
          width: "20%",
        },
        {
          text: "活動量",
          value: "activity_values",
          align: "center",
          width: "30%",
        },
        {
          text: "排出原単位",
          value: "coef",
          align: "right",
          width: "20%",
        },
        {
          text: "排出量",
          value: "score",
          align: "center",
          width: "10%",
        },
      ],
      DB1: [
        {
          text: "活動名",
          value: "activityName",
          align: "left",
          width: "10%",
        },
        {
          text: "算定方法",
          value: "calculationMethod",
          align: "left",
          width: "20%",
        },
        {
          text: "活動量",
          value: "activity_values",
          align: "center",
          width: "45%",
        },
        {
          text: "排出量",
          value: "emissionVolume",
          align: "right",
          width: "15%",
          class: "success--text",
        },
        {
          text: "",
          value: "buttons",
          align: "right",
          width: "10%",
        },
      ],
      DB2: [
        {
          text: "活動名",
          value: "activityName",
          align: "left",
          width: "10%",
        },
        {
          text: "算定方法",
          value: "calculationMethod",
          align: "left",
          width: "20%",
        },
        {
          text: "活動量",
          value: "activity_values",
          align: "center",
          width: "40%",
          remark: "実際の活動量を入力してください",
        },
        {
          text: "排出原単位",
          value: "emissionIntensity",
          align: "right",
          width: "15%",
        },
        {
          text: "排出量",
          value: "emissionVolume",
          align: "right",
          width: "15%",
          class: "success--text",
        },
        {
          text: "",
          value: "buttons",
          align: "right",
          width: "60px",
        },
      ],
      DB3: [
        {
          text: "活動名",
          value: "activityName",
          align: "left",
          width: "10%",
        },
        {
          text: "算定方法",
          value: "calculationMethod",
          align: "left",
          width: "20%",
        },
        {
          text: "活動量",
          value: "activity_values",
          align: "center",
          width: "40%",
          remark: "実際の活動量を入力してください",
        },
        {
          text: "排出原単位",
          value: "emissionIntensity",
          align: "right",
          width: "15%",
        },
        {
          text: "排出量",
          value: "emissionVolume",
          align: "right",
          width: "15%",
          class: "success--text",
        },
        {
          text: "",
          value: "buttons",
          align: "right",
          width: "60px",
        },
      ],
      DB4: [
        {
          text: "活動名",
          value: "activityName",
          align: "left",
          width: "8%",
        },
        {
          text: "算定方法",
          value: "calculationMethod",
          align: "left",
          width: "20%",
        },
        {
          text: "活動量",
          value: "activity_values",
          align: "center",
          width: "60%",
          remark: "実際の活動量を入力してください",
        },
        {
          text: "排出量",
          value: "emissionVolume",
          align: "right",
          width: "10%",
          class: "success--text",
        },
        {
          text: "",
          value: "buttons",
          align: "right",
          width: "60px",
        },
      ],
      DB5: [
        {
          text: "製品名",
          value: "productName",
          align: "left",
        },
        {
          text: "&nbsp;&nbsp;部門名<br/>（産業連関表品目）",
          value: "departmentName",
          align: "left",
          width: "15%",
        },
        {
          text: "算定方法",
          value: "calculationMethod",
          align: "left",
          width: "15%",
        },
        {
          text: "活動量",
          value: "activity_values",
          align: "center",
          width: "25%",
          remark: "Q0006",
        },
        {
          text: "排出原単位",
          value: "emissionIntensity",
          align: "right",
          width: "10%",
        },
        {
          text: "排出量",
          value: "emissionVolume",
          align: "right",
          width: "15%",
          class: "success--text",
        },
        {
          text: "",
          value: "buttons",
          align: "right",
          width: "60px",
        },
      ],
      DB0: [
        {
          text: "活動名",
          value: "activityName",
          align: "left",
          width: "10%",
          remark: "",
        },
        {
          text: "算定方法",
          value: "calculationMethod",
          align: "left",
          width: "25%",
          remark: "",
        },
        {
          text: "活動量",
          value: "activity_values",
          align: "center",
          width: "500px",
          remark: "Q0013",
        },
        {
          text: "排出量",
          value: "emissionVolume",
          align: "right",
          width: "20%",
          class: "success--text",
        },
        {
          text: "",
          value: "buttons",
          align: "right",
          width: "30px",
        },
      ],
      DB8: [
        {
          text: "活動名",
          value: "activityName",
          align: "left",
          width: "12%",
        },
        {
          text: "算定方法",
          value: "calculationMethod",
          align: "left",
          width: "22%",
        },
        {
          text: "廃棄物種別",
          value: "calculationTargetName",
          align: "left",
          width: "18%",
        },
        {
          text: "活動量",
          value: "activity_values",
          align: "center",
          width: "30%",
        },
        {
          text: "排出原単位",
          value: "emissionIntensity",
          align: "center",
          width: "15%",
        },
        {
          text: "排出量",
          value: "emissionVolume",
          align: "right",
          width: "10%",
          class: "success--text",
        },
        {
          text: "",
          value: "buttons",
          align: "right",
          width: "60px",
        },
      ],
      S3_C14: {
        ACTIVITY_HEADERS: [
          {
            text: "活動名",
            value: "productActionName",
            align: "left",
            width: "15%",
          },
          {
            text: "算定方法",
            value: "calculationMethodName",
            align: "left",
            width: "20%",
          },
          {
            text: "活動量",
            value: "activityValues",
            align: "center",
            width: "50%",
          },
          {
            text: "排出量",
            value: "emissionVolume",
            align: "right",
            width: "10%",
            class: "success--text",
          },
          {
            text: "",
            value: "action",
            align: "right",
            width: "60px",
          },
        ],
        SUBCOLUMNS: [
          {
            name: 'inputField1Name',
            model: 'activityVolume',
            rules: 'required|length_comma:10|number_comma_full_width:true|numeric_right:3',
            show: (item) => item.calculationMethodCode === '0904',
            editable: true
          },
          {
            name: 'inputField2Name',
            model: 'emissionIntensity',
            rules: 'required|length_comma:5|number_comma_full_width:true|numeric_right:10',
            show: (item) => item.calculationMethodCode === '0904',
            editable: true
          },
          {
            name: '排出量',
            model: 'activityVolume',
            rules: 'required|length_comma:10|number_comma_full_width:true|numeric_right:3',
            show: (item) => item.calculationMethodCode === '0902',
            editable: true,
            unit: 'activityVolumeUnit'
          },
        ],
        ADD_DIALOG: {
          formValues: {
            productActionName: '',
            calculationMethod: null,
            inputField1Name: '',
            inputField2Name: '',
            memo: '',
            // 値2の項目名の価値
            emissionIntensity: null,
            emissionUnit: null,
          },
          formFields: {
            productActionName: {
              model: 'productActionName',
              rules: 'required|max:100',
              component: 'v-text-field',
              props: {
                label: '活動名',
                type: 'text',
              },
            },
            calculationMethod: {
              model: 'calculationMethod',
              rules: 'required',
              component: 'v-select',
              props: {
                label: '入力方法',
                items: [],
                'item-text': 'name',
                'return-object': true,
              },
            },
            inputField1Name: {
              model: 'inputField1Name',
              rules: 'required|max:100',
              component: 'v-text-field',
              show: (data) => data.formValues.calculationMethod?.code === '0904',
              props: {
                label: '活動量の項目名',
                type: 'text',
              },
            },
            inputField2Name: {
              model: 'inputField2Name',
              rules: 'required|max:100',
              component: 'v-text-field',
              show: (data) => data.formValues.calculationMethod?.code === '0904',
              props: {
                label: '排出量の項目名',
                type: 'text',
              },
            },
            memo: {
              component: 'v-text-field',
              model: 'memo',
              rules: 'max:100',
              props: {
                label: 'メモ',
                type: 'text',
              },
            },
          },
          dataMapper: {
            calculationMethod: 'methods',
          }
        },
        GUIDE: 'Q0011'
      },
      S3_C06: {
        ACTIVITY_HEADERS: [
          {
            text: "活動名",
            value: "productActionName",
            align: "left",
            width: "15%",
          },
          {
            text: "算定方法",
            value: "calculationMethodName",
            align: "left",
            width: "25%",
          },
          {
            text: "活動量",
            value: "activityValues",
            align: "center",
            width: "30%",
          },
          {
            text: "排出原単位",
            value: "emissionUnit",
            align: "center",
            width: "15%",
          },
          {
            text: "排出量",
            value: "emissionVolume",
            align: "right",
            width: "10%",
            class: "success--text",
          },
          {
            text: "",
            value: "action",
            align: "right",
            width: "60px",
          },
        ],
        SUBCOLUMNS: [
          {
            name: '旅客人キロ',
            model: 'activityVolume',
            rules: 'required|length_comma:10|number_comma_full_width:true|numeric_right:3',
            unit: 'activityVolumeUnit',
            show: (item) => item.calculationMethodCode === '0125',
            showDetailButton: true
          },
          {
            name: '燃料使用量',
            model: 'activityVolume',
            rules: 'required|length_comma:10|number_comma_full_width:true|numeric_right:3',
            unit: 'activityVolumeUnit',
            show: (item) => item.calculationMethodCode === '0108',
            showDetailButton: true
          },
          {
            name: '移動距離',
            model: 'activityVolume',
            rules: 'required|length_comma:10|number_comma_full_width:true|numeric_right:3',
            unit: 'activityVolumeUnit',
            show: (item) => item.calculationMethodCode === '0109',
            showDetailButton: true
          },
          {
            name: '燃費',
            model: 'fuelEconomy',
            rules: 'required|length_comma:11|number_comma_full_width:true|numeric_right:2',
            unit: 'fuelUnitName',
            show: (item) => item.calculationMethodCode === '0109',
            disabled: (item) => item.fuelCode === '02',
            editable: (item) => item.fuelCode !== '02'
          },
          {
            name: '支給額',
            model: 'activityVolume',
            rules: 'required|length_comma:10|number_comma_full_width:true|numeric_right:3',
            unit: 'activityVolumeUnit',
            show: (item) => item.calculationMethodCode === '0127',
            showDetailButton: true
          },
          {
            name: '人泊数',
            model: 'activityVolume',
            rules: 'required|length_comma:10|number_comma_full_width:true|numeric_right:3',
            unit: 'activityVolumeUnit',
            show: (item) => item.calculationMethodCode === '0128',
            showDetailButton: true
          },
          {
            name: '人日数',
            model: 'activityVolume',
            rules: 'required|length_comma:10|number_comma_full_width:true|numeric_right:3',
            unit: 'activityVolumeUnit',
            show: (item) => item.calculationMethodCode === '0129',
            showDetailButton: true
          },
          {
            name: '従業員数',
            model: 'activityVolume',
            rules: 'required|length_comma:10|number_comma_full_width:true|numeric_right:3',
            unit: 'activityVolumeUnit',
            show: (item) => item.calculationMethodCode === '0130',
            editable: true
          },
          {
            name: 'inputField1Name',
            model: 'activityVolume',
            rules: 'required|length_comma:10|number_comma_full_width:true|numeric_right:3',
            show: (item) => item.calculationMethodCode === '0901',
            editable: true
          },
          {
            name: 'inputField2Name',
            model: 'emissionIntensity',
            rules: 'required|length_comma:5|number_comma_full_width:true|numeric_right:10',
            show: (item) => item.calculationMethodCode === '0901',
            editable: true
          },
          {
            name: '排出量',
            model: 'activityVolume',
            rules: 'required|length_comma:10|number_comma_full_width:true|numeric_right:3',
            show: (item) => item.calculationMethodCode === '0902',
            editable: true,
            unit: 'activityVolumeUnit'
          },
        ],
        FORMULA: {
          '0109': ['activityVolume', '/', 'fuelEconomy', '*', 0.001, '*', 'emissionIntensity'],
          // 交通費支給額に基づく
          '0127': ['activityVolume', '*', 'emissionIntensity', '*', 0.001],
          // 宿泊
          '0128': ['activityVolume', '*', 0.001, '*', 'emissionIntensity'],
        },
        ADD_DIALOG: {
          getName: {
            '0125': ['calculationTargetName2'],
            '0108': ['fuelMethodName', 'calculationTargetName1'],
            '0109': ['fuelMethodName', 'calculationTargetName1', '燃費が把握-fuelName'],
            '0127': ['calculationTargetName1'],
            '0129': ['calculationTargetName1'],
          },
          formValues: {
            productActionName: '',
            calculationMethod: null,
            calculationTarget: null,
            inputField1Name: '',
            inputField2Name: '',
            memo: '',
            // 自動車算定方法
            fuelMethod: null,
            // 燃費の把握
            fuel: null,
            // 値2の項目名の価値
            emissionIntensity: null,
            emissionUnit: null,
          },
          formFields: {
            productActionName: {
              model: 'productActionName',
              rules: 'required|max:100',
              component: 'v-text-field',
              props: {
                label: '活動名',
                type: 'text',
              },
            },
            calculationMethod: {
              model: 'calculationMethod',
              rules: 'required',
              component: 'v-select',
              show: (data, props) => props.catSixPersonnelAndCalcFlg.category6CalculationFlag === '2',
              props: {
                label: '入力方法',
                items: [],
                'item-text': 'name',
                'return-object': true,
              },
            },
            calculationTarget: {
              model: 'calculationTarget',
              rules: 'required',
              component: 'v-select',
              // 移動距離に基づく算定
              show: (data, props) => data.formValues.calculationMethod?.code === '0125' && props.catSixPersonnelAndCalcFlg.category6CalculationFlag === '2',
              props: {
                label: '算定方法（交通区分）',
                items: [],
                'item-text': 'name2',
                'return-object': true,
              },
            },
            fuelMethod: {
              model: 'fuelMethod',
              rules: 'required',
              component: 'v-radio-group',
              // 移動距離に基づく算定
              show: (data, props) => data.formValues.calculationMethod?.code === '0126' && props.catSixPersonnelAndCalcFlg.category6CalculationFlag === '2',
              props: {
                label: '自動車算定方法',
                'return-object': true,
              },
              items: [
                {
                  component: 'v-radio',
                  props: {
                    label: '燃料法',
                    value: { name: '燃料法', code: '0108', codeId: '183' }
                  }
                },
                {
                  component: 'v-radio',
                  props: {
                    label: '燃費法',
                    value: { name: '燃費法', code: '0109', codeId: '183' }
                  }
                },
              ]
            },
            fuel: {
              model: 'calculationTarget',
              rules: 'required',
              component: 'v-select',
              // 燃料法
              show: (data, props) => data.formValues.fuelMethod?.code === '0108' && props.catSixPersonnelAndCalcFlg.category6CalculationFlag === '2',
              props: {
                label: '燃料の種類',
                items: [],
                'item-text': 'name1',
                'return-object': true,
              },
            },
            fuelConsumption: {
              model: 'calculationTarget',
              rules: 'required',
              component: 'v-select',
              // 燃費法
              show: (data, props) => data.formValues.fuelMethod?.code === '0109' && props.catSixPersonnelAndCalcFlg.category6CalculationFlag === '2',
              props: {
                label: '輸送の区分',
                items: [],
                'item-text': 'name1',
                'return-object': true,
              },
            },
            fuelCode: {
              model: 'fuel',
              rules: 'required',
              component: 'v-radio-group',
              guide: 'Q0003',
              // 燃費法
              show: (data, props) => data.formValues.fuelMethod?.code === '0109' && props.catSixPersonnelAndCalcFlg.category6CalculationFlag === '2',
              props: {
                label: '燃費の把握',
                'return-object': true
              },
              items: [
                {
                  component: 'v-radio',
                  props: {
                    label: 'できない',
                    value: { name: 'できない', code: '02' }
                  }
                },
                {
                  component: 'v-radio',
                  props: {
                    label: 'できる',
                    value: { name: 'できる', code: '01' }
                  }
                },
              ]
            },
            transExps: {
              model: 'calculationTarget',
              rules: 'required',
              component: 'v-select',
              // 燃料法
              show: (data, props) => data.formValues.calculationMethod?.code === '0127' && props.catSixPersonnelAndCalcFlg.category6CalculationFlag === '2',
              props: {
                label: '算定方法（交通区分）',
                items: [],
                'item-text': 'name1',
                'return-object': true,
              },
            },
            travelDays: {
              model: 'calculationTarget',
              rules: 'required',
              component: 'v-select',
              // 燃料法
              show: (data, props) => data.formValues.calculationMethod?.code === '0129' && props.catSixPersonnelAndCalcFlg.category6CalculationFlag === '2',
              props: {
                label: '出張種別',
                items: [],
                'item-text': 'name1',
                'return-object': true,
              },
            },
            inputField1Name: {
              model: 'inputField1Name',
              rules: 'required|max:100',
              component: 'v-text-field',
              show: (data, props) => data.formValues.calculationMethod?.code === '0901' && props.catSixPersonnelAndCalcFlg.category6CalculationFlag === '2',
              props: {
                label: '値1の項目名',
                type: 'text',
              },
            },
            inputField2Name: {
              model: 'inputField2Name',
              rules: 'required|max:100',
              component: 'v-text-field',
              show: (data, props) => data.formValues.calculationMethod?.code === '0901' && props.catSixPersonnelAndCalcFlg.category6CalculationFlag === '2',
              props: {
                label: '値2の項目名',
                type: 'text',
              },
            },
            memo: {
              component: 'v-text-field',
              model: 'memo',
              rules: 'max:100',
              show: (data, props) => ['0901', '0902'].includes(data.formValues.calculationMethod?.code) && props.catSixPersonnelAndCalcFlg.category6CalculationFlag === '2',
              props: {
                label: 'メモ',
                type: 'text',
              },
            },
          },
          dataMapper: {
            calculationMethod: 'codes',
            calculationTarget: 'transportDistance',
            fuel: 'fuel',
            fuelConsumption: 'fuelConsumption',
            transExps: 'transExps',
            travelDays: 'travelDays'
          }
        },
        DETAIL_DIALOG: {
          '0125': {
            headers: [
              { text: '入力日', value: 'createDate', width: '8%', align: 'left' },
              { text: '入力者', value: 'createName', width: '8%', align: 'left' },
              {
                text: '人数',
                value: 'activityVolume',
                width: '15%',
                align: 'left',
                unit: '人',
                default: null,
                rule: 'required|number_max:10'
              },
              {
                text: '移動距離',
                value: 'transportDistance',
                width: '15%',
                align: 'left',
                unit: 'km',
                default: 0
              },
              {
                text: '活動量の根拠',
                value: 'evidence',
                width: '30%',
                align: 'left',
                default: ''
              },
              {
                text: 'メモ',
                value: 'activityDetailMemo',
                width: '26%',
                align: 'left',
                default: ''
              },
              { text: '削除', value: 'remove', width: '9%', align: 'left', default: false },
              { text: '明細コピー', value: 'action', width: '120px', align: 'left', default: false, guide: 'Q0018' },
            ],
            formula: {
              activityVolume: ['activityVolume', '*', 'transportDistance']
            }
          },
          '0108': {
            headers: [
              { text: '入力日', value: 'createDate', width: '8%', align: 'left' },
              { text: '入力者', value: 'createName', width: '8%', align: 'left' },
              {
                text: '使用量',
                value: 'activityVolume',
                width: '15%',
                align: 'left',
                default: null,
                unit: 'activityVolumeUnit'
              },
              {
                text: '活動量の根拠',
                value: 'evidence',
                width: '30%',
                align: 'left',
                default: ''
              },
              {
                text: 'メモ',
                value: 'activityDetailMemo',
                width: '26%',
                align: 'left',
                default: ''
              },
              { text: '削除', value: 'remove', width: '9%', align: 'left', default: false },
              { text: '明細コピー', value: 'action', width: '120px', align: 'left', default: false, guide: 'Q0018' },
            ]
          },
          '0109': {
            headers: [
              { text: '入力日', value: 'createDate', width: '8%', align: 'left' },
              { text: '入力者', value: 'createName', width: '8%', align: 'left' },
              {
                text: '移動距離',
                value: 'activityVolume',
                width: '15%',
                align: 'left',
                default: null,
                unit: 'activityVolumeUnit'
              },
              {
                text: '活動量の根拠',
                value: 'evidence',
                width: '30%',
                align: 'left',
                default: ''
              },
              {
                text: 'メモ',
                value: 'activityDetailMemo',
                width: '26%',
                align: 'left',
                default: null
              },
              { text: '削除', value: 'remove', width: '9%', align: 'left', default: false },
              { text: '明細コピー', value: 'action', width: '120px', align: 'left', default: false, guide: 'Q0018' },
            ]
          },
          '0127': {
            headers: [
              { text: '入力日', value: 'createDate', width: '8%', align: 'left' },
              { text: '入力者', value: 'createName', width: '8%', align: 'left' },
              {
                text: '人数',
                value: 'activityVolume',
                width: '15%',
                align: 'left',
                unit: '人',
                default: null,
                rule: 'required|number_max:10'
              },
              {
                text: '支給額',
                value: 'allowance',
                width: '15%',
                align: 'left',
                unit: 'activityVolumeUnit',
                default: 0
              },
              {
                text: '活動量の根拠',
                value: 'evidence',
                width: '30%',
                align: 'left',
                default: ''
              },
              {
                text: 'メモ',
                value: 'activityDetailMemo',
                width: '26%',
                align: 'left',
                default: ''
              },
              { text: '削除', value: 'remove', width: '9%', align: 'left', default: false },
              { text: '明細コピー', value: 'action', width: '120px', align: 'left', default: false, guide: 'Q0018' },
            ],
            formula: {
              activityVolume: ['activityVolume', '*', 'allowance']
            }
          },
          '0128': {
            headers: [
              { text: '入力日', value: 'createDate', width: '8%', align: 'left' },
              { text: '入力者', value: 'createName', width: '8%', align: 'left' },
              {
                text: '人数',
                value: 'activityVolume',
                width: '15%',
                align: 'left',
                unit: '人',
                default: null,
                rule: 'required|number_max:10'
              },
              {
                text: '日数',
                value: 'dayCount',
                width: '15%',
                align: 'left',
                unit: '日',
                default: 0
              },
              {
                text: '活動量の根拠',
                value: 'evidence',
                width: '30%',
                align: 'left',
                default: ''
              },
              {
                text: 'メモ',
                value: 'activityDetailMemo',
                width: '26%',
                align: 'left',
                default: ''
              },
              { text: '削除', value: 'remove', width: '9%', align: 'left', default: false },
              { text: '明細コピー', value: 'action', width: '120px', align: 'left', default: false, guide: 'Q0018' },
            ],
            formula: {
              activityVolume: ['activityVolume', '*', 'dayCount']
            }
          },
          '0129': {
            headers: [
              { text: '入力日', value: 'createDate', width: '8%', align: 'left' },
              { text: '入力者', value: 'createName', width: '8%', align: 'left' },
              {
                text: '人数',
                value: 'activityVolume',
                width: '15%',
                align: 'left',
                unit: '人',
                default: null,
                rule: 'required|number_max:10'
              },
              {
                text: '日数',
                value: 'dayCount',
                width: '15%',
                align: 'left',
                unit: '日',
                default: 0
              },
              {
                text: '活動量の根拠',
                value: 'evidence',
                width: '30%',
                align: 'left',
                default: ''
              },
              {
                text: 'メモ',
                value: 'activityDetailMemo',
                width: '26%',
                align: 'left',
                default: ''
              },
              { text: '削除', value: 'remove', width: '9%', align: 'left', default: false },
              { text: '明細コピー', value: 'action', width: '120px', align: 'left', default: false, guide: 'Q0018' },
            ],
            formula: {
              activityVolume: ['activityVolume', '*', 'dayCount']
            }
          },
        },
        GUIDE: 'Q0016'
      },
      S3_C07: {
        ACTIVITY_HEADERS: [
          {
            text: "活動名",
            value: "productActionName",
            align: "left",
            width: "15%",
          },
          {
            text: "算定方法",
            value: "calculationMethodName",
            align: "left",
            width: "20%",
          },
          {
            text: "活動量",
            value: "activityValues",
            align: "center",
            width: "40%",
          },
          {
            text: "排出原単位",
            value: "emissionUnit",
            align: "center",
            width: "10%",
          },
          {
            text: "排出量",
            value: "emissionVolume",
            align: "right",
            width: "10%",
            class: "success--text",
          },
          {
            text: "",
            value: "action",
            align: "right",
            width: "60px",
          },
        ],
        SUBCOLUMNS: [
          {
            name: '旅客人キロ',
            model: 'activityVolume',
            rules: 'required|length_comma:10|number_comma_full_width:true|numeric_right:3',
            unit: 'activityVolumeUnit',
            show: (item) => item.calculationMethodCode === '0125',
            showDetailButton: true
          },
          {
            name: '燃料使用量',
            model: 'activityVolume',
            rules: 'required|length_comma:10|number_comma_full_width:true|numeric_right:3',
            unit: 'activityVolumeUnit',
            show: (item) => item.calculationMethodCode === '0108',
            showDetailButton: true
          },
          {
            name: '移動距離',
            model: 'activityVolume',
            rules: 'required|length_comma:10|number_comma_full_width:true|numeric_right:3',
            unit: 'activityVolumeUnit',
            show: (item) => item.calculationMethodCode === '0109',
            showDetailButton: true
          },
          {
            name: '燃費',
            model: 'fuelEconomy',
            rules: 'required|length_comma:11|number_comma_full_width:true|numeric_right:2',
            unit: 'fuelUnitName',
            show: (item) => item.calculationMethodCode === '0109',
            disabled: (item) => item.fuelCode === '02',
            editable: (item) => item.fuelCode !== '02'
          },
          {
            name: '支給額',
            model: 'activityVolume',
            rules: 'required|length_comma:10|number_comma_full_width:true|numeric_right:3',
            unit: 'activityVolumeUnit',
            show: (item) => item.calculationMethodCode === '0127',
            showDetailButton: true
          },
          {
            name: '燃料使用量',
            model: 'fuelActivityVolume',
            rules: 'required|length_comma:10|number_comma_full_width:true|numeric_right:3',
            unit: (item) => item.fuelActivityVolumeUnit,
            show: (item) => item.calculationMethodCode === '0131',
          },
          {
            name: '電気使用量',
            model: 'activityVolume',
            rules: 'required|length_comma:10|number_comma_full_width:true|numeric_right:3',
            unit: (item) => item.activityVolumeUnit,
            show: (item) => item.calculationMethodCode === '0131',
            showDetailButton: true
          },
          {
            name: '従業員数',
            model: 'activityVolume',
            rules: 'required|number_max:10',
            unit: 'activityVolumeUnit',
            show: (item) => item.calculationMethodCode === '0132',
            editable: true
          },
          {
            name: '年間営業日数',
            model: 'businessDaysYear',
            rules: 'required|custom_min_value:-1|custom_max_value:366|number_max:3',
            // value: (props) => props['category']['fiscalYear']['businessDays'],
            unit: '日',
            show: (item) => item.calculationMethodCode === '0132',
            editable: true
          },
          {
            name: 'inputField1Name',
            model: 'activityVolume',
            rules: 'required|length_comma:10|number_comma_full_width:true|numeric_right:3',
            show: (item) => item.calculationMethodCode === '0901',
            editable: true
          },
          {
            name: 'inputField2Name',
            model: 'emissionIntensity',
            rules: 'required|length_comma:5|number_comma_full_width:true|numeric_right:10',
            show: (item) => item.calculationMethodCode === '0901',
            editable: true
          },
          {
            name: '排出量',
            model: 'activityVolume',
            rules: 'required|length_comma:10|number_comma_full_width:true|numeric_right:3',
            show: (item) => item.calculationMethodCode === '0902',
            editable: true,
            unit: 'activityVolumeUnit'
          },
        ],
        FORMULA: {
          '0109': ['activityVolume', '/', 'fuelEconomy', '*', 0.001, '*', 'emissionIntensity'],
          // 交通費支給額に基づく
          '0127': ['activityVolume', '*', 'emissionIntensity', '*', 0.001],
          // テレワーク
          '0131': ['(', 'fuelActivityVolume', '*', 'fuelEmissionIntensity', ')', '+', '(', 'activityVolume', '*', 'emissionIntensity', ')'],
          '0132': ['activityVolume', '*', 'businessDaysYear', '*', 'emissionIntensity'],
        },
        ADD_DIALOG: {
          getName: {
            '0125': ['calculationTargetName2'],
            '0108': ['fuelMethodName', 'calculationTargetName1'],
            '0109': ['fuelMethodName', 'calculationTargetName1', '燃費が把握-fuelName'],
            '0127': ['calculationTargetName1'],
            '0131': (item) => {
              let result = []
              item.electricCalculationMethodItem1 && result.push(item.electricCalculationMethodItem1)
              item.electricCompanyElectricCompanyNo && result.push(`${item.electricCompanyElectricCompanyNo} ${item.electricCompanyElectricCompanyName}`)
              item.supplyCompanyUnit1 && result.push(item.supplyCompanyUnit1)

              let joinRes = result.join('/')
              if (joinRes) {
                joinRes = `&電気/` + result.join('/')
              } else {
                joinRes = `&-`
              }
              if (item.calculationTargetName1) {
                joinRes = '&' + item.calculationTargetName1 + joinRes
              } else {
                joinRes = '&-' + joinRes
              }
              return joinRes
            }
          },
          formValues: {
            productActionName: '',
            calculationMethod: null,
            calculationTarget: null,
            inputField1Name: '',
            inputField2Name: '',
            memo: '',
            // 自動車算定方法
            fuelMethod: null,
            // 燃費の把握
            fuel: null,
            // 値2の項目名の価値
            emissionIntensity: null,
            emissionUnit: null,
            electricCalculationMethod: null,
            electricCompany: null,
            supplyCompany: null,
          },
          formFields: {
            productActionName: {
              model: 'productActionName',
              rules: 'required|max:100',
              component: 'v-text-field',
              props: {
                label: '活動名',
                type: 'text',
              },
            },
            calculationMethod: {
              model: 'calculationMethod',
              rules: 'required',
              component: 'v-select',
              props: {
                label: '入力方法',
                items: [],
                'item-text': 'name',
                'return-object': true,
              },
            },
            calculationTarget: {
              model: 'calculationTarget',
              rules: 'required',
              component: 'v-select',
              // 移動距離に基づく算定
              show: (data) => data.formValues.calculationMethod?.code === '0125',
              props: {
                label: '算定方法（交通区分）',
                items: [],
                'item-text': 'name2',
                'return-object': true,
              },
            },
            fuelMethod: {
              model: 'fuelMethod',
              rules: 'required',
              component: 'v-radio-group',
              // 移動距離に基づく算定
              show: (data) => data.formValues.calculationMethod?.code === '0126',
              props: {
                label: '自動車算定方法',
                'return-object': true,
              },
              items: [
                {
                  component: 'v-radio',
                  props: {
                    label: '燃料法',
                    value: { name: '燃料法', code: '0108', codeId: '183' }
                  }
                },
                {
                  component: 'v-radio',
                  props: {
                    label: '燃費法',
                    value: { name: '燃費法', code: '0109', codeId: '183' }
                  }
                },
              ]
            },
            fuel: {
              model: 'calculationTarget',
              rules: 'required',
              component: 'v-select',
              // 燃料法
              show: (data) => data.formValues.fuelMethod?.code === '0108',
              props: {
                label: '燃料の種類',
                items: [],
                'item-text': 'name1',
                'return-object': true,
              },
            },
            fuelConsumption: {
              model: 'calculationTarget',
              rules: 'required',
              component: 'v-select',
              // 燃費法
              show: (data) => data.formValues.fuelMethod?.code === '0109',
              props: {
                label: '輸送の区分',
                items: [],
                'item-text': 'name1',
                'return-object': true,
              },
            },
            fuelCode: {
              model: 'fuel',
              rules: 'required',
              component: 'v-radio-group',
              guide: 'Q0003',
              // 燃費法
              show: (data) => data.formValues.fuelMethod?.code === '0109',
              props: {
                label: '燃費の把握',
                'return-object': true
              },
              items: [
                {
                  component: 'v-radio',
                  props: {
                    label: 'できない',
                    value: { name: 'できない', code: '02' }
                  }
                },
                {
                  component: 'v-radio',
                  props: {
                    label: 'できる',
                    value: { name: 'できる', code: '01' }
                  }
                },
              ]
            },
            transExps: {
              model: 'calculationTarget',
              rules: 'required',
              component: 'v-select',
              // 燃料法
              show: (data) => data.formValues.calculationMethod?.code === '0127',
              props: {
                label: '算定方法（交通区分）',
                items: [],
                'item-text': 'name1',
                'return-object': true,
              },
            },
            telework: {
              model: 'calculationTarget',
              rules: (item) => ({ required: item.electricCalculationMethod === null }),
              component: 'v-select',
              // 燃料法
              show: (data) => data.formValues.calculationMethod?.code === '0131',
              props: {
                label: '電気以外の燃料の種類',
                items: [],
                'item-text': 'name1',
                'return-object': true,
              },
            },
            electricCodesRes: {
              model: 'electricCalculationMethod',
              rules: (item) => ({ required: item.calculationTarget === null }),
              component: 'v-select',
              // 燃料法
              show: (data) => data.formValues.calculationMethod?.code === '0131',
              props: {
                label: '電気算定方法',
                items: [],
                'item-text': 'item1',
                'return-object': true,
              },
            },
            retailElectricCompanies: {
              model: 'electricCompany',
              rules: (item) => ({ required: !!item.electricCalculationMethod }),
              component: 'v-autocomplete',
              // 燃料法
              show: (data) => data.formValues.calculationMethod?.code === '0131',
              props: {
                label: '電力会社',
                items: [],
                'item-text': (item) => item.electricCompanyNo + ' ' + item.electricCompanyName,
                'return-object': true,
              },
            },
            supplyCompanies: {
              model: 'supplyCompany',
              rules: 'required',
              component: 'v-select',
              // 燃料法
              show: (data) => !!data.formFields.supplyCompanies.props.items.length,
              props: {
                label: '供給メニュー',
                items: [],
                'item-text': 'unit_1',
                'return-object': true,
              },
            },
            inputField1Name: {
              model: 'inputField1Name',
              rules: 'required|max:100',
              component: 'v-text-field',
              show: (data) => data.formValues.calculationMethod?.code === '0901',
              props: {
                label: '値1の項目名',
                type: 'text',
              },
            },
            inputField2Name: {
              model: 'inputField2Name',
              rules: 'required|max:100',
              component: 'v-text-field',
              show: (data) => data.formValues.calculationMethod?.code === '0901',
              props: {
                label: '値2の項目名',
                type: 'text',
              },
            },
            memo: {
              component: 'v-text-field',
              model: 'memo',
              rules: 'max:100',
              show: (data) => ['0901', '0902'].includes(data.formValues.calculationMethod?.code),
              props: {
                label: 'メモ',
                type: 'text',
              },
            },
          },
          dataMapper: {
            calculationMethod: 'codes',
            calculationTarget: 'transportDistance',
            fuel: 'fuel',
            fuelConsumption: 'fuelConsumption',
            transExps: 'transExps',
            telework: 'telework',
            electricCodesRes: 'calcMethods',
            retailElectricCompanies: 'retailElectricCompanies'
          }
        },
        DETAIL_DIALOG: {
          '0125': {
            headers: [
              { text: '入力日', value: 'createDate', width: '8%', align: 'left' },
              { text: '入力者', value: 'createName', width: '8%', align: 'left' },
              {
                text: '人数',
                value: 'activityVolume',
                width: '15%',
                align: 'left',
                unit: '人',
                default: null
              },
              {
                text: '移動距離',
                value: 'transportDistance',
                width: '15%',
                align: 'left',
                unit: 'km',
                default: 0
              },
              {
                text: '活動量の根拠',
                value: 'evidence',
                width: '30%',
                align: 'left',
                default: ''
              },
              {
                text: 'メモ',
                value: 'activityDetailMemo',
                width: '26%',
                align: 'left',
                default: ''
              },
              { text: '削除', value: 'remove', width: '9%', align: 'left', default: false },
              { text: '明細コピー', value: 'action', width: '120px', align: 'left', default: false, guide: 'Q0018' },
            ],
            formula: {
              activityVolume: ['activityVolume', '*', 'transportDistance']
            }
          },
          '0108': {
            headers: [
              { text: '入力日', value: 'createDate', width: '8%', align: 'left' },
              { text: '入力者', value: 'createName', width: '8%', align: 'left' },
              {
                text: '使用量',
                value: 'activityVolume',
                width: '15%',
                align: 'left',
                default: null,
                unit: 'activityVolumeUnit',
              },
              {
                text: '活動量の根拠',
                value: 'evidence',
                width: '30%',
                align: 'left',
                default: ''
              },
              {
                text: 'メモ',
                value: 'activityDetailMemo',
                width: '26%',
                align: 'left',
                default: ''
              },
              { text: '削除', value: 'remove', width: '9%', align: 'left', default: false },
              { text: '明細コピー', value: 'action', width: '120px', align: 'left', default: false, guide: 'Q0018' },
            ]
          },
          '0109': {
            headers: [
              { text: '入力日', value: 'createDate', width: '8%', align: 'left' },
              { text: '入力者', value: 'createName', width: '8%', align: 'left' },
              {
                text: '移動距離',
                value: 'activityVolume',
                width: '15%',
                align: 'left',
                default: null,
                unit: 'activityVolumeUnit',
              },
              {
                text: '活動量の根拠',
                value: 'evidence',
                width: '30%',
                align: 'left',
                default: ''
              },
              {
                text: 'メモ',
                value: 'activityDetailMemo',
                width: '26%',
                align: 'left',
                default: null
              },
              { text: '削除', value: 'remove', width: '9%', align: 'left', default: false },
              { text: '明細コピー', value: 'action', width: '120px', align: 'left', default: false, guide: 'Q0018' },
            ]
          },
          '0127': {
            headers: [
              { text: '入力日', value: 'createDate', width: '8%', align: 'left' },
              { text: '入力者', value: 'createName', width: '8%', align: 'left' },
              {
                text: '人数',
                value: 'activityVolume',
                width: '15%',
                align: 'left',
                unit: '人',
                default: null
              },
              {
                text: '支給額',
                value: 'allowance',
                width: '15%',
                align: 'left',
                unit: 'activityVolumeUnit',
                default: null
              },
              {
                text: '活動量の根拠',
                value: 'evidence',
                width: '30%',
                align: 'left',
                default: ''
              },
              {
                text: 'メモ',
                value: 'activityDetailMemo',
                width: '26%',
                align: 'left',
                default: ''
              },
              { text: '削除', value: 'remove', width: '9%', align: 'left', default: false },
              { text: '明細コピー', value: 'action', width: '120px', align: 'left', default: false, guide: 'Q0018' },
            ],
            formula: {
              activityVolume: ['activityVolume', '*', 'allowance']
            }
          },
          '0131': {
            headers: [
              { text: '入力日', value: 'createDate', width: '8%', align: 'left' },
              { text: '入力者', value: 'createName', width: '8%', align: 'left' },
              {
                text: '電気以外の燃料使用量',
                value: 'fuelActivityVolume',
                width: '15%',
                align: 'left',
                default: 0,
                unit: (item) => item.fuelActivityVolumeUnit,
                disabled: (item) => item.fuelActivityVolumeUnit === ''
              },
              {
                text: '電気使用量',
                value: 'activityVolume',
                width: '15%',
                align: 'left',
                default: 0,
                unit: (item) => item.activityVolumeUnit,
                disabled: (item) => item.activityVolumeUnit === ''
              },
              {
                text: '活動量の根拠',
                value: 'evidence',
                width: '30%',
                align: 'left',
                default: ''
              },
              {
                text: 'メモ',
                value: 'activityDetailMemo',
                width: '26%',
                align: 'left',
                default: ''
              },
              { text: '削除', value: 'remove', width: '9%', align: 'left', default: false },
              { text: '明細コピー', value: 'action', width: '120px', align: 'left', default: false, guide: 'Q0018' },
            ],
            formula: {
              activityVolume: ['activityVolume'],
              fuelActivityVolume: ['fuelActivityVolume']
            }
          },
        }
      },
      S3_C08: {
        ACTIVITY_HEADERS: [
          {
            text: "活動名",
            value: "productActionName",
            align: "left",
            width: "15%",
          },
          {
            text: "算定方法",
            value: "calculationMethodName",
            align: "left",
            width: "25%",
          },
          {
            text: "活動量",
            value: "activityValues",
            align: "center",
            width: "35%",
          },
          {
            text: "排出原単位",
            value: "emissionUnit",
            align: "center",
            width: "15%",
          },
          {
            text: "排出量",
            value: "emissionVolume",
            align: "right",
            width: "10%",
            class: "success--text",
          },
          {
            text: "",
            value: "action",
            align: "right",
            width: "60px",
          },
        ],
        SUBCOLUMNS: [
          {
            name: '使用量',
            model: 'activityVolume',
            rules: 'required|length_comma:10|number_comma_full_width:true|numeric_right:3',
            unit: 'activityVolumeUnit',
            show: (item) => item.calculationMethodCode === '0138',
            editable: true,
          },
          {
            name: 'エネルギー消費量',
            model: 'activityVolume',
            rules: 'required|length_comma:10|number_comma_full_width:true|numeric_right:3',
            unit: 'activityVolumeUnit',
            show: (item) => item.calculationMethodCode === '0139',
            editable: true
          },
          {
            name: '建物の面積',
            model: 'activityVolume',
            rules: 'required|length_comma:10|number_comma_full_width:true|numeric_right:3',
            unit: 'activityVolumeUnit',
            show: (item) => item.calculationMethodCode === '0140',
            editable: true
          },
          {
            name: '按分',
            model: 'distribution',
            rules: 'not_zero|fraction|fraction_determinator|fraction_numerator|fraction_value|fraction_value_2|max_digits:100',
            show: (item) => ['0139', '0140'].includes(item.calculationMethodCode),
            editable: true
          },
          {
            name: 'inputField1Name',
            model: 'activityVolume',
            rules: 'required|length_comma:10|number_comma_full_width:true|numeric_right:3',
            show: (item) => item.calculationMethodCode === '0901',
            editable: true
          },
          {
            name: 'inputField2Name',
            model: 'emissionIntensity',
            rules: 'required|length_comma:5|number_comma_full_width:true|numeric_right:10',
            show: (item) => item.calculationMethodCode === '0901',
            editable: true
          },
          {
            name: '排出量',
            model: 'activityVolume',
            rules: 'required|length_comma:10|number_comma_full_width:true|numeric_right:3',
            show: (item) => item.calculationMethodCode === '0902',
            editable: true,
            unit: 'activityVolumeUnit'
          },
        ],
        FORMULA: {
          '0139': ['activityVolume', '*', 'distribution', '*', 'emissionIntensity'],
          '0140': ['activityVolume', '*', 'distribution', '*', 'emissionIntensity'],
        },
        ADD_DIALOG: {
          getName: {
            '0138': ['calculationMethodName1', 'gasTypesName1', 'calculationTargetName1'],
            '0139': (item) => {
              let arr = ['calculationMethodName1', 'calculationTargetName1']
              // 代表値
              if (item.electricityUserCode === '01') {
                arr.push('electricityUserName')
              }
              let res = arr.map(i => item[i]).join('/')
              // 個別の電気事業者
              if (item.electricityUserCode === '02') {
                res += '/'
                res += item.retailElectricCompaniesElectricCompanyNo
                res += item.retailElectricCompaniesElectricCompanyName
              }
              return res
            },
            '0140': (item) => {
              let arr = ['calculationMethodName1', 'calculationTargetName1']
              // 代表値
              if (item.electricityUserCode === '01') {
                arr.push('electricityUserName')
              }
              let res = arr.map(i => item[i]).join('/')
              // 個別の電気事業者
              if (item.electricityUserCode === '02') {
                res += '/'
                res += item.retailElectricCompaniesElectricCompanyNo
                res += item.retailElectricCompaniesElectricCompanyName
              }
              return res
            },
            '0901': ['calculationMethodName1'],
            '0902': ['calculationMethodName1'],
          },
          formValues: {
            productActionName: '',
            calculationMethod: null,
            calculationTarget: null,
            inputField1Name: '',
            inputField2Name: '',
            memo: '',
            emissionIntensity: null,
            emissionUnit: null,
            gasTypes: null,
            electricityUser: null,
            distribution: '',
            retailElectricCompanies: null
          },
          formFields: {
            productActionName: {
              model: 'productActionName',
              rules: 'required|max:100',
              component: 'v-text-field',
              props: {
                label: '活動名',
                type: 'text',
              },
            },
            calculationMethod: {
              model: 'calculationMethod',
              rules: 'required',
              component: 'v-select',
              props: {
                label: '入力方法',
                items: [],
                'item-text': 'name1',
                'return-object': true,
              },
            },
            gasTypes: {
              model: 'gasTypes',
              rules: 'required',
              component: 'v-select',
              show: (data) => data.formValues.calculationMethod?.code === '0138',
              props: {
                label: 'ガスの種類',
                items: [],
                'item-text': 'name1',
                'return-object': true,
              },
            },
            actTypes: {
              model: 'calculationTarget',
              rules: 'required',
              component: 'v-select',
              show: (data) => data.formValues.calculationMethod?.code === '0138' && data.formValues.gasTypes,
              props: {
                label: '活動の種類',
                items: [],
                'item-text': 'name1',
                'return-object': true,
              },
            },
            codesBuildingPurpose: {
              model: 'calculationTarget',
              rules: 'required',
              component: 'v-select',
              show: (data) => ['0139', '0140'].includes(data.formValues.calculationMethod?.code),
              props: {
                label: '建物用途',
                items: [],
                'item-text': 'name1',
                'return-object': true,
              },
            },
            electricityUser: {
              model: 'electricityUser',
              rules: 'required',
              component: 'v-radio-group',
              guide: 'Q0014',
              show: (data) => ['0139', '0140'].includes(data.formValues.calculationMethod?.code),
              props: {
                label: '電気事業者の数値',
                'return-object': true
              },
              items: [
                {
                  component: 'v-radio',
                  props: {
                    label: '代表値',
                    value: { name: '代表値', code: '01' }
                  }
                },
                {
                  component: 'v-radio',
                  props: {
                    label: '個別の電気事業者',
                    value: { name: '個別の電気事業者', code: '02' }
                  }
                },
              ]
            },
            retailElectricCompanies: {
              model: 'retailElectricCompanies',
              rules: 'required',
              component: 'v-autocomplete',
              show: (data) => data.formValues.electricityUser?.code === '02',
              props: {
                label: '電力会社',
                items: [],
                'item-text': (item) => item.electricCompanyNo + ' ' + item.electricCompanyName,
                'return-object': true,
              },
            },
            inputField1Name: {
              model: 'inputField1Name',
              rules: 'required|max:100',
              component: 'v-text-field',
              show: (data) => data.formValues.calculationMethod?.code === '0901',
              props: {
                label: '値1の項目名',
                type: 'text',
              },
            },
            inputField2Name: {
              model: 'inputField2Name',
              rules: 'required|max:100',
              component: 'v-text-field',
              show: (data) => data.formValues.calculationMethod?.code === '0901',
              props: {
                label: '値2の項目名',
                type: 'text',
              },
            },
            memo: {
              component: 'v-text-field',
              model: 'memo',
              rules: 'max:100',
              props: {
                label: 'メモ',
                type: 'text',
              },
            },
          },
          dataMapper: {
            calculationMethod: 'eightCat',
            gasTypes: 'gasTypes',
            actTypes: 'actTypes',
            codesBuildingPurpose: 'codesBuildingPurpose',
            retailElectricCompanies: 'retailElectricCompanies'
          }
        },
      },
      S3_C13: {
        ACTIVITY_HEADERS: [
          {
            text: "活動名",
            value: "productActionName",
            align: "left",
            width: "15%",
          },
          {
            text: "算定方法",
            value: "calculationMethodName",
            align: "left",
            width: "25%",
          },
          {
            text: "活動量",
            value: "activityValues",
            align: "center",
            width: "35%",
          },
          {
            text: "排出原単位",
            value: "emissionUnit",
            align: "center",
            width: "15%",
          },
          {
            text: "排出量",
            value: "emissionVolume",
            align: "right",
            width: "10%",
            class: "success--text",
          },
          {
            text: "",
            value: "action",
            align: "right",
            width: "60px",
          },
        ],
        SUBCOLUMNS: [
          {
            name: '使用量',
            model: 'activityVolume',
            rules: 'required|length_comma:10|number_comma_full_width:true|numeric_right:3',
            unit: 'activityVolumeUnit',
            show: (item) => item.calculationMethodCode === '0138',
            editable: true,
          },
          {
            name: 'エネルギー消費量',
            model: 'activityVolume',
            rules: 'required|length_comma:10|number_comma_full_width:true|numeric_right:3',
            unit: 'activityVolumeUnit',
            show: (item) => item.calculationMethodCode === '0139',
            editable: true
          },
          {
            name: '建物の面積',
            model: 'activityVolume',
            rules: 'required|length_comma:10|number_comma_full_width:true|numeric_right:3',
            unit: 'activityVolumeUnit',
            show: (item) => item.calculationMethodCode === '0140',
            editable: true
          },
          {
            name: '按分',
            model: 'distribution',
            rules: 'not_zero|fraction|fraction_determinator|fraction_numerator|fraction_value|fraction_value_2|max_digits:100',
            show: (item) => ['0139', '0140'].includes(item.calculationMethodCode),
            editable: true
          },
          {
            name: 'inputField1Name',
            model: 'activityVolume',
            rules: 'required|length_comma:10|number_comma_full_width:true|numeric_right:3',
            show: (item) => item.calculationMethodCode === '0901',
            editable: true
          },
          {
            name: 'inputField2Name',
            model: 'emissionIntensity',
            rules: 'required|length_comma:5|number_comma_full_width:true|numeric_right:10',
            show: (item) => item.calculationMethodCode === '0901',
            editable: true
          },
          {
            name: '排出量',
            model: 'activityVolume',
            rules: 'required|length_comma:10|number_comma_full_width:true|numeric_right:3',
            show: (item) => item.calculationMethodCode === '0902',
            editable: true,
            unit: 'activityVolumeUnit'
          },
        ],
        FORMULA: {
          '0139': ['activityVolume', '*', 'distribution', '*', 'emissionIntensity'],
          '0140': ['activityVolume', '*', 'distribution', '*', 'emissionIntensity'],
        },
        ADD_DIALOG: {
          getName: {
            '0138': ['calculationMethodName1', 'gasTypesName1', 'calculationTargetName1'],
            '0139': (item) => {
              let arr = ['calculationMethodName1', 'calculationTargetName1']
              // 代表値
              if (item.electricityUserCode === '01') {
                arr.push('electricityUserName')
              }
              let res = arr.map(i => item[i]).join('/')
              // 個別の電気事業者
              if (item.electricityUserCode === '02') {
                res += '/'
                res += item.retailElectricCompaniesElectricCompanyNo
                res += item.retailElectricCompaniesElectricCompanyName
              }
              return res
            },
            '0140': (item) => {
              let arr = ['calculationMethodName1', 'calculationTargetName1']
              // 代表値
              if (item.electricityUserCode === '01') {
                arr.push('electricityUserName')
              }
              let res = arr.map(i => item[i]).join('/')
              // 個別の電気事業者
              if (item.electricityUserCode === '02') {
                res += '/'
                res += item.retailElectricCompaniesElectricCompanyNo
                res += item.retailElectricCompaniesElectricCompanyName
              }
              return res
            },
            '0901': ['calculationMethodName1'],
            '0902': ['calculationMethodName1'],
          },
          formValues: {
            productActionName: '',
            calculationMethod: null,
            calculationTarget: null,
            inputField1Name: '',
            inputField2Name: '',
            memo: '',
            emissionIntensity: null,
            emissionUnit: null,
            gasTypes: null,
            electricityUser: null,
            distribution: '',
            retailElectricCompanies: null
          },
          formFields: {
            productActionName: {
              model: 'productActionName',
              rules: 'required|max:100',
              component: 'v-text-field',
              props: {
                label: '活動名',
                type: 'text',
              },
            },
            calculationMethod: {
              model: 'calculationMethod',
              rules: 'required',
              component: 'v-select',
              props: {
                label: '入力方法',
                items: [],
                'item-text': 'name1',
                'return-object': true,
              },
            },
            gasTypes: {
              model: 'gasTypes',
              rules: 'required',
              component: 'v-select',
              show: (data) => data.formValues.calculationMethod?.code === '0138',
              props: {
                label: 'ガスの種類',
                items: [],
                'item-text': 'name1',
                'return-object': true,
              },
            },
            actTypes: {
              model: 'calculationTarget',
              rules: 'required',
              component: 'v-select',
              show: (data) => data.formValues.calculationMethod?.code === '0138' && data.formValues.gasTypes,
              props: {
                label: '活動の種類',
                items: [],
                'item-text': 'name1',
                'return-object': true,
              },
            },
            codesBuildingPurpose: {
              model: 'calculationTarget',
              rules: 'required',
              component: 'v-select',
              show: (data) => ['0139', '0140'].includes(data.formValues.calculationMethod?.code),
              props: {
                label: '建物用途',
                items: [],
                'item-text': 'name1',
                'return-object': true,
              },
            },
            electricityUser: {
              model: 'electricityUser',
              rules: 'required',
              component: 'v-radio-group',
              guide: 'Q0014',
              show: (data) => ['0139', '0140'].includes(data.formValues.calculationMethod?.code),
              props: {
                label: '電気事業者の数値',
                'return-object': true
              },
              items: [
                {
                  component: 'v-radio',
                  props: {
                    label: '代表値',
                    value: { name: '代表値', code: '01' }
                  }
                },
                {
                  component: 'v-radio',
                  props: {
                    label: '個別の電気事業者',
                    value: { name: '個別の電気事業者', code: '02' }
                  }
                },
              ]
            },
            retailElectricCompanies: {
              model: 'retailElectricCompanies',
              rules: 'required',
              component: 'v-autocomplete',
              show: (data) => data.formValues.electricityUser?.code === '02',
              props: {
                label: '電力会社',
                items: [],
                'item-text': (item) => item.electricCompanyNo + ' ' + item.electricCompanyName,
                'return-object': true,
              },
            },
            inputField1Name: {
              model: 'inputField1Name',
              rules: 'required|max:100',
              component: 'v-text-field',
              show: (data) => data.formValues.calculationMethod?.code === '0901',
              props: {
                label: '値1の項目名',
                type: 'text',
              },
            },
            inputField2Name: {
              model: 'inputField2Name',
              rules: 'required|max:100',
              component: 'v-text-field',
              show: (data) => data.formValues.calculationMethod?.code === '0901',
              props: {
                label: '値2の項目名',
                type: 'text',
              },
            },
            memo: {
              component: 'v-text-field',
              model: 'memo',
              rules: 'max:100',
              props: {
                label: 'メモ',
                type: 'text',
              },
            },
          },
          dataMapper: {
            calculationMethod: 'thirteenCat',
            gasTypes: 'gasTypes',
            actTypes: 'actTypes',
            codesBuildingPurpose: 'codesBuildingPurpose',
            retailElectricCompanies: 'retailElectricCompanies'
          }
        },
      },
      S3_C02: {
        ACTIVITY_HEADERS: [
          {
            text: "活動名",
            value: "productActionName",
            align: "left",
            width: "15%",
          },
          {
            text: "算定方法",
            value: "calculationMethodName",
            align: "left",
            width: "25%",
          },
          {
            text: "活動量",
            value: "activityValues",
            align: "center",
            width: "25%",
          },
          {
            text: "排出原単位",
            value: "emissionUnit",
            align: "center",
            width: "25%",
          },
          {
            text: "排出量",
            value: "emissionVolume",
            align: "right",
            width: "10%",
            class: "success--text",
          },
          {
            text: "",
            value: "action",
            align: "right",
            width: "60px",
          },
        ],
        SUBCOLUMNS: [
          {
            name: 'inputField1Name',
            model: 'activityVolume',
            rules: 'required|length_comma:10|number_comma_full_width:true|numeric_right:3',
            show: (item) => ['0133', '0135', '0136'].includes(item.calculationMethodCode),
            editable: true
          },
          {
            name: 'サプライヤーの資本財関連のScope1及びScope2の排出量',
            model: 'activityVolume',
            rules: 'required|length_comma:10|number_comma_full_width:true|numeric_right:3',
            show: (item) => item.calculationMethodCode === '0134',
            editable: true
          },
          {
            name: '原材料の投入量または価格',
            model: 'rawActivityVolume',
            rules: 'required|length_comma:10|number_comma_full_width:true|numeric_right:3',
            show: (item) => item.calculationMethodCode === '0134',
            editable: true
          },
          {
            name: '原材料の輸送量',
            model: 'rawTransportActivityVolume',
            rules: 'required|length_comma:10|number_comma_full_width:true|numeric_right:3',
            show: (item) => item.calculationMethodCode === '0134',
            editable: true
          },
          {
            name: '資本財に関連した廃棄物の重量',
            model: 'capitalWasteActivityVolume',
            rules: 'required|length_comma:10|number_comma_full_width:true|numeric_right:3',
            show: (item) => item.calculationMethodCode === '0134',
            editable: true
          },
          {
            name: 'inputField1Name',
            model: 'activityVolume',
            rules: 'required|length_comma:10|number_comma_full_width:true|numeric_right:3',
            show: (item) => item.calculationMethodCode === '0137',
            unit: 'activityVolumeUnit',
            editable: true,
          },
          {
            name: '排出原単位',
            model: 'activityVolume',
            rules: 'required|length_comma:10|number_comma_full_width:true|numeric_right:3',
            show: (item) => item.calculationMethodCode === '0902',
            editable: true,
            unit: 'activityVolumeUnit'
          },
        ],
        INTENSITY_COLUMNS: [
          {
            name: 'inputField2Name',
            model: 'emissionIntensity',
            rules: 'required|length_comma:5|number_comma_full_width:true|numeric_right:10',
            show: (item) => ['0133', '0135', '0136'].includes(item.calculationMethodCode),
            editable: true,
          },
          {
            name: '排出原単位',
            model: 'rawEmissionIntensity',
            rules: 'required|length_comma:5|number_comma_full_width:true|numeric_right:10',
            show: (item) => item.calculationMethodCode === '0134',
            disabled: () => true,
            style: 'opacity: 0',
            precision: 10
          },
          {
            name: '排出原単位',
            model: 'rawEmissionIntensity',
            rules: 'required|length_comma:5|number_comma_full_width:true|numeric_right:10',
            show: (item) => item.calculationMethodCode === '0134',
            editable: true,
            precision: 10
          },
          {
            name: '排出原単位',
            model: 'rawTransportEmissionIntensity',
            rules: 'required|length_comma:5|number_comma_full_width:true|numeric_right:10',
            show: (item) => item.calculationMethodCode === '0134',
            editable: true,
            precision: 10
          },
          {
            name: '排出原単位',
            model: 'capitalWasteEmissionIntensity',
            rules: 'required|length_comma:5|number_comma_full_width:true|numeric_right:10',
            show: (item) => item.calculationMethodCode === '0134',
            editable: true,
            precision: 10
          },
          {
            name: 'inputField2Name',
            model: 'emissionIntensity',
            rules: 'required|length_comma:5|number_comma_full_width:true|numeric_right:10',
            show: (item) => item.calculationMethodCode === '0137',
            disabled: () => true,
            unit: 'emissionUnit',
            formatFixed: true
          },
        ],
        FORMULA: {
          '0134': ['activityVolume', '+', '(', 'rawActivityVolume', '*', 'rawEmissionIntensity', ')', '+', '(', 'rawTransportActivityVolume', '*', 'rawTransportEmissionIntensity', ')', '+', '(', 'capitalWasteActivityVolume', '*', 'capitalWasteEmissionIntensity', ')'],
        },
        ADD_DIALOG: {
          getName: {
            '0133': ['calculationMethodName1'],
            '0134': ['calculationMethodName1'],
            '0135': ['calculationMethodName1'],
            '0136': ['calculationMethodName1'],
            '0137': (item) => {
              if (item.capitalCode === '00-0000') return `${item.calculationMethodName1}/${item.capitalItem1}`
              return `${item.calculationMethodName1}/${item.capitalCode} ${item.capitalItem1}`
            },
            '0902': ['calculationMethodName1'],
          },
          formValues: {
            productActionName: '',
            calculationMethod: null,
            inputField1Name: '',
            inputField2Name: '',
            memo: '',
            emissionIntensity: null,
            emissionUnit: null,
            rawActivityVolume: 0,
            rawEmissionIntensity: 0,
            rawTransportActivityVolume: 0,
            rawTransportEmissionIntensity: 0,
            capitalWasteActivityVolume: 0,
            capitalWasteEmissionIntensity: 0,
            goodsCriteria: null,
            capital: null
          },
          formFields: {
            productActionName: {
              model: 'productActionName',
              rules: 'required|max:100',
              component: 'v-text-field',
              props: {
                label: '活動名',
                type: 'text',
              },
            },
            calculationMethod: {
              model: 'calculationMethod',
              rules: 'required',
              component: 'v-select',
              props: {
                label: '入力方法',
                items: [],
                'item-text': 'name1',
                'return-object': true,
              },
            },
            goodsCriteria: {
              model: 'goodsCriteria',
              rules: 'required',
              component: 'v-select',
              show: (data) => data.formValues.calculationMethod?.code === '0164',
              props: {
                label: '資本財の基準',
                items: [],
                'item-text': 'name1',
                'return-object': true,
              },
            },
            capitals: {
              model: 'capital',
              rules: 'required',
              component: 'v-autocomplete',
              show: (data) => data.formValues.goodsCriteria?.code === '0137',
              props: {
                label: '算定対象となる資本形成部門の業類',
                items: [],
                'item-text': (item) => {
                  if (item.code === '00-0000') return item.item1
                  return item.code + ' ' + item.item1
                },
                'return-object': true,
              },
            },
            memo: {
              component: 'v-text-field',
              model: 'memo',
              rules: 'max:100',
              props: {
                label: 'メモ',
                type: 'text',
              },
            },
          },
          dataMapper: {
            calculationMethod: 'catTwoCodes',
            goodsCriteria: 'capitalGoodStd',
            capitals: 'capitals'
          }
        },
      },
      S3_C03: {
        ACTIVITY_HEADERS: [
          {
            text: "活動名",
            value: "productActionName",
            align: "left",
            width: "10%",
          },
          {
            text: "燃料種別",
            value: "calculationTargetName",
            align: "left",
            width: "15%",
          },
          {
            text: "算定方法",
            value: "calculationMethodName",
            align: "left",
            width: "20%",
          },
          {
            text: "活動量",
            value: "activityValues",
            align: "center",
            width: "30%",
          },
          {
            text: "排出原単位",
            value: "emissionUnit",
            align: "left",
            width: "15%",
          },
          {
            text: "排出量",
            value: "emissionVolume",
            align: "center",
            width: "10%",
            class: "success--text",
          },
          {
            text: "",
            value: "action",
            align: "right",
            width: "60px",
          },
        ],
        SUBCOLUMNS: [
          {
            name: 'inputField1Name',
            model: 'activityVolume',
            rules: 'required|length_comma:10|number_comma_full_width:true|numeric_right:3',
            show: (item) => ['0144', '0145'].includes(item.calculationMethodCode),
            editable: true,
            unit: (item) => item.activityVolumeUnit,
          },
          {
            name: '排出量',
            model: 'activityVolume',
            rules: 'required|length_comma:10|number_comma_full_width:true|numeric_right:3',
            show: (item) => item.calculationMethodCode === '0902',
            editable: true,
            unit: 'activityVolumeUnit'
          },
        ],
        FORMULA: {},
        ADD_DIALOG: {
          getName: {
            '0144': ['calculationMethodName1'],
            '0145': ['calculationMethodName1'],
            '0902': ['calculationMethodName1'],
          },
          formValues: {
            productActionName: '',
            calculationMethod: null,
            inputField1Name: '',
            inputField2Name: '',
            memo: '',
            emissionIntensity: null,
            emissionUnit: null,
            calculationTarget: null,
            fuelUnits071: null,
            fuelUnits070: null,
          },
          formFields: {
            productActionName: {
              model: 'productActionName',
              rules: 'required|max:100',
              component: 'v-text-field',
              props: {
                label: '活動名',
                type: 'text',
              },
            },
            calculationMethod: {
              model: 'calculationMethod',
              rules: 'required',
              component: 'v-select',
              props: {
                label: '入力方法',
                items: [],
                'item-text': 'name1',
                'return-object': true,
              },
            },
            fuelUnits071: {
              model: 'calculationTarget',
              rules: 'required',
              component: 'v-select',
              show: (data) => data.formValues.calculationMethod?.code === '0144',
              props: {
                label: '燃料',
                items: [],
                'item-text': 'name1',
                'return-object': true,
              },
            },
            fuelUnits070: {
              model: 'calculationTarget',
              rules: 'required',
              component: 'v-select',
              show: (data) => data.formValues.calculationMethod?.code === '0145',
              props: {
                label: '燃料',
                items: [],
                'item-text': 'name1',
                'return-object': true,
              },
            },
            memo: {
              component: 'v-text-field',
              model: 'memo',
              rules: 'max:100',
              props: {
                label: 'メモ',
                type: 'text',
              },
            },
          },
          dataMapper: {
            calculationMethod: 'threeCds',
            fuelUnits071: 'fuelUnits071',
            fuelUnits070: 'fuelUnits070',
          }
        },
      },
      S3_C11: {
        ACTIVITY_HEADERS: [
          {
            text: "活動名",
            value: "productActionName",
            align: "left",
            width: "100px"
          },
          {
            text: "算定方法",
            value: "calculationMethodName",
            align: "left",
            width: "25%",
          },
          {
            text: "活動量",
            value: "activityValues",
            align: "center",
            width: "55%",
          },
          {
            text: "排出量",
            value: "emissionVolume",
            align: "right",
            width: "10%",
            class: "success--text",
          },
          {
            text: "",
            value: "action",
            align: "right",
            width: "60px",
          },
        ],
        SUBCOLUMNS: [
          {
            name: '想定生涯使用回数',
            model: 'activityVolume',
            rules: 'required|length_comma:10|number_comma_full_width:true|numeric_right:3',
            show: (item) => ['0155', '0156', '0160', '0161'].includes(item.calculationMethodCode),
            editable: true,
            unit: 'activityVolumeUnit'
          },
          {
            name: '想定生涯使用回数の割合',
            model: 'expectedLifetimeUsagePer',
            rules: 'required|number_max:3',
            show: (item) => item.calculationMethodCode === '0160',
            editable: true,
            unit: '%'
          },
          {
            name: '販売数',
            model: 'saleCount',
            rules: 'required|length_comma:10|number_comma_full_width:true|numeric_right:3',
            show: (item) => ['0155', '0156', '0160', '0161'].includes(item.calculationMethodCode),
            editable: true,
            unit: 'saleCountUnit'
          },
          {
            name: '使用1回あたりの燃料使用量',
            model: 'perLessonUsage',
            rules: 'required|length_comma:5|number_comma_full_width:true|numeric_right:10',
            show: (item) => ['0155', '0160'].includes(item.calculationMethodCode),
            editable: true,
            unit: 'perLessonUsageUnit',
            precision: 10
          },
          {
            name: '使用1回あたりの電気使用量',
            model: 'perLessonUsage',
            rules: 'required|length_comma:5|number_comma_full_width:true|numeric_right:10',
            show: (item) => ['0156', '0161'].includes(item.calculationMethodCode),
            editable: true,
            unit: 'perLessonUsageUnit',
            precision: 10
          },
          {
            name: '排出原単位',
            model: 'emissionIntensity',
            rules: 'required|length_comma:5|number_comma_full_width:true|numeric_right:10',
            show: (item) => ['0155', '0156', '0160', '0161'].includes(item.calculationMethodCode),
            editable: true
          },
          {
            name: '温室効果ガス排出量',
            model: 'activityVolume',
            rules: 'required|length_comma:10|number_comma_full_width:true|numeric_right:3',
            show: (item) => ['0157', '0162'].includes(item.calculationMethodCode),
            editable: true
          },
          {
            name: '地球温暖化係数',
            model: 'gwp',
            rules: 'required|number_max:6',
            show: (item) => ['0157', '0162'].includes(item.calculationMethodCode),
            editable: true,
          },
          {
            name: '燃料販売量',
            model: 'activityVolume',
            rules: 'required|length_comma:10|number_comma_full_width:true|numeric_right:3',
            show: (item) => item.calculationMethodCode === '0158',
            editable: true,
            unit: 'activityVolumeUnit'
          },
          {
            name: '排出原単位',
            model: 'emissionIntensity',
            rules: 'required|length_comma:5|number_comma_full_width:true|numeric_right:10',
            show: (item) => item.calculationMethodCode === '0158',
            disabled: () => true,
            unit: 'emissionUnit'
          },
          {
            name: '製品あたりの温室効果ガス含有量',
            model: 'activityVolume',
            rules: 'required|length_comma:10|number_comma_full_width:true|numeric_right:3',
            show: (item) => item.calculationMethodCode === '0159',
            editable: true,
            unit: 'activityVolumeUnit'
          },
          {
            name: '販売数',
            model: 'saleCount',
            rules: 'required|length_comma:10|number_comma_full_width:true|numeric_right:3',
            show: (item) => item.calculationMethodCode === '0159',
            editable: true,
            unit: 'saleCountUnit'
          },
          {
            name: '生涯使用の排出率',
            model: 'emissionLifetimeUse',
            rules: 'required|length_comma:5|number_comma_full_width:true|numeric_right:10',
            show: (item) => item.calculationMethodCode === '0159',
            editable: true,
            unit: '%',
            converter: 0.01,
            precision: 10
          },
          {
            name: '排出原単位',
            model: 'emissionIntensity',
            rules: 'required|length_comma:5|number_comma_full_width:true|numeric_right:10',
            show: (item) => item.calculationMethodCode === '0159',
            editable: true,
          },
          {
            name: '排出量',
            model: 'activityVolume',
            rules: 'required|length_comma:10|number_comma_full_width:true|numeric_right:3',
            show: (item) => item.calculationMethodCode === '0902',
            editable: true,
            unit: 'activityVolumeUnit'
          },
        ],
        FORMULA: {
          '0155': ['activityVolume', '*', 'saleCount', '*', 'perLessonUsage', '*', 'emissionIntensity'],
          '0156': ['activityVolume', '*', 'saleCount', '*', 'perLessonUsage', '*', 'emissionIntensity'],
          '0157': ['activityVolume', '*', 'gwp'],
          '0158': ['activityVolume', '*', 'emissionIntensity'],
          '0159': ['activityVolume', '*', 'saleCount', '*', 'emissionLifetimeUse', '*', 'emissionIntensity'],
          '0162': ['activityVolume', '*', 'gwp'],
          '0160': ['activityVolume', '*', 'expectedLifetimeUsagePer', '*', '0.01', '*', 'saleCount', '*', 'perLessonUsage', '*', 'emissionIntensity'],
          '0161': ['activityVolume', '*', 'saleCount', '*', 'perLessonUsage', '*', 'emissionIntensity'],
        },
        ADD_DIALOG: {
          getName: {
            '0155': ['calculationMethodName1'],
            '0156': ['calculationMethodName1'],
            '0157': ['calculationMethodName1'],
            '0158': ['calculationMethodName1', 'calculationTargetName1'],
            '0159': ['calculationMethodName1'],
            '0160': ['calculationMethodName1'],
            '0161': ['calculationMethodName1'],
            '0162': ['calculationMethodName1'],
            '0902': ['calculationMethodName1'],
          },
          formValues: {
            productActionName: '',
            calculationMethod: null,
            calculationTarget: null,
            inputField1Name: '',
            inputField2Name: '',
            memo: '',
            emissionIntensity: null,
            emissionUnit: null,
            // 販売数
            saleCount: 0,
            // 販売数単位
            saleCountUnit: null,
            // 使用1回あたりの使用量
            perLessonUsage: 0,
            // 使用1回あたりの使用量単位
            perLessonUsageUnit: null,
            // 生涯使用の排出量
            emissionLifetimeUse: 0,
            // 想定生涯使用回数の割合
            expectedLifetimeUsagePer: null
          },
          formFields: {
            productActionName: {
              model: 'productActionName',
              rules: 'required|max:100',
              component: 'v-text-field',
              props: {
                label: '活動名',
                type: 'text',
              },
            },
            calculationMethod: {
              model: 'calculationMethod',
              rules: 'required',
              component: 'v-select',
              props: {
                label: '入力方法',
                items: [],
                'item-text': (i) => i.name1,
                'return-object': true,
                'item-value': (i) => ({ ...i })
              },
            },
            calculationTarget: {
              model: 'calculationTarget',
              rules: 'required',
              component: 'v-select',
              show: (data) => data.formValues.calculationMethod?.code === '0158',
              props: {
                label: '燃料の種類',
                items: [],
                'item-text': (i) => i.name1,
                'return-object': true,
              },
            },
            inputField1Name: {
              model: 'inputField1Name',
              rules: 'required|max:100',
              component: 'v-text-field',
              show: (data) => data.formValues.calculationMethod?.code === '0901',
              props: {
                label: '値1の項目名',
                type: 'text',
              },
            },
            inputField2Name: {
              model: 'inputField2Name',
              rules: 'required|max:100',
              component: 'v-text-field',
              show: (data) => data.formValues.calculationMethod?.code === '0901',
              props: {
                label: '値2の項目名',
                type: 'text',
              },
            },
            memo: {
              component: 'v-text-field',
              model: 'memo',
              rules: 'max:100',
              props: {
                label: '使用シナリオ',
                type: 'text',
              },
            },
          },
          dataMapper: {
            calculationMethod: 'elevens',
            calculationTarget: 'fuelTypes'
          }
        },
      },
      S3_C12: {
        ACTIVITY_HEADERS: [
          {
            text: "活動名",
            value: "productActionName",
            align: "left",
            width: "12%",
          },
          {
            text: "算定方法",
            value: "calculationMethodName",
            align: "left",
            width: "22%",
          },
          {
            text: "廃棄物の種類",
            value: "calculationTargetName",
            align: "left",
            width: "18%",
          },
          {
            text: "活動量",
            value: "activityValues",
            align: "center",
            width: "30%",
          },
          {
            text: "排出原単位",
            value: "emissionUnit",
            align: "center",
            width: "15%",
          },
          {
            text: "排出量",
            value: "emissionVolume",
            align: "right",
            width: "10%",
            class: "success--text",
          },
          {
            text: "",
            value: "action",
            align: "right",
            width: "60px",
          },
        ],
        SUBCOLUMNS: [
          {
            name: '廃棄量',
            model: 'activityVolume',
            rules: 'required|length_comma:10|number_comma_full_width:true|numeric_right:3',
            unit: 'activityVolumeUnit',
            show: (item) => ['0123', '0124'].includes(item.calculationMethodCode) && item.calculationTargetCodeId !== '080',
            showDetailButton: true
          },
          {
            name: 'リサイクル量',
            model: 'activityVolume',
            rules: 'required|length_comma:10|number_comma_full_width:true|numeric_right:3',
            unit: 'activityVolumeUnit',
            show: (item) => item.calculationTargetCodeId === '080',
            showDetailButton: true
          },
          {
            name: 'inputField1Name',
            model: 'activityVolume',
            rules: 'required|length_comma:10|number_comma_full_width:true|numeric_right:3',
            show: (item) => item.calculationMethodCode === '0901',
            editable: true
          },
          {
            name: 'inputField2Name',
            model: 'emissionIntensity',
            rules: 'required|length_comma:5|number_comma_full_width:true|numeric_right:10',
            show: (item) => item.calculationMethodCode === '0901',
            editable: true
          },
          {
            name: '排出量',
            model: 'activityVolume',
            rules: 'required|length_comma:10|number_comma_full_width:true|numeric_right:3',
            show: (item) => item.calculationMethodCode === '0902',
            editable: true,
            unit: 'activityVolumeUnit'
          },
        ],
        FORMULA: {},
        ADD_DIALOG: {
          getName: {
            '0123': ['calcMethodsName1', 'transNecMethodsName1'],
            '0124': ['calcMethodsName1', 'transNecMethodsName1'],
            '0901': ['calculationMethodName1'],
            '0902': ['calculationMethodName1'],
          },
          formValues: {
            productActionName: '',
            calculationMethod: null,
            calculationTarget: null,
            inputField1Name: '',
            inputField2Name: '',
            memo: '',
            emissionIntensity: null,
            emissionUnit: null,
            calcMethods: null,
            transNecMethods: null
          },
          formFields: {
            productActionName: {
              model: 'productActionName',
              rules: 'required|max:100',
              component: 'v-text-field',
              props: {
                label: '活動名',
                type: 'text',
              },
            },
            calculationMethod: {
              model: 'calculationMethod',
              rules: 'required',
              component: 'v-select',
              props: {
                label: '入力方法',
                items: [],
                'item-text': 'name1',
                'return-object': true,
              },
            },
            calcMethods: {
              model: 'calcMethods',
              rules: 'required',
              component: 'v-select',
              show: (data) => data.formValues.calculationMethod?.code === '0118',
              props: {
                label: '算定方法（廃棄物の処理方法）',
                items: [],
                'item-text': 'name1',
                'return-object': true,
              },
            },
            transNecMethods: {
              model: 'transNecMethods',
              rules: 'required',
              component: 'v-radio-group',
              show: (data) => data.formValues.calculationMethod?.code === '0118',
              props: {
                label: '輸送の要否',
                'return-object': true,
              },
              items: []
            },
            // dummyTrashType: {
            //   model: 'calculationTarget',
            //   rules: 'required',
            //   component: 'v-select',
            //   show: (data) => data.formValues.calculationMethod?.code === '0118' && !data.formValues.calcMethods,
            //   props: {
            //     label: '廃棄物の種類',
            //     items: [],
            //     'item-text': 'name1',
            //     'return-object': true,
            //   },
            // },
            secondTrashType: {
              model: 'calculationTarget',
              rules: 'required',
              component: 'v-select',
              show: (data) => data.formValues.calcMethods?.code === '0119',
              props: {
                label: '廃棄物の種類',
                items: [],
                'item-text': 'name1',
                'return-object': true,
              },
            },
            thirdTrashType: {
              model: 'calculationTarget',
              rules: 'required',
              component: 'v-select',
              show: (data) => data.formValues.calcMethods?.code === '0120',
              props: {
                label: '廃棄物の種類',
                items: [],
                'item-text': 'name1',
                'return-object': true,
              },
            },
            firstTrashType: {
              model: 'calculationTarget',
              rules: 'required',
              component: 'v-select',
              show: (data) => data.formValues.calcMethods?.code === '0121',
              props: {
                label: '廃棄物の種類',
                items: [],
                'item-text': 'name1',
                'return-object': true,
              },
            },
            fourthTrashType: {
              model: 'calculationTarget',
              rules: 'required',
              component: 'v-select',
              show: (data) => data.formValues.calcMethods?.code === '0122',
              props: {
                label: '廃棄物の種類',
                items: [],
                'item-text': 'name1',
                'return-object': true,
              },
            },
            inputField1Name: {
              model: 'inputField1Name',
              rules: 'required|max:100',
              component: 'v-text-field',
              show: (data) => data.formValues.calculationMethod?.code === '0901',
              props: {
                label: '値1の項目名',
                type: 'text',
              },
            },
            inputField2Name: {
              model: 'inputField2Name',
              rules: 'required|max:100',
              component: 'v-text-field',
              show: (data) => data.formValues.calculationMethod?.code === '0901',
              props: {
                label: '値2の項目名',
                type: 'text',
              },
            },
            memo: {
              component: 'v-text-field',
              model: 'memo',
              rules: 'max:100',
              show: (data) => ['0901', '0902'].includes(data.formValues.calculationMethod?.code),
              props: {
                label: 'メモ',
                type: 'text',
              },
            },
          },
          dataMapper: {
            calculationMethod: 'twelveCat',
            calcMethods: 'calcMethods',
            firstTrashType: 'firstTrashType',
            secondTrashType: 'secondTrashType',
            thirdTrashType: 'thirdTrashType',
            fourthTrashType: 'fourthTrashType',
          }
        },
        DETAIL_DIALOG: {
          '0123': {
            headers: [
              { text: '入力日', value: 'createDate', width: '8%', align: 'left' },
              { text: '入力者', value: 'createName', width: '8%', align: 'left' },
              {
                text: '廃棄量',
                value: 'activityVolume',
                width: '15%',
                align: 'left',
                unit: 'activityVolumeUnit',
                default: null
              },
              {
                text: '廃棄量の根拠',
                value: 'evidence',
                width: '30%',
                align: 'left',
                default: ''
              },
              {
                text: 'メモ',
                value: 'activityDetailMemo',
                width: '26%',
                align: 'left',
                default: ''
              },
              { text: '削除', value: 'remove', width: '9%', align: 'left', default: false },
              { text: '明細コピー', value: 'action', width: '120px', align: 'left', default: false, guide: 'Q0018' },
            ],
          },
          '0124': {
            headers: [
              { text: '入力日', value: 'createDate', width: '8%', align: 'left' },
              { text: '入力者', value: 'createName', width: '8%', align: 'left' },
              {
                text: '廃棄量',
                value: 'activityVolume',
                width: '15%',
                align: 'left',
                unit: 't',
                default: null
              },
              {
                text: '廃棄量の根拠',
                value: 'evidence',
                width: '30%',
                align: 'left',
                default: ''
              },
              {
                text: 'メモ',
                value: 'activityDetailMemo',
                width: '26%',
                align: 'left',
                default: ''
              },
              { text: '削除', value: 'remove', width: '9%', align: 'left', default: false },
              { text: '明細コピー', value: 'action', width: '120px', align: 'left', default: false, guide: 'Q0018' },
            ],
          },
          '0121': {
            headers: [
              { text: '入力日', value: 'createDate', width: '8%', align: 'left' },
              { text: '入力者', value: 'createName', width: '8%', align: 'left' },
              {
                text: 'リサイクル量',
                value: 'activityVolume',
                width: '15%',
                align: 'left',
                unit: 'activityVolumeUnit',
                default: null
              },
              {
                text: 'リサイクル量の根拠',
                value: 'evidence',
                width: '30%',
                align: 'left',
                default: ''
              },
              {
                text: 'メモ',
                value: 'activityDetailMemo',
                width: '26%',
                align: 'left',
                default: ''
              },
              { text: '削除', value: 'remove', width: '9%', align: 'left', default: false },
            ],
          },
        },
        //算定方法ー廃棄量の処理方法
        CALCULATION_DISPOSAL_METHOD_CODE: {
          //焼却処理
          INCINERATION: {
            code: "0119",
            item2: "焼却処理"
          },
          //埋立処理
          LANDFILL_DISPOSAL: {
            code: "0120",
            item2: "埋立処理"
          },
          //リサイクル
          RECYLING: {
            code: "0121",
            item2: "リサイクル"
          },
          //処理方法不明
          UNKNOWN_PROCESSING_METHOD: {
            code: "0122",
            item2: "処理方法不明"
          }
        }
      },
    },
    SCOPE_THREE_DETAIL_TABLE_HEADER: {
      // 算定方法が「燃料法」の場合
      FUEL_METHOD: [
        { text: "入力日", value: "createDate", width: "8%", align: "left" },
        { text: "入力者", value: "createName", width: "8%", align: "left" },
        {
          text: "燃料使用量",
          value: "value",
          width: "15%",
          align: "left",
        },
        {
          text: "荷主別按分",
          value: "shipperDistribution",
          width: "10%",
          align: "left",
          remark: "Q0005",
        },
        {
          text: "活動量の根拠",
          value: "evidence",
          width: "30%",
          align: "left",
        },
        {
          text: "メモ",
          value: "activityDetailMemo",
          width: "26%",
          align: "left",
        },
        { text: "削除", value: "remove", width: "9%", align: "left" },
        { text: '明細コピー', value: 'action', width: '120px', align: 'left', default: false, guide: 'Q0018' },
      ],
      // 算定方法が「燃費法」の場合
      FUEL_CONSUMPTION_METHOD: [
        { text: "入力日", value: "createDate", width: "8%", align: "left" },
        { text: "入力者", value: "createName", width: "8%", align: "left" },
        {
          text: "輸送距離",
          value: "transportDistance",
          width: "15%",
          align: "left",
        },
        {
          text: "荷主別按分",
          value: "shipperDistribution",
          width: "10%",
          align: "left",
          remark: "Q0005",
        },
        {
          text: "活動量の根拠",
          value: "evidence",
          width: "30%",
          align: "left",
        },
        {
          text: "メモ",
          value: "activityDetailMemo",
          width: "26%",
          align: "left",
        },
        { text: "削除", value: "remove", width: "56px", align: "left" },
        { text: '明細コピー', value: 'action', width: '120px', align: 'left', default: false, guide: 'Q0018' },
      ],
      // 算定方法が「トンキロ法」の場合
      TON_KILOMETER_METHOD: [
        { text: "入力日", value: "createDate", width: "8%", align: "left" },
        { text: "入力者", value: "createName", width: "8%", align: "left" },
        {
          text: "貨物重量",
          value: "value",
          width: "15%",
          align: "left",
        },
        {
          text: "荷主別按分",
          value: "shipperDistribution",
          width: "10%",
          align: "left",
          remark: "Q0005",
        },
        {
          text: "輸送距離",
          value: "transportDistance",
          width: "15%",
          align: "left",
        },
        {
          text: "活動量の根拠",
          value: "evidence",
          width: "30%",
          align: "left",
        },
        {
          text: "メモ",
          value: "activityDetailMemo",
          width: "26%",
          align: "left",
        },
        { text: "削除", value: "remove", width: "56px", align: "left" },
        { text: '明細コピー', value: 'action', width: '120px', align: 'left', default: false, guide: 'Q0018' },
      ],
      // 拠点(荷役、保管、販売)のエネルギー使用に伴う排出量
      BASIC_EMISSION_COEFFICIENT: [
        { text: "入力日", value: "createDate", width: "8%", align: "left" },
        { text: "入力者", value: "createName", width: "8%", align: "left" },
        {
          text: "燃料使用量",
          value: "value",
          width: "15%",
          align: "left",
        },
        {
          text: "荷主別按分",
          value: "shipperDistribution",
          width: "10%",
          align: "left",
          remark: "Q0005",
        },
        {
          text: "活動量の根拠",
          value: "evidence",
          width: "30%",
          align: "left",
        },
        {
          text: "メモ",
          value: "activityDetailMemo",
          width: "26%",
          align: "left",
        },
        { text: "削除", value: "remove", width: "56px", align: "left" },
        { text: '明細コピー', value: 'action', width: '120px', align: 'left', default: false, guide: 'Q0018' },
      ],
      // 入力方法が「整備時の充填量・回収量から算定」の場合
      FILLING_RECOVERY: [
        { text: "入力日", value: "createDate", width: "8%", align: "left" },
        { text: "入力者", value: "createName", width: "8%", align: "left" },
        {
          text: "充填量",
          value: "value",
          width: "15%",
          align: "left",
          remark:
            "Q0002",
          rule: 'required|number_max:10'
        },
        {
          text: "回収・適正処理量",
          value: "recoveryVolume",
          width: "10%",
          align: "left",
          remark:
            "Q0002",
          rule: 'required|number_max:13'
        },
        {
          text: "活動量の根拠",
          value: "evidence",
          width: "30%",
          align: "left",
        },
        {
          text: "メモ",
          value: "activityDetailMemo",
          width: "26%",
          align: "left",
        },
        { text: "削除", value: "remove", width: "56px", align: "left" },
        { text: '明細コピー', value: 'action', width: '120px', align: 'left', default: false, guide: 'Q0018' },
      ],
      // 入力方法が「漏えい率から算定」の場合
      LEAKAGE_RATE: [
        { text: "入力日", value: "createDate", width: "8%", align: "left" },
        { text: "入力者", value: "createName", width: "8%", align: "left" },
        {
          text: "稼動機器に含まれる冷媒量",
          value: "value",
          width: "15%",
          align: "left",
          remark:
            "Q0002",
        },
        {
          text: "回収・適正処理量",
          value: "recoveryVolume",
          width: "10%",
          align: "left",
          remark:
            "Q0002",
        },
        {
          text: "活動量の根拠",
          value: "evidence",
          width: "30%",
          align: "left",
        },
        {
          text: "メモ",
          value: "activityDetailMemo",
          width: "26%",
          align: "left",
        },
        { text: "削除", value: "remove", width: "56px", align: "left" },
        { text: '明細コピー', value: 'action', width: '120px', align: 'left', default: false, guide: 'Q0018' },
      ],
      DB5: [
        { text: "入力日", value: "createDate", width: "8%", align: "left" },
        { text: "入力者", value: "createName", width: "8%", align: "left" },
        {
          text: "物量",
          value: "value",
          width: "15%",
          align: "left",
        },
        {
          text: "活動量の根拠",
          value: "evidence",
          width: "30%",
          align: "left",
        },
        {
          text: "メモ",
          value: "activityDetailMemo",
          width: "26%",
          align: "left",
        },
        { text: "削除", value: "remove", width: "9%", align: "left" },
        { text: '明細コピー', value: 'action', width: '120px', align: 'left', default: false, guide: 'Q0018' },
      ],

    },
    // 質問マークのガイド
    GUIDE: {},
    /** 企業マスタ */
    BASE_ORGANIZATION: {
      /** 企業マスタ：拠点コード */
      BASE_CODE: "01",
      /** 企業マスタ：組織コード */
      ORGANIZATION_CODE: "02",
    },
    PAGE_MODE: {
      /** 初期化状態 */
      INIT: 0,
      /** 検索済状態 */
      SEARCH: 1,
    },
    ACTIVITY_SCOPE: {
      SCOPE_ONE: "1",
      SCOPE_TWO: "2",
      SCOPE_THREE: "3",
    },
    FUEL_TYPE: {
      ELECTRICITY: "09",
    },
    CALCULATION_METHOD: {
      BASE_EMISSION: "01",
      ADJUSTMENT_EMISSION: "02",
      //処理方法不明
      UNKNOWN_PROCESSING_METHOD: {
        code: "0122",
        name: "処理方法不明"
      }
    },
    ACTIVITY_CATEGORY: {
      DEFAULT: "00",
    },
    INPUT_METHOD: {
      /** 産業連関表ベース */
      INDUSTRIAL_RELATION: "0101",
      /** 物量ベース */
      QUANTITY_BASE: "0102",
      /** 生産者価格ベース */
      PRODUCER_PRICE: "0103",
      /** 購入者価格ベース */
      PURCHASER_PRICE: "0104",
      /** 金額ベース */
      PRICE_BASE: "0105",
      /** 燃料から算出 */
      CALCULATION_FROM_FUEL: "0107",
      /** 燃料から算出（エネルギー使用に伴う排出量） */
      CALCULATION_FROM_FUEL_ENERGY: "0903",
      /** 燃料法 */
      FUEL_METHOD: "0108",
      /** 燃費法 */
      FUEL_CONSUMPTION_METHOD: "0109",
      /** トンキロ法 */
      TON_KILOMETER_METHOD: "0110",
      /** SHK制度 */
      SHK_PROGRAM: "0112",
      /** 基礎排出係数 */
      BASIC_EMISSION_COEFFICIENT: "0113",
      /** 調整後排出係数 */
      ADJUSTMENT_EMISSION_COEFFICIENT: "0114",
      /** 整備時の充填量・回収量から算定 */
      FILLING_RECOVERY: "0116",
      /** 漏えい率から算定 */
      LEAKAGE_RATE: "0117",
      /** 活動量×原単位 (値1×値2) */
      EMISSION_INPUT: "0901",
      /** 直接手入力 */
      HAND_INPUT: "0902",
      /** SCOPE1,2の排出量を投資持分比率に応じて積み上げで算出 */
      EMISSIONS_AS_INVESTMENT_INPUT: "0146",
      /** SCOPE1,2の排出量を投資持分比率に応じて積み上げで算出(任意加算あり)(With optional addition) */
      EMISSIONS_AS_INVESTMENT_WOA_INPUT: "0147",
      /** 経済データを用いて推計 */
      ESTIMATED_USING_ECONOMIC_INPUT: "0148",
      /** 経済データを用いて推計(任意加算あり)(With optional addition) */
      ESTIMATED_USING_ECONOMIC_WOA_INPUT: "0149",
      /** 廃棄量から算出 */
      CALCULATED_AMOUNT_OF_WASTE: "0118",
      /** 販売先から加工に伴う排出量データを入手できる場合 */
      PROCESSING_EMISSION: "0150",
      /** 販売先から加工に伴うエネルギー消費データを入手できる場合 */
      PROCESSING_ENERGY: "0151",
      /** 販売先から加工に伴うエネルギー消費データ等を入手できない場合 */
      NOT_PROCESSING_ENERGY: "0152",
    },
    /** 燃費の把握 */
    FUEL_GRASP: {
      /** 把握できる */
      POSSIBLE: "01",
    },
    INPUT_METHOD_GROUP: {
      /** [１]産業連関表ベースの排出原単位 */
      DB1: "DB1",
      /** [２]産業連関表ベースの排出原単位 */
      DB2: "DB2",
      DB3: "DB3",
      DB4: "DB4",
      /** [５]産業連関表ベースの排出原単位 */
      //HEAD
      DB5: "DB5",
      DB0: "DB0",
      /** 事業から出る廃棄物 */
      DB8: "DB8",
    },
    CATEGORY_GROUP_CODE: {
      DB8: "0142",
      DB2: "0143",
    },
    USER_TABLE_HEADER: [
      {
        text: '',
        value: 'action',
        width: '60px',
      },
      {
        text: "ユーザID",
        value: "userName",
        width: "10%",
        align: "center",
        sortable: false,
      },
      {
        text: "氏名",
        value: "userNameKanji",
        width: "20%",
        align: "center",
        sortable: false,
      },
      {
        text: "氏名カナ",
        value: "userNameKana",
        width: "20%",
        align: "center",
        sortable: false,
      },
      {
        text: "組織",
        value: "organizationName",
        width: "10%",
        align: "center",
        sortable: false,
      },
      {
        text: "権限",
        value: "userAuthorityName",
        width: "20%",
        align: "center",
        sortable: false,
      },
      {
        text: "メールアドレス",
        value: "email",
        width: "20%",
        align: "center",
        sortable: false,
      },
      {
        text: '',
        value: 'action_2',
        width: '60px',
      },
    ],
    SIGNUP_FORM: {
      user_id: {
        model: 'user_id',
        rules: 'required|alpha_num|max:10',
        component: 'v-text-field',
        props: {
          label: "user_registration.user_id",
          type: 'text',
        },
      },
      user_name_kanji: {
        model: 'user_name_kanji',
        rules: 'required|max:30',
        component: 'v-text-field',
        props: {
          label: 'user_registration.user_name_kanji',
          type: 'text',
        },
      },
      user_name_kana: {
        model: 'user_name_kana',
        rules: 'kana|max:30',
        component: 'v-text-field',
        props: {
          label: 'user_registration.user_name_kana',
          type: 'text',
        },
      },
      // user_authority_code: {
      //   model: 'user_authority_code',
      //   rules: 'required',
      //   component: 'v-select',
      //   props: {
      //     label: 'user_registration.authority',
      //     items: [],
      //     'item-text': 'item1',
      //   },
      // },
      organization_id: {
        model: 'organization_id',
        rules: 'required',
        component: 'tree-select',
        props: {
          label: 'user_registration.organization',
          openDirection: 'top',
          maxHeight: null,
          items: [],
        },
      },
      email: {
        model: 'email',
        rules: 'required|email|max:50',
        component: 'v-text-field',
        props: {
          label: 'user_registration.email',
          type: 'text',
        },
      },
    },
    USER_AUTHORITY_KBN: {
      ONLY_DISPLAY: "1",
      CAN_INPUT: "2",
      SYSTEM_ADMIN: "9",
    },
    DEFAULT_STEP_UNIT: 50,
    BUTTON_CHOICE: {
      YES: "はい",
      NO: "いいえ",
      CANCEL: "キャンセル",
    },
  };

  inject("constants", constants);
};
