export default () => ({
  userInfo: {
    user_name: "開発ユーザ",
    loc_id: "01",
    dept_id: "honbu",
  },
  mockMasterCodeList: {},
  /** スコープ一覧 */
  scopeList: [],
  /** 単位一覧 */
  unitList: [],
  unitLabelList: [],
  selectedOrg: null,
  targetMonth: null,
  /** 拠点組織一覧 */
  baseOrganizationList: [],
  /** 組織マスタ一覧 */
  organizationList: [],
  /** 拠点マスタ一覧 */
  baseList: [],
  /** スコープ1の算定対象一覧 */
  calculationTargetScope1List: [],
  /** スコープ1の算定方法一覧 */
  calculationMethodScope1List: [],
  emissionIntensityScope1List: [],
  /** スコープ２の排出活動一覧 */
  emissionActivityList: [],
  /** スコープ２の小売電気事業者一覧 */
  electricCompanyList: [],
  /** スコープ２の小売電気事業者排出係数一覧 */
  emissionCoefficientList: [],
  emissionIntensityList: [],
  tkgBasicUnitList: [],
  carFuelEconomyList: [],

  divisionTypeList: [],
  divisionList: [],
  locationList: [] /* 不用 */,
  deptList: []  /* 不用 */,
  categoryList: [],
  electricTable: {},
  industryConnectionTableList: {},
  transportTableList: {},
  capitalMoneyTable: [],
  fuelSupplyTable: [],
  fuelTypeUsageCoefTable: [],
  fuelTypeCalorificTable: [],
  operatorList: [],
  trashRecycleTableList: {},
  trafficFeeTable: [],
  fuelEconomyTable: {},
  hasMeisaiUpdateData : false,
})
