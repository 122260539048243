import Vue from "vue"
import {
  ValidationProvider,
  ValidationObserver,
  extend,
  localize,
} from "vee-validate"
import * as originalRules from "vee-validate/dist/rules"
import * as CustomRules from "./vee-validate-custom-rules"
import ja from "@/lang/vee-validate/ja"

// 全てのルールをインポート
let rule
for (rule in originalRules) {
  extend(rule, {
    ...originalRules[rule],
  })
}

//カスタムルール設定
for (const rule in CustomRules) {
  extend(rule, CustomRules[rule])
}

// メッセージを設定
localize("ja", ja)

Vue.component("ValidationProvider", ValidationProvider)
Vue.component("ValidationObserver", ValidationObserver)
