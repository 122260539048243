import Vue from 'vue'
import Router from 'vue-router'
import { interopDefault } from './utils'
import scrollBehavior from './router.scrollBehavior.js'

const _616ae869 = () => interopDefault(import('../pages/dashboard.vue' /* webpackChunkName: "pages/dashboard" */))
const _56e09fea = () => interopDefault(import('../pages/owner/index.vue' /* webpackChunkName: "pages/owner/index" */))
const _77643861 = () => interopDefault(import('../pages/activity/board.vue' /* webpackChunkName: "pages/activity/board" */))
const _3cdf8f45 = () => interopDefault(import('../pages/activity/input.vue' /* webpackChunkName: "pages/activity/input" */))
const _672181bc = () => interopDefault(import('../pages/setting/calculate.vue' /* webpackChunkName: "pages/setting/calculate" */))
const _6358225a = () => interopDefault(import('../pages/setting/company.vue' /* webpackChunkName: "pages/setting/company" */))
const _028892c8 = () => interopDefault(import('../pages/setting/company_.vue' /* webpackChunkName: "pages/setting/company_" */))
const _652deac2 = () => interopDefault(import('../pages/setting/fiscal-year.vue' /* webpackChunkName: "pages/setting/fiscal-year" */))
const _62667baa = () => interopDefault(import('../pages/setting/masterregistercsvexport.vue' /* webpackChunkName: "pages/setting/masterregistercsvexport" */))
const _4ead0266 = () => interopDefault(import('../pages/setting/organization.vue' /* webpackChunkName: "pages/setting/organization" */))
const _0e98f076 = () => interopDefault(import('../pages/setting/process.vue' /* webpackChunkName: "pages/setting/process" */))
const _7dff0016 = () => interopDefault(import('../pages/setting/retail-electricity-providers.vue' /* webpackChunkName: "pages/setting/retail-electricity-providers" */))
const _42c3f18e = () => interopDefault(import('../pages/setting/retail-electricity-providers-emission.vue' /* webpackChunkName: "pages/setting/retail-electricity-providers-emission" */))
const _4307d936 = () => interopDefault(import('../pages/setting/user.vue' /* webpackChunkName: "pages/setting/user" */))
const _c0d98dfe = () => interopDefault(import('../pages/owner/setting/user.vue' /* webpackChunkName: "pages/owner/setting/user" */))
const _f51803b2 = () => interopDefault(import('../pages/index.vue' /* webpackChunkName: "pages/index" */))

// TODO: remove in Nuxt 3
const emptyFn = () => {}
const originalPush = Router.prototype.push
Router.prototype.push = function push (location, onComplete = emptyFn, onAbort) {
  return originalPush.call(this, location, onComplete, onAbort)
}

Vue.use(Router)

export const routerOptions = {
  mode: 'history',
  base: decodeURI('/'),
  linkActiveClass: 'nuxt-link-active',
  linkExactActiveClass: 'nuxt-link-exact-active',
  scrollBehavior,

  routes: [{
    path: "/dashboard",
    component: _616ae869,
    name: "dashboard"
  }, {
    path: "/owner",
    component: _56e09fea,
    name: "owner"
  }, {
    path: "/activity/board",
    component: _77643861,
    name: "activity-board"
  }, {
    path: "/activity/input",
    component: _3cdf8f45,
    name: "activity-input"
  }, {
    path: "/setting/calculate",
    component: _672181bc,
    name: "setting-calculate"
  }, {
    path: "/setting/company",
    component: _6358225a,
    name: "setting-company"
  }, {
    path: "/setting/company_",
    component: _028892c8,
    name: "setting-company_"
  }, {
    path: "/setting/fiscal-year",
    component: _652deac2,
    name: "setting-fiscal-year"
  }, {
    path: "/setting/masterregistercsvexport",
    component: _62667baa,
    name: "setting-masterregistercsvexport"
  }, {
    path: "/setting/organization",
    component: _4ead0266,
    name: "setting-organization"
  }, {
    path: "/setting/process",
    component: _0e98f076,
    name: "setting-process"
  }, {
    path: "/setting/retail-electricity-providers",
    component: _7dff0016,
    name: "setting-retail-electricity-providers"
  }, {
    path: "/setting/retail-electricity-providers-emission",
    component: _42c3f18e,
    name: "setting-retail-electricity-providers-emission"
  }, {
    path: "/setting/user",
    component: _4307d936,
    name: "setting-user"
  }, {
    path: "/owner/setting/user",
    component: _c0d98dfe,
    name: "owner-setting-user"
  }, {
    path: "/",
    component: _f51803b2,
    name: "index"
  }],

  fallback: false
}

export function createRouter () {
  return new Router(routerOptions)
}
