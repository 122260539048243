export const actions = {
  nuxtClientInit({ commit, state, dispatch }, { req }) {
    const co2 = localStorage.getItem('co2')
    if (co2) {
      let token = (JSON.parse(co2))?.app?.token
      if (token)
        commit('app/SET_TOKEN', token)
    }
  },
}
