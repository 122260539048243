import Vue from "vue"
// import { NuxtAppOptions } from "@nuxt/types"
import VueI18n from "vue-i18n"

// User lang files
import jaLocale from "@/lang/ja"
// import enLocale from "@/lang/en"

// Element-ui built-in fang files
// import elementJaLocale from 'element-ui/lib/locale/lang/ja'
// import elementEnLocale from 'element-ui/lib/locale/lang/en'

Vue.use(VueI18n)

const messages = {
  ja: {
    ...jaLocale,
    // ...elementJaLocale
  },
  // en: {
  //   ...enLocale,
  //   // ...elementEnLocale
  // },
}

export default ({ app }) => {
  app.i18n = new VueI18n({
    locale: "ja",
    fallbackLocale: "ja",
    messages,
  })
}

export const i18n = new VueI18n({
  locale: "ja",
  fallbackLocale: "ja",
  messages,
})
