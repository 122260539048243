export default () => ({
  filterCriteria: {
    targetMonth: null,
    targetMonthF: null,
    targetMonthT: null,
    division: "9",
    selectDivision: "",
    selectScopeList: [1, 2, 3],
    selectedCategoryList: ["01", '02', '03', "04", '05', '06', '07', '08', "09", "10", '11', '12', '13', '14', '15'],
  },
  totalScore: 0,
  totalScorePerLocList: {},
  scopeOneActivityInputList: {},
  screenItemList: [],
  screenRelationList: [],
  categoryGroupList: [],
  globalWarmingPotentialList: [],
  numberOfEmpCateSixFlg: false,
  catSixPersonnelAndCalcFlg: null
})
