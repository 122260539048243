export default {
  getMockMasterCodeLabelList: function (state) {
    const _returnLabelList = {}
    const _mockMasterCodeList = state.mockMasterCodeList
    for (let listKey in _mockMasterCodeList) {
      _returnLabelList[listKey] = []
      for (let i = 0; i < _mockMasterCodeList[listKey].length; i++) {
        let _key = _mockMasterCodeList[listKey][i]["value"]
        let _label = _mockMasterCodeList[listKey][i]["label"]
        _returnLabelList[listKey][_key] = _label
      }
    }

    return _returnLabelList
  },
}
