export default class ActivityService {
  constructor(axios) {
    this.axios = axios
  }

  async getInit(params) {
    return await this.axios.$get('/activity-input/init', { params: params });
  }

  async search(params) {
    return await this.axios.$post('/activity-input/search', params);
  }

  /**
   * 更新
   * @param {*} params 
   * @returns 
   */
  async update(params) {
    return await this.axios.$post('/activity-input/update', params);
  }

  getDetail(params) {
    return this.axios.$post('/activity-input/detail', params)
  }

  updateDetailOne(params) {
    return this.axios.$post('/activity-input/updateDetailOne', params)
  }

  getTotalEmissionByOrg(params) {
    return this.axios.$get('/activity-input/org-emission-vol', { params: params })
  }

  getTotalEmissionByBase(params) {
    return this.axios.$get('/activity-input/base-emission-vol', { params: params })
  }

  getCodeMethod(categoryId, targetMonth) {
    const url = `/code/code-methods-${categoryId}?categoryId=${categoryId}&fiscalYear=${targetMonth}`
    return this.axios.$get(url)
  }

  getSupplyMenu(companyNo) {
    return this.axios.$get(`/code/code-method-7-supply-menu?electric_company_no=${companyNo}`)
  }

  getPersonnel(year) {
    return this.axios.$get(`/code/personnel?fiscal_year=${year}`)
  }

  getCategory8Intensity(companyNo, buildingUsageCode) {
    return this.axios.$get(`/code/methods-8-emission-intensity?e_supply_reg_no=${companyNo}&building_use=${buildingUsageCode}`)
  }
}
