const FREE_INPUT_LABEL = "直接入力"
const FREE_MULTIPLE_LABEL = "活動量×原単位(任意の値)"

export default (context, inject) => {
  const mockdata = {
    STORE_CODE_STATE: context.app.store.state.codes,
    STORE_MOCK_MASTER_CODE_LABEL_LIST: {},
    scopeOneActivityInputList: [],
    scopeThreeActivityInputList: [],
    scopeTwoActivityInputList: [],
    scopeThreeAddInputItem: {},

    // DB実装後に置き換え
    set() {
      this.STORE_MOCK_MASTER_CODE_LABEL_LIST =
        context.app.store.getters["codes/getMockMasterCodeLabelList"]
      this.setScopeOneActivityInputList()
      this.setScopeThreeActivityInputList()
      this.setScopeTwoActivityInputList()
      this.setScopeThreeAddInputItem()
    },
    setScopeOneActivityInputList() {
      this.scopeOneActivityInputList = [
        {
          activity_name: "ガソリン",
          calc_source: this.STORE_MOCK_MASTER_CODE_LABEL_LIST["scopeOne"]["00"],
          calc_form: {
            input: [0, 34.6, 0.0182, 44, 12],
            operator: ["02", "02", "02", "03"],
          },
          // unit_cal_value: "34.6	GJ/kl",
          // emission_factor: "0.0183 tC/GJ",
          coef: 2.322,
          unit: "tCO2/kl",
          score: "",
          activity_values: [
            {
              form_target: 0,
              type: "text",
              labelKey: "ガソリン使用量",
              value: "",
              suffix: "kl",
            },
          ],
        },
        {
          activity_name: "ジェット燃料油",
          calc_source: this.STORE_MOCK_MASTER_CODE_LABEL_LIST["scopeOne"]["00"],
          calc_form: {
            input: [0, 36.7, 0.0183, 44, 12],
            operator: ["02", "02", "02", "03"],
          },
          // unit_cal_value: "36.7	GJ/kl",
          // emission_factor: "0.0183 tC/GJ",
          coef: 2.463,
          unit: "tCO2/kl",
          score: 0,
          activity_values: [
            {
              form_target: 0,
              type: "text",
              labelKey: "ジェット燃料油使用量",
              value: 0,
              suffix: "kl",
            },
          ],
        },
        {
          activity_name: "軽油",
          calc_source: this.STORE_MOCK_MASTER_CODE_LABEL_LIST["scopeOne"]["00"],
          calc_form: {
            input: [0, 37.7, 0.0183, 44, 12],
            operator: ["02", "02", "02", "03"],
          },
          // unit_cal_value: "37.7	GJ/kl",
          // emission_factor: "0.0183 tC/GJ",
          coef: 2.585,
          unit: "tCO2/kl",
          score: 0,
          activity_values: [
            {
              form_target: 0,
              type: "text",
              labelKey: "軽油使用量",
              value: 0,
              suffix: "kl",
            },
          ],
        },
        {
          activity_name: "A重油",
          calc_source: this.STORE_MOCK_MASTER_CODE_LABEL_LIST["scopeOne"]["00"],
          calc_form: {
            input: [0, 39.1, 0.0189, 44, 12],
            operator: ["02", "02", "02", "03"],
          },
          // unit_cal_value: "39.1	GJ/kl",
          // emission_factor: "0.0189 tC/GJ",
          coef: 2.71,
          unit: "tCO2/kl",
          score: 0,
          activity_values: [
            {
              form_target: 0,
              type: "text",
              labelKey: "A重油使用量",
              value: 0,
              suffix: "kl",
            },
          ],
        },
        {
          activity_name: "B・C重油",
          calc_source: this.STORE_MOCK_MASTER_CODE_LABEL_LIST["scopeOne"]["00"],
          calc_form: {
            input: [0, 41.9, 0.0195, 44, 12],
            operator: ["02", "02", "02", "03"],
          },
          // unit_cal_value: "41.9	GJ/kl",
          // emission_factor: "0.0195 tC/GJ",
          coef: 2.996,
          unit: "tCO2/kl",
          score: 0,
          activity_values: [
            {
              form_target: 0,
              type: "text",
              labelKey: "A重油使用量",
              value: 0,
              suffix: "kl",
            },
          ],
        },
        {
          activity_name: "液化石油ガス（LPG）",
          calc_source: this.STORE_MOCK_MASTER_CODE_LABEL_LIST["scopeOne"]["00"],
          calc_form: {
            input: [0, 50.8, 0.0161, 44, 12],
            operator: ["02", "02", "02", "03"],
          },
          // unit_cal_value: "50.8	GJ/kl",
          // emission_factor: "0.0161 tC/GJ",
          coef: 2.999,
          unit: "tCO2/kl",
          score: 0,
          activity_values: [
            {
              form_target: 0,
              type: "text",
              labelKey: "液化石油ガス（LPG）",
              value: 0,
              suffix: "kl",
            },
          ],
        },
        {
          activity_name: "都市ガス",
          calc_source: this.STORE_MOCK_MASTER_CODE_LABEL_LIST["scopeOne"]["00"],
          calc_form: {
            input: [0, 44.8, 0.0136, 44, 12],
            operator: ["02", "02", "02", "03"],
          },
          // unit_cal_value: "44.8	GJ/千Nm3",
          // emission_factor: "0.0136 tC/GJ",
          coef: 2.234,
          unit: "tCO2/千Nm3",
          score: 0,
          activity_values: [
            {
              form_target: 0,
              type: "text",
              labelKey: "都市ガス",
              value: 0,
              suffix: "千Nm3",
            },
          ],
        },
      ]

      // vid指定のために連番を付与
      for (let i = 0; i < this.scopeOneActivityInputList.length; i++) {
        this.scopeOneActivityInputList[i]["activity_id"] = ("0000" + i).slice(
          -4
        )
        this.scopeOneActivityInputList[i]["scope_id"] = "01"
        let activity_values = this.scopeOneActivityInputList[i][
          "activity_values"
        ]
        for (let j = 0; j < activity_values.length; j++) {
          activity_values[j]["activity_values_id"] = ("0000" + j).slice(-4)
        }
      }
    },
    setScopeTwoActivityInputList() {
      this.scopeTwoActivityInputList = [
        {
          activity_name: "電力使用量",
          type: "00", //電力
          calc_source: this.STORE_MOCK_MASTER_CODE_LABEL_LIST[
            "electricUseCoefList"
          ]["00"],
          electric_company: "A0275 九州電力",
          coef: 0.000371,
          unit: "t-CO2/kWh",
          score: 0,
          calc_form: {
            input: [0, 0.000371],
            operator: ["02"],
          },
          activity_values: [
            {
              form_target: 0,
              type: "text",
              labelKey: "電気使用量",
              value: 0,
              suffix: "kWh",
            },
          ],
        },
        // {
        //   loc_id: "01",
        //   dept_id: "honbu",
        //   scope_id: "02",
        //   acrivity_id: "002",
        //   activity_name: "産業用蒸気の使用",
        //   calc_form: {
        //   input: [0, 0.06],
        //   operator: ["02"],
        // },
        //   input_values: [
        //     {
        //       input_seq: 1,
        //       input_desc: "産業用蒸気使用量",
        //       input_unit: "GJ",
        //     },
        //   ],
        //   score:"",
        // },
      ]

      // vid指定のために連番を付与
      for (let i = 0; i < this.scopeTwoActivityInputList.length; i++) {
        this.scopeTwoActivityInputList[i]["activity_id"] = ("0000" + i).slice(
          -4
        )
        this.scopeTwoActivityInputList[i]["scope_id"] = "02"
        let activity_values = this.scopeTwoActivityInputList[i][
          "activity_values"
        ]
        for (let j = 0; j < activity_values.length; j++) {
          activity_values[j]["activity_values_id"] = ("0000" + j).slice(-4)
        }
      }
    },
    setScopeThreeActivityInputList() {
      this.scopeThreeActivityInputList = [
        {
          category: "01",
          title: "カテゴリ1（購入した製品・サービス）",
          labelKey: "製品名",
          input_values: [
            {
              inputs: [
                {
                  activity_name: "キャベツ",
                  department_name: "野菜",
                  calc_source: this.STORE_MOCK_MASTER_CODE_LABEL_LIST
                    .calc_amount_base["cost_producer_base"],
                  calc_form: {
                    input: [0, 3.28],
                    operator: ["02"],
                  },
                  coef: 3.28,
                  unit: "t-CO2eq/百万円",
                  score: "0",
                  activity_values: [
                    {
                      form_target: 0,
                      type: "text",
                      labelKey: "購入金額",
                      suffix: "百万円",
                      value: "0",
                    },
                  ],
                },
                {
                  activity_name: "製品A",
                  department_name: "石油化学基礎製品",
                  calc_source: this.STORE_MOCK_MASTER_CODE_LABEL_LIST
                    .calc_amount_base["material_base"],
                  calc_form: {
                    input: [0, 0.863],
                    operator: ["02"],
                  },
                  coef: 0.863,
                  unit: "t-CO2eq/t",
                  score: 0,
                  activity_values: [
                    {
                      form_target: 0,
                      labelKey: "購入量",
                      type: "text",
                      suffix: "t",
                      value: 0,
                    },
                  ],
                },
                {
                  activity_name: "グループ会社排出量（推計）",
                  score: 0,
                  calc_source: FREE_INPUT_LABEL,
                  calc_form: {
                    input: [0],
                    operator: [],
                  },
                  remark:
                    "グループ会社のカテゴリ1排出量の和 × （他グループ会社売上高）／（親会社売上高）を計算して入力してください",
                  activity_values: [
                    {
                      form_target: 0,
                      type: "text",
                      labelKey: "排出量",
                      suffix: "t-CO2",
                      value: 0,
                    },
                  ],
                },
              ],
            },
          ],
        },
        {
          category: "02",
          title: "カテゴリ2（資本材）",
          labelKey: "資本財の種類",
          input_values: [
            {
              inputs: [
                {
                  activity_name: "商品A",
                  calc_source: this.STORE_MOCK_MASTER_CODE_LABEL_LIST
                    .capital_calc_method["00"],
                  calc_form: {
                    input: [2.73, 0],
                    operator: ["02"],
                  },
                  capital_name: "化学製品",
                  coef: 2.73,
                  unit: "t-CO2eq/百万円",
                  score: 0,
                  activity_values: [
                    {
                      form_target: 1,
                      labelKey: "金額",
                      type: "text",
                      suffix: "百万円",
                      value: 0,
                    },
                  ],
                },
              ],
            },
          ],
        },
        {
          category: "03",
          title: "カテゴリ3（SCOPE1、2に含まれない燃料およびエネルギー活動）",
          labelKey: "活動名",
          input_values: [
            {
              inputs: [
                {
                  activity_name: "活動A",
                  calc_source: "燃料消費量",
                  calc_form: {
                    input: [0.0000682, 0],
                    operator: ["02"],
                  },
                  fuel_type: "電力",
                  coef: 0.0000682,
                  unit: "tCO2e/kWh",
                  score: 0,
                  activity_values: [
                    {
                      form_target: 1,
                      labelKey: "燃料使用量",
                      type: "text",
                      suffix: "kWh",
                      value: 0,
                    },
                  ],
                },
                {
                  activity_name: "活動A",
                  calc_source: FREE_MULTIPLE_LABEL,
                  calc_form: {
                    input: [0, 0],
                    operator: ["02"],
                  },
                  fuel_type: "ガソリン",
                  score: 0,
                  remark: "排出原単位には0.XXXを設定",
                  activity_values: [
                    {
                      form_target: 0,
                      type: "text",
                      labelKey: "使用量",
                      suffix: "kl",
                      value: 0,
                    },
                    {
                      form_target: 1,
                      type: "text",
                      labelKey: "排出原単位",
                      suffix: "",
                      value: 0,
                    },
                  ],
                },
              ],
            },
          ],
        },
        // カテゴリー04
        {
          category: "04",
          title: "カテゴリ4（輸送・配送＜上流＞）",
          labelKey: "活動名",
          input_values: [
            {
              title: "輸送に伴う排出量",
              inputs: [
                {
                  activity_name: "出荷A",
                  calc_source:
                    this.STORE_MOCK_MASTER_CODE_LABEL_LIST.transport["00"] +
                    "/ガソリン",
                  calc_form: {
                    input: [0, 2.322],
                    operator: ["02"],
                  },
                  coef: 2.322,
                  unit: "tCO2/kl",
                  score: 0,
                  activity_values: [
                    {
                      form_target: 0,
                      type: "text",
                      labelKey: "燃料使用量",
                      suffix: "kl",
                      value: 0,
                    },
                  ],
                },
                {
                  activity_name: "出荷B",
                  calc_source:
                    this.STORE_MOCK_MASTER_CODE_LABEL_LIST.transport["01"] +
                    "/ガソリン",
                  calc_form: {
                    input: [0, 0, 34.6, 0.0183, 44, 12],
                    operator: ["03", "02", "02", "02", "03"],
                  },
                  coef: 2.322,
                  unit: "tCO2/kl",
                  score: 0,
                  activity_values: [
                    {
                      form_target: 0,
                      labelKey: "輸送距離",
                      type: "text",
                      suffix: "km",
                      value: 0,
                    },
                    {
                      form_target: 1,
                      labelKey: "燃費",
                      suffix: "l",
                      type: "text",
                      value: 0,
                    },
                  ],
                },
                {
                  activity_name: "出荷C",
                  calc_source:
                    this.STORE_MOCK_MASTER_CODE_LABEL_LIST.transport["02"] +
                    "/自動車/軽油",
                  calc_form: {
                    input: [0, 0, 0, 0.0183, 37.7, 0.0187, 44, 12],
                    operator: ["02", "02", "02", "02", "02", "02", "03"],
                  },
                  coef: 2.585,
                  unit: "tCO2/kl",
                  score: 0,
                  activity_values: [
                    {
                      form_target: 0,
                      type: "text",
                      labelKey: "貨物重量",
                      suffix: "t",
                      value: 0,
                    },
                    {
                      form_target: 1,
                      labelKey: "輸送距離",
                      suffix: "km",
                      type: "text",
                      value: 0,
                    },
                    {
                      type: "select",
                      form_target: 2,
                      itemList: this.STORE_CODE_STATE.transportTableList["02"],
                      labelKey: "積載率",
                      value: "01",
                    },
                  ],
                },
                {
                  activity_name: "出荷D",
                  calc_source:
                    this.STORE_MOCK_MASTER_CODE_LABEL_LIST.transport["02"] +
                    "/航空",
                  calc_form: {
                    input: [0, 0, 1.49],
                    operator: ["02", "02"],
                  },
                  coef: 1.49,
                  unit: "tCO2/トンキロ",
                  score: 0,
                  activity_values: [
                    {
                      form_target: 0,
                      type: "text",
                      labelKey: "貨物重量",
                      suffix: "t",
                      value: 0,
                    },
                    {
                      form_target: 1,
                      labelKey: "輸送距離",
                      suffix: "km",
                      type: "text",
                      value: 0,
                    },
                  ],
                },
                {
                  activity_name: "陸上輸送",
                  score: 0,
                  calc_source: FREE_INPUT_LABEL,
                  calc_form: {
                    input: [0],
                    operator: [],
                  },
                  remark:
                    "CFP PCR「〇〇類」の流通段階の輸送シナリオをもとに、500km、10トントラック、積載率62%と設定し計算",
                  activity_values: [
                    {
                      form_target: 0,
                      type: "text",
                      labelKey: "排出量",
                      suffix: "t-CO2",
                      value: 0,
                    },
                  ],
                },
              ],
            },
            {
              title: "拠点(荷役、保管、販売)のエネルギー使用に伴う排出量",
              inputs: [
                {
                  activity_name: "保管",
                  calc_source: "燃料消費量/都市ガス",
                  calc_form: {
                    input: [0, 2.234],
                    operator: ["02"],
                  },
                  coef: 2.234,
                  unit: "tCO2/千Nm3",
                  score: 0,
                  activity_values: [
                    {
                      form_target: 0,
                      type: "text",
                      suffix: "t",
                      labelKey: "燃料使用量",
                      value: 0,
                    },
                  ],
                },
              ],
            },
          ],
        },
        {
          category: "05",
          title: "カテゴリ5（事業から出る廃棄物）",
          labelKey: "活動名",
          input_values: [
            {
              inputs: [
                {
                  activity_name: "廃棄A",
                  calc_source: "リサイクル",
                  trash_type: "廃プラスチック類",
                  transport_stage: "含まない",
                  coef: 0.136,
                  unit: "tCO2/t",
                  score: 0,
                  calc_form: {
                    input: [0, 0.136],
                    operator: ["02"],
                  },
                  activity_values: [
                    {
                      type: "text",
                      form_target: 0,
                      labelKey: "重量",
                      suffix: "t",
                      value: 0,
                    },
                  ],
                },
                {
                  activity_name: "廃棄B",
                  calc_source: "把握していない",
                  trash_type: "廃油",
                  transport_stage: "含まない",
                  coef: 1.7815,
                  unit: "tCO2/t",
                  score: 0,
                  calc_form: {
                    input: [0, 1.7815],
                    operator: ["02"],
                  },
                  activity_values: [
                    {
                      type: "text",
                      form_target: 0,
                      labelKey: "重量",
                      suffix: "t",
                      value: 0,
                    },
                  ],
                },
                {
                  activity_name: "廃棄C",
                  score: 0,
                  calc_source: FREE_INPUT_LABEL,
                  calc_form: {
                    input: [0],
                    operator: [],
                  },
                  remark: "算定シナリオを入力",
                  activity_values: [
                    {
                      form_target: 0,
                      type: "text",
                      labelKey: "排出量",
                      suffix: "t-CO2",
                      value: 0,
                    },
                  ],
                },
              ],
            },
          ],
        },
        {
          category: "06",
          title: "カテゴリ6（出張）",
          labelKey: "活動名",
          input_values: [
            {
              inputs: [
                {
                  activity_name: "通勤",
                  calc_source:
                    this.STORE_MOCK_MASTER_CODE_LABEL_LIST.work_come_method[
                    "01"
                    ] + "/旅客鉄道",
                  calc_form: {
                    input: [0, 0.00000185],
                    operator: ["02"],
                  },
                  coef: 0.00000185,
                  unit: "tCO2/円",
                  score: 0,
                  activity_values: [
                    {
                      form_target: 0,
                      type: "text",
                      labelKey: "交通費支給額",
                      suffix: "円",
                      value: 0,
                    },
                  ],
                },
              ],
            },
          ],
        },
        {
          category: "07",
          title: "カテゴリ7（雇用者の通勤）",
          labelKey: "活動名",
          input_values: [
            {
              title: "通勤に伴う排出",
              inputs: [
                {
                  activity_name: "通勤",
                  calc_source:
                    this.STORE_MOCK_MASTER_CODE_LABEL_LIST.work_come_method[
                    "01"
                    ] + "/旅客鉄道",
                  calc_form: {
                    input: [0, 0.00000185],
                    operator: ["02"],
                  },
                  coef: 0.00000185,
                  unit: "tCO2/円",
                  score: 0,
                  activity_values: [
                    {
                      form_target: 0,
                      type: "text",
                      labelKey: "交通費支給額",
                      suffix: "円",
                      value: 0,
                    },
                  ],
                },
              ],
            },
          ],
        },
        {
          category: "08",
          title: "カテゴリ8（リース資産＜上流＞）",
          labelKey: "活動名",
          input_values: [
            {
              inputs: [
                {
                  activity_name: "大分本社",
                  calc_source:
                    this.STORE_MOCK_MASTER_CODE_LABEL_LIST.lease_assets["02"] +
                    "/事務所ビル",
                  calc_form: {
                    input: [0, 0.091, 12],
                    operator: ["02", "03"],
                  },
                  coef: 0.091,
                  unit: "tCO2/m2・年",
                  score: 0,
                  activity_values: [
                    {
                      form_target: 0,
                      type: "text",
                      labelKey: "床面積",
                      suffix: "m2",
                      value: 0,
                    },
                  ],
                },
                {
                  activity_name: "福岡事務所",
                  calc_source: FREE_MULTIPLE_LABEL,
                  remark: "排出原単位には0.XXXを設定",
                  calc_form: {
                    input: [0, 0],
                    operator: ["02"],
                  },
                  score: 0,
                  activity_values: [
                    {
                      form_target: 0,
                      type: "text",
                      labelKey: "活動量",
                      suffix: "",
                      value: 0,
                    },
                    {
                      form_target: 1,
                      type: "text",
                      labelKey: "排出原単位",
                      suffix: "",
                      value: 0,
                    },
                  ],
                },
              ],
            },
          ],
        },
        {
          category: "09",
          title: "カテゴリ9（輸送・配送＜下流＞）",
          labelKey: "活動名",
          input_values: [
            {
              title: "輸送に伴う排出量",
              inputs: [
                {
                  activity_name: "出荷A",
                  calc_source:
                    this.STORE_MOCK_MASTER_CODE_LABEL_LIST.transport["00"] +
                    "/ガソリン",
                  calc_form: {
                    input: [0, 2.322],
                    operator: ["02"],
                  },
                  coef: 2.322,
                  unit: "tCO2/kl",
                  score: 0,
                  activity_values: [
                    {
                      form_target: 0,
                      type: "text",
                      labelKey: "燃料使用量",
                      suffix: "kl",
                      value: 0,
                    },
                  ],
                },
                {
                  activity_name: "出荷B",
                  calc_source:
                    this.STORE_MOCK_MASTER_CODE_LABEL_LIST.transport["01"] +
                    "/ガソリン",
                  calc_form: {
                    input: [0, 0, 34.6, 0.0183, 44, 12],
                    operator: ["03", "02", "02", "02", "03"],
                  },
                  coef: 2.322,
                  unit: "tCO2/kl",
                  score: 0,
                  activity_values: [
                    {
                      form_target: 0,
                      labelKey: "輸送距離",
                      type: "text",
                      suffix: "km",
                      value: 0,
                    },
                    {
                      form_target: 1,
                      labelKey: "燃費",
                      suffix: "l",
                      type: "text",
                      value: 0,
                    },
                  ],
                },
                {
                  activity_name: "出荷C",
                  calc_source:
                    this.STORE_MOCK_MASTER_CODE_LABEL_LIST.transport["02"] +
                    "/自動車/軽油",
                  calc_form: {
                    input: [0, 0, 0, 0.0183, 37.7, 0.0187, 44, 12],
                    operator: ["02", "02", "02", "02", "02", "02", "03"],
                  },
                  coef: 2.585,
                  unit: "tCO2/kl",
                  score: 0,
                  activity_values: [
                    {
                      form_target: 0,
                      type: "text",
                      labelKey: "貨物重量",
                      suffix: "t",
                      value: 0,
                    },
                    {
                      form_target: 1,
                      labelKey: "輸送距離",
                      suffix: "km",
                      type: "text",
                      value: 0,
                    },
                    {
                      type: "select",
                      form_target: 2,
                      itemList: this.STORE_CODE_STATE.transportTableList["02"],
                      labelKey: "積載率",
                      value: "01",
                    },
                  ],
                },
                {
                  activity_name: "出荷D",
                  calc_source:
                    this.STORE_MOCK_MASTER_CODE_LABEL_LIST.transport["02"] +
                    "/航空",
                  calc_form: {
                    input: [0, 0, 1.49],
                    operator: ["02", "02"],
                  },
                  coef: 1.49,
                  unit: "tCO2/トンキロ",
                  score: 0,
                  activity_values: [
                    {
                      form_target: 0,
                      type: "text",
                      labelKey: "貨物重量",
                      suffix: "t",
                      value: 0,
                    },
                    {
                      form_target: 1,
                      labelKey: "輸送距離",
                      suffix: "km",
                      type: "text",
                      value: 0,
                    },
                  ],
                },
                {
                  activity_name: "陸上輸送",
                  score: 0,
                  calc_source: FREE_INPUT_LABEL,
                  calc_form: {
                    input: [0],
                    operator: [],
                  },
                  remark:
                    "CFP PCR「〇〇類」の流通段階の輸送シナリオをもとに、500km、10トントラック、積載率62%と設定し計算",
                  activity_values: [
                    {
                      form_target: 0,
                      type: "text",
                      labelKey: "排出量",
                      suffix: "t-CO2",
                      value: 0,
                    },
                  ],
                },
              ],
            },
            {
              title: "拠点(荷役、保管、販売)のエネルギー使用に伴う排出量",
              inputs: [
                {
                  activity_name: "保管",
                  calc_source: "燃料消費量/都市ガス",
                  calc_form: {
                    input: [0, 0.0136],
                    operator: ["02"],
                  },
                  coef: 2.234,
                  unit: "tCO2/千Nm3",
                  score: 0,
                  activity_values: [
                    {
                      form_target: 0,
                      type: "text",
                      suffix: "t",
                      labelKey: "燃料使用量",
                      value: 0,
                    },
                  ],
                },
              ],
            },
          ],
        },
        {
          category: "10",
          title: "カテゴリ10（販売した製品の加工）",
          labelKey: "活動名",
          input_values: [
            {
              inputs: [
                {
                  activity_name: "加工段階A",
                  score: 0,
                  calc_source: FREE_MULTIPLE_LABEL,
                  remark: "当月の販売量と排出原単位に0.XXXを入力",
                  calc_form: {
                    input: [0, 0],
                    operator: ["02"],
                  },
                  activity_values: [
                    {
                      form_target: 0,
                      type: "text",
                      labelKey: "販売量",
                      suffix: "t",
                      value: 0,
                    },
                    {
                      form_target: 1,
                      type: "text",
                      labelKey: "排出原単位",
                      suffix: "",
                      value: 0,
                    },
                  ],
                },
              ],
            },
          ],
        },
        {
          category: "11",
          title: "カテゴリ11（販売した製品の使用）",
          labelKey: "製品名",
          input_values: [
            {
              inputs: [
                {
                  activity_name: "製品使用段階A",
                  score: 0,
                  calc_source: FREE_INPUT_LABEL,
                  calc_form: {
                    input: [0],
                    operator: [],
                  },
                  remark:
                    "１個あたりの平均的な年間電力消費量(kWh)×平均的な使用期間×月内に販売した数量×0.00579を計算して入力",
                  activity_values: [
                    {
                      form_target: 0,
                      type: "text",
                      labelKey: "排出量",
                      suffix: "t-CO2",
                      value: 0,
                    },
                  ],
                },
                {
                  activity_name: "製品使用段階B",
                  score: 0,
                  calc_source: FREE_MULTIPLE_LABEL,
                  remark: "当月の販売量と排出原単位に0.XXXを入力",
                  calc_form: {
                    input: [0, 0],
                    operator: ["02"],
                  },
                  activity_values: [
                    {
                      form_target: 0,
                      type: "text",
                      labelKey: "販売量",
                      suffix: "t",
                      value: 0,
                    },
                    {
                      form_target: 1,
                      type: "text",
                      labelKey: "排出原単位",
                      suffix: "",
                      value: 0,
                    },
                  ],
                },
              ],
            },
          ],
        },
        {
          category: "12",
          title: "カテゴリ12（販売した製品の廃棄）",
          labelKey: "活動名",
          input_values: [
            {
              inputs: [
                {
                  activity_name: "廃棄A",
                  calc_source: "リサイクル",
                  trash_type: "廃プラスチック類",
                  transport_stage: "含まない",
                  coef: 0.136,
                  unit: "tCO2/t",
                  score: 0,
                  calc_form: {
                    input: [0, 0.136],
                    operator: ["02"],
                  },
                  activity_values: [
                    {
                      type: "text",
                      form_target: 0,
                      labelKey: "重量",
                      suffix: "t",
                      value: 0,
                    },
                  ],
                },
                {
                  activity_name: "廃棄B",
                  calc_source: "把握していない",
                  trash_type: "廃油",
                  transport_stage: "含まない",
                  coef: 1.7815,
                  unit: "tCO2/t",
                  score: 0,
                  calc_form: {
                    input: [0, 1.7815],
                    operator: ["02"],
                  },
                  activity_values: [
                    {
                      type: "text",
                      form_target: 0,
                      labelKey: "重量",
                      suffix: "t",
                      value: 0,
                    },
                  ],
                },
                {
                  activity_name: "廃棄C",
                  score: 0,
                  calc_source: FREE_INPUT_LABEL,
                  calc_form: {
                    input: [0],
                    operator: [],
                  },
                  remark: "算定シナリオを入力",
                  activity_values: [
                    {
                      form_target: 0,
                      type: "text",
                      labelKey: "排出量",
                      suffix: "t-CO2",
                      value: 0,
                    },
                  ],
                },
              ],
            },
          ],
        },
        {
          category: "13",
          title: "カテゴリ13（リース資産＜下流＞）",
          labelKey: "活動名",
          input_values: [
            {
              inputs: [
                {
                  activity_name: "事務所",
                  calc_source:
                    this.STORE_MOCK_MASTER_CODE_LABEL_LIST.lease_assets["02"] +
                    "/事務所ビル",
                  calc_form: {
                    input: [0, 0.091, 12],
                    operator: ["02", "03"],
                  },
                  coef: 0.091,
                  unit: "tCO2/m2・年",
                  score: 0,
                  activity_values: [
                    {
                      form_target: 0,
                      type: "text",
                      labelKey: "床面積",
                      suffix: "m2",
                      value: 0,
                    },
                  ],
                },
                {
                  activity_name: "福岡事務所",
                  calc_source: FREE_MULTIPLE_LABEL,
                  remark: "排出原単位には0.XXXを設定",
                  calc_form: {
                    input: [0, 0],
                    operator: ["02"],
                  },
                  score: 0,
                  activity_values: [
                    {
                      form_target: 0,
                      type: "text",
                      labelKey: "活動量",
                      suffix: "",
                      value: 0,
                    },
                    {
                      form_target: 1,
                      type: "text",
                      labelKey: "排出原単位",
                      suffix: "",
                      value: 0,
                    },
                  ],
                },
              ],
            },
          ],
        },
        {
          category: "14",
          title: "カテゴリ14（フランチャイズ）",
          labelKey: "加盟店名",
          input_values: [
            {
              inputs: [
                {
                  activity_name: "加盟店総数",
                  calc_source: "加盟店排出量",
                  remark: "店舗あたり排出量◯◯◯◯",
                  calc_form: {
                    input: [0, 0],
                    operator: ["02"],
                  },
                  score: 0,
                  activity_values: [
                    {
                      form_target: 0,
                      type: "text",
                      labelKey: "加盟店数",
                      suffix: "",
                      value: 0,
                    },
                    {
                      form_target: 1,
                      type: "text",
                      labelKey: "店舗あたりのSCOPE1・2排出量",
                      suffix: "(t-CO2e/店)",
                      value: 0,
                    },
                  ],
                },
              ],
            },
          ],
        },
        {
          category: "15",
          title: "カテゴリ15（投資）",
          labelKey: "投資先名",
          input_values: [
            {
              inputs: [
                {
                  activity_name: "投資先A",
                  calc_source: "投資先排出量",
                  calc_form: {
                    input: [0, 0],
                    operator: ["02"],
                  },
                  remark: "株式保有割合◯◯%を小数で入力してください",
                  score: 0,
                  activity_values: [
                    {
                      form_target: 0,
                      type: "text",
                      labelKey: "投資先の排出量",
                      suffix: "t-CO2e",
                      value: 0,
                    },
                    {
                      form_target: 1,
                      type: "text",
                      labelKey: "株式保有割合",
                      value: 0,
                    },
                  ],
                },
              ],
            },
          ],
        },
        {
          category: "other",
          title: "その他",
          labelKey: "活動名",
          input_values: [
            {
              inputs: [
                {
                  activity_name: "その他活動",
                  calc_source: FREE_INPUT_LABEL,
                  calc_form: {
                    input: [0],
                    operator: [],
                  },
                  remark: "算定シナリオを入力",
                  score: 0,
                  activity_values: [
                    {
                      form_target: 0,
                      type: "text",
                      labelKey: "排出量",
                      suffix: "t-CO2",
                      value: 0,
                    },
                  ],
                },
              ],
            },
          ],
        },
      ]

      for (let i = 0; i < this.scopeThreeActivityInputList.length; i++) {
        let inputValues = this.scopeThreeActivityInputList[i]["input_values"]
        for (let j = 0; j < inputValues.length; j++) {
          let next = 0
          let inputs = inputValues[j]["inputs"]
          for (let k = 0; k < inputs.length; k++) {
            inputs[k]["activity_id"] = ("0000" + k).slice(-4)
            let activityValues = inputs[k]["activity_values"]
            for (let m = 0; m < activityValues.length; m++) {
              activityValues[m]["activity_values_id"] = (
                "0000" +
                (m + next)
              ).slice(-4)
            }
            next += activityValues.length
          }
        }
      }
    },

    //** 算出方法別追加入力欄 **/
    scopeTwoAddInputItem: {
      "00": {
        scope_id: "02",
        acrivity_id: "",
        activity_name: "電力使用量",
        type: "00", //電力
        score: 0,
        calc_form: {
          input: [0, 0],
          operator: ["02"],
        },
        activity_values: [
          {
            activity_values_id: "00001",
            form_target: 0,
            type: "text",
            labelKey: "電気使用量",
            value: 0,
            suffix: "kWh",
          },
        ],
      },
    },

    setScopeThreeAddInputItem() {
      this.scopeThreeAddInputItem = {
        "01": {
          cost_producer_base: {
            calc_source: this.STORE_MOCK_MASTER_CODE_LABEL_LIST
              .calc_amount_base["cost_producer_base"],
            calc_form: {
              input: [0, 0],
              operator: ["02"],
            },
            activity_name: "",
            score: 0,
            activity_values: [
              {
                activity_values_id: "00000",
                form_target: 0,
                type: "text",
                labelKey: "購入金額",
                suffix: "百万円",
                value: "",
              },
            ],
          },
          cost_purchaser_base: {
            calc_source: this.STORE_MOCK_MASTER_CODE_LABEL_LIST
              .calc_amount_base["cost_purchaser_base"],
            calc_form: {
              input: [0, 0],
              operator: ["02"],
            },
            activity_name: "",
            score: 0,
            activity_values: [
              {
                activity_values_id: "00000",
                form_target: 0,
                type: "text",
                labelKey: "購入金額",
                suffix: "百万円",
                value: "",
              },
            ],
          },
          material_base: {
            calc_source: this.STORE_MOCK_MASTER_CODE_LABEL_LIST
              .calc_amount_base["material_base"],
            calc_form: {
              input: [0, 0],
              operator: ["02"],
            },
            activity_name: "",
            score: 0,
            activity_values: [
              {
                activity_values_id: "00000",
                form_target: 0,
                type: "text",
                labelKey: "購入量",
                suffix: "t",
                value: "",
              },
            ],
          },
        },
        "04": {
          "01": {
            activity_name: "",
            calc_source: this.STORE_MOCK_MASTER_CODE_LABEL_LIST.transport["01"],
            calc_form: {
              input: [0, 0, 0, 0, 44, 12],
              operator: ["03", "02", "02", "02", "03"],
            },
            score: 0,
            activity_values: [
              {
                form_target: 0,
                labelKey: "輸送距離",
                type: "text",
                suffix: "km",
                value: 0,
              },
              {
                form_target: 1,
                labelKey: "燃費",
                suffix: "l",
                type: "text",
                value: 0,
              },
            ],
          },
        },
      }
    },

    //** 排出源単位DB **//

    // 電力
    ELECTRIC_TABLE: {
      name: ["value", "label", "coef"],
      values: [
        ["A0275", "九州電力", 0.000344],
        ["A0009", "エネット", 0.000408],
        ["A0074", "テプコカスタマーサービス(株)", 0.000514],
        ["A0050", "ENEOS", 0.000462],
        ["A0186", "SBパワー(株)", 0.0005],
        ["A0028", "出光グリーンパワー", 0.000288],
        ["A0006", "エバーグリーン・マーケティング", 0.000308],
        ["A0153", "エナリス・パワー・マーケティング", 0.000531],
        ["A0001", "F-Power", 0.000448],
      ],
    },
    ELECTRIC_TABLE_MENU_TABLE: {
      name: ["value", "elecom_code", "label", "coef"],
      values: [
        ["0001", "A0275", "メニューA", 0],
        ["0002", "A0275", "メニューB(残差)", 0.000371],
        ["0003", "A0275", "(参考値)事業者全体", 0.00037],
        ["0004", "A0009", "メニューA", 0],
        ["0005", "A0009", "メニューB", 0],
        ["0006", "A0009", "メニューC", 0.00022],
        ["0007", "A0009", "メニューD", 0.00033],
        ["0008", "A0009", "メニューE", 0.000349],
        ["0009", "A0009", "メニューF", 0.0004],
        ["0010", "A0009", "メニューG", 0.000405],
        ["0011", "A0009", "メニューH(残差)", 0.000409],
        ["0012", "A0009", "(参考値)事業者全体", 0.000408],
        ["0013", "A0074", "(残差)", 0.000533],
        ["0014", "A0074", "(参考値)事業者全体", 0.000532],
        ["0015", "A0050", "メニューA", 0],
        ["0016", "A0050", "メニューB", 0],
        ["0017", "A0050", "メニューC(残差)", 0.000472],
        ["0018", "A0050", "(参考値)事業者全体", 0.000472],
        ["0019", "A0186", "メニューA", 0],
        ["0020", "A0186", "メニューB", 0.000373],
        ["0021", "A0186", "メニューC", 0.00039],
        ["0022", "A0186", "(参考値)事業者全体", 0.00039],
        ["0023", "A0028", "メニューA", 0],
        ["0024", "A0028", "メニューB", 0],
        ["0025", "A0028", "メニューC", 0.0002],
        ["0026", "A0028", "メニューD(残差)", 0.000462],
        ["0027", "A0028", "(参考値)事業者全体", 0.000391],
        ["0028", "A0006", "メニューA", 0],
        ["0029", "A0006", "メニューB", 0],
        ["0030", "A0006", "メニューC(残差)", 0.00044],
        ["0031", "A0006", "(参考値)事業者全体", 0.000432],
        ["0032", "A0153", "メニューA", 0],
        ["0033", "A0153", "メニューB", 0],
        ["0034", "A0153", "メニューC", 0.00039],
        ["0035", "A0153", "メニューD", 0.00039],
        ["0036", "A0153", "メニューE", 0.00039],
        ["0037", "A0153", "メニューF", 0.000343],
        ["0038", "A0153", "メニューG", 0.000248],
        ["0039", "A0153", "メニューH", 0],
        ["0040", "A0153", "メニューI(残差)", 0.000536],
        ["0041", "A0153", "(参考値)事業者全体", 0.000531],
        ["0042", "A0001", "メニューA", 0],
        ["0043", "A0001", "メニューB", 0],
        ["0044", "A0001", "メニューC(残差)", 0.000514],
        ["0045", "A0001", "(参考値)事業者全体", 0.000513],
      ],
    },
    // 燃費法排出係数DB
    FUEL_ECONOMY_TABLE: {
      name: ["value", "label", "cal_value", "coef"],
      values: [
        ["0", "ガソリン", 34.6, 0.0183],
        ["1", "軽油", 37.7, 0.0187],
      ],
    },
    // 燃費法燃費特定テーブル
    FUEL_ECONOMY_SPECIFIC_TABLE: {
      name: ["fuel_type", "maximum_value", "maximum_label", "usage", "coef"],
      values: [
        ["0", "00", "軽貨物車", "0", 9.33],
        ["0", "00", "軽貨物車", "1", 10.3],
        ["0", "01", "～1,999kg", "0", 6.57],
        ["0", "01", "～1,999kg", "1", 7.15],
        ["0", "02", "2,000kg以上", "0", 4.96],
        ["0", "02", "2,000kg以上", "1", 5.25],
        ["1", "03", "～999kg", "0", 9.32],
        ["1", "03", "～999kg", "1", 11.9],
        ["1", "04", "1,000～1,999kg", "0", 6.19],
        ["1", "04", "1,000～1,999kg", "1", 7.34],
        ["1", "05", "2,000～3,999kg", "0", 4.58],
        ["1", "05", "2,000～3,999kg", "1", 4.94],
        ["1", "06", "4,000～5,999kg", "0", 3.79],
        ["1", "06", "4,000～5,999kg", "1", 3.96],
        ["1", "07", "6,000～7,999kg", "0", 3.38],
        ["1", "07", "6,000～7,999kg", "1", 3.53],
        ["1", "08", "8,000～9,999kg", "0", 3.09],
        ["1", "08", "8,000～9,999kg", "1", 3.23],
        ["1", "09", "10,000～11,999kg", "0", 2.89],
        ["1", "09", "10,000～11,999kg", "1", 3.02],
        ["1", "10", "12,000～16,999kg", "0", 2.62],
        ["1", "10", "12,000～16,999kg", "1", 2.74],
      ],
    },
    //交通費支給額あたりDB
    TRAFFIC_FEE_TABLE: {
      name: ["value", "label", "coef", "unit"],
      values: [
        ["01", "旅客航空機（国内線）", 0.00525, "円"],
        ["02", "旅客航空機（国際線）", 0.0071, "円"],
        ["03", "旅客鉄道", 0.00185, "円"],
        ["04", "旅客船舶", 0.05019, "円"],
        ["05", "バス(営業用乗合)", 0.00471, "円"],
        ["06", "自動車（タクシー・ハイヤー）", 0.00331, "円"],
      ],
    },

    //トンキロ法 積載率別燃料使用量（例）
    TK_ACCUM_RATE_TABLE: {
      name: ["value", "label", "coef"],
      values: [
        ["01", "〜10%", 0.886],
        ["02", "〜20%", 0.466],
        ["03", "〜40%", 0.245],
        ["04", "〜60%", 0.168],
        ["05", "〜80%", 0.129],
        ["06", "〜100%", 0.105],
      ],
    },
    // 廃棄物種類別DB
    TRASH_TYPE_TABLE: {
      name: ["value", "label", "transport_coef", "coef", "unit"],
      values: [
        ["00001", "燃えがら", 0.0453, 0.0122, "t"],
        ["00002", "汚泥", 0.2161, 0.1722, "t"],
        ["00003", "廃油", 1.8153, 1.7815, "t"],
        ["00004", "廃酸", 0.0627, 0.0254, "t"],
        ["00005", "廃アルカリ", 0.0671, 0.0273, "t"],
        ["00006", "廃プラスチック類", 0.8214, 0.7927, "t"],
        ["00007", "紙くず", 0.1317, 0.1082, "t"],
        ["00008", "木くず", 0.1127, 0.0972, "t"],
        ["00009", "繊維くず", 0.3132, 0.287, "t"],
        ["00010", "動植物性残渣注1", 0.1006, 0.0728, "t"],
        ["00011", "動物系固形不要物", 0.1583, 0.1386, "t"],
        ["00012", "ゴムくず", 0.0386, 0.0149, "t"],
        ["00013", "金属くず", 0.0122, 0.0015, "t"],
        ["00014", "ガラス陶磁器くず", 0.0321, 0.0117, "t"],
        ["00015", "鉱さい", 0.0219, 0.0037, "t"],
        ["00016", "がれき類", 0.0113, 0.0027, "t"],
        ["00017", "動物のふん尿", 0.0795, 0.0776, "t"],
        ["00018", "動物の死体", 0.1422, 0.1166, "t"],
        ["00019", "ばいじん", 0.0489, 0.0103, "t"],
        ["00020", "液晶・プラズマテレビ", 0.166, 0.1459, "t"],
        ["00021", "冷蔵庫・冷凍庫", 0.1179, 0.0978, "t"],
        ["00022", "洗濯機・衣類乾燥機", 0.0695, 0.0494, "t"],
        ["00023", "エアコン", 0.0736, 0.0535, "t"],
        ["00024", "パソコン・モニタ", 0.0644, 0.0476, "t"],
      ],
    },

    // 廃棄物種類・処理方法別（リサイクル）
    TRASH_RECYCLE_TABLE: {
      name: [
        "value",
        "label",
        "transport_coef",
        "transport_unit",
        "coef",
        "unit",
      ],
      values: [
        ["00001", "燃えがら", 0.026, "(tCO2/t)", 0, "(tCO2/t)"],
        ["00002", "汚泥", 0.012, "(tCO2/t)", 0, "(tCO2/t)"],
        ["00003", "廃油", 0.011, "(tCO2/t)", 0, "(tCO2/t)"],
        ["00004", "廃酸", 0.02048, "(tCO2/t)", 0.00638, "(tCO2/t)"],
        ["00005", "廃アルカリ", 0.02084, "(tCO2/t)", 0.00604, "(tCO2/t)"],
        ["00006", "廃プラスチック類", 0.149, "(tCO2/t)", 0.136, "(tCO2/t)"],
        ["00007", "紙くず", 0.021, "(tCO2/t)", 0.011, "(tCO2/t)"],
        ["00008", "木くず", 0.015, "(tCO2/t)", 0.008, "(tCO2/t)"],
        ["00009", "繊維くず", 0.013, "(tCO2/t)", 0, "(tCO2/t)"],
        [
          "00010",
          "動植物性残渣（飼料化）",
          0.037,
          "(tCO2/t)",
          0.0284,
          "(tCO2/t)",
        ],
        [
          "00011",
          "動植物性残渣（肥料化）",
          0.1426,
          "(tCO2/t)",
          0.134,
          "(tCO2/t)",
        ],
        ["00012", "動物系固形不要物", 0.19, "(tCO2/t)", 0.182, "(tCO2/t)"],
        ["00013", "ゴムくず", 0.007, "(tCO2/t)", 0, "(tCO2/t)"],
        ["00014", "金属くず", 0.009, "(tCO2/t)", 0, "(tCO2/t)"],
        ["00015", "ガラス陶磁器くず", 0.00995, "(tCO2/t)", 0.00107, "(tCO2/t)"],
        ["00016", "鉱さい", 0.015, "(tCO2/t)", 0, "(tCO2/t)"],
        ["00017", "がれき類", 0.00786, "(tCO2/t)", 0.00107, "(tCO2/t)"],
        ["00018", "動物のふん尿", 0.0795, "(tCO2/t)", 0.0795, "(tCO2/t)"],
        ["00019", "動物の死体", 0.19, "(tCO2/t)", 0.182, "(tCO2/t)"],
        ["00020", "ばいじん", 0.035, "(tCO2/t)", 0, "(tCO2/t)"],
        [
          "00021",
          "液晶・プラズマテレビ（想定重量　19.8 kg/台）",
          0.166,
          "(tCO2/t)",
          0.146,
          "(tCO2/t)",
        ],
        [
          "00022",
          "冷蔵庫・冷凍庫（想定重量　61.8 kg/台）",
          0.1179,
          "(tCO2/t)",
          0.0978,
          "(tCO2/t)",
        ],
        [
          "00023",
          "洗濯機・衣類乾燥機（想定重量　37.6 kg/台）",
          0.0695,
          "(tCO2/t)",
          0.0494,
          "(tCO2/t)",
        ],
        [
          "00024",
          "エアコン（想定重量　41.1 kg/台）",
          0.0736,
          "(tCO2/t)",
          0.0535,
          "(tCO2/t)",
        ],
        [
          "00025",
          "パソコン・ディスプレイ（想定重量 8.09 kg/台）",
          0.0644,
          "(tCO2/t)",
          0.0476,
          "(tCO2/t)",
        ],
        [
          "00026",
          "液晶・プラズマテレビ（想定重量　19.8 kg/台）",
          0.003287,
          "(tCO2/台)",
          0.00289,
          "(tCO2/台)",
        ],
        [
          "00027",
          "冷蔵庫・冷凍庫（想定重量　61.8 kg/台）",
          0.00729,
          "(tCO2/台)",
          0.00604,
          "(tCO2/台)",
        ],
        [
          "00028",
          "洗濯機・衣類乾燥機（想定重量　37.6 kg/台）",
          0.002615,
          "(tCO2/台)",
          0.00186,
          "(tCO2/台)",
        ],
        [
          "00029",
          "エアコン（想定重量　41.1 kg/台）",
          0.003026,
          "(tCO2/台)",
          0.0022,
          "(tCO2/台)",
        ],
        [
          "00030",
          "パソコン・ディスプレイ（想定重量 8.09 kg/台）",
          0.000521,
          "(tCO2/台)",
          0.000385,
          "(tCO2/台)",
        ],
      ],
    },

    // 燃料調達時の排出源単位
    FUEL_SUPPLY_TABLE: {
      name: ["value", "fuel_name", "coef", "unit"],
      values: [
        ["001", "電力", "0.0682", "kgCO2e/kWh"],
        ["002", "蒸気", "0.0328", "kgCO2e/MJ"],
      ],
    },

    //燃料種別の発熱量
    FUEL_TYPE_CALORIFIC_TABLE: {
      name: ["value", "label", "unit", "coef"],
      values: [
        ["000001", "原料炭", "GJ/t", 29.0],
        ["000002", "一般炭", "GJ/t", 25.7],
        ["000003", "無煙炭", "GJ/t", 26.9],
        ["000004", "コークス", "GJ/t", 29.4],
        ["000005", "石油コークス", "GJ/t", 29.9],
        ["000006", "練炭又は豆炭", "GJ/t", 23.9],
        ["000007", "木材", "GJ/t", 14.4],
        ["000008", "木炭", "GJ/t", 30.5],
        ["000009", "その他の固体燃料", "GJ/t", 33.1],
        ["000010", "コールタール", "GJ/t", 37.3],
        ["000011", "石油アスファルト", "GJ/t", 40.9],
        ["000012", "コンデンセート（NGL）", "GJ/kl", 35.3],
        [
          "000013",
          "液体燃料",
          "原油（コンデンセート（NGL）を除く。）",
          "GJ/kl",
          38.2,
        ],
        ["000014", "ガソリン", "GJ/kl", 34.6],
        ["000015", "ナフサ", "GJ/kl", 33.6],
        ["000016", "ジェット燃料油", "GJ/kl", 36.7],
        ["000017", "灯油", "GJ/kl", 36.7],
        ["000018", "軽油", "GJ/kl", 37.7],
        ["000019", "A重油", "GJ/kl", 39.1],
        ["000020", "B・C重油", "GJ/kl", 41.9],
        ["000021", "潤滑油", "GJ/kl", 40.2],
        ["000022", "その他の液体燃料", "GJ/kl", 37.9],
        ["000023", "液化石油ガス（LPG）", "GJ/t", 50.8],
        ["000024", "石油系炭化水素ガス", "GJ/1,000Nm3", 44.9],
        ["000025", "液化天然ガス（LNG）", "GJ/t", 54.6],
        [
          "000026",
          "気体燃料",
          "天然ガス（液化天然ガス（LNG）を除く。）",
          "GJ/1,000Nm3",
          43.5,
        ],
        ["000027", "コークス炉ガス", "GJ/1,000Nm3", 21.1],
        ["000028", "高炉ガス", "GJ/1,000Nm3", 3.41],
        ["000029", "転炉ガス", "GJ/1,000Nm3", 8.41],
        ["000030", "都市ガス", "GJ/1,000Nm3", 44.8],
        ["000031", "その他の気体燃料", "GJ/1,000Nm3", 28.5],
        ["000032", "パルプ廃液", "", "GJ/t", 13.9],
      ],
    },

    FUEL_TYPE_USAGE_COEF_TABLE: {
      name: ["value", "fuel_name", "unit", "coef"],
      values: [
        ["000001", "原料炭", "tC/GJ", 0.0245],
        ["000002", "一般炭", "tC/GJ", 0.0247],
        ["000003", "無煙炭", "tC/GJ", 0.0255],
        ["000004", "コークス", "tC/GJ", 0.0294],
        ["000005", "石油コークス", "tC/GJ", 0.0254],
        ["000010", "コールタール", "tC/GJ", 0.0209],
        ["000011", "石油アスファルト", "tC/GJ", 0.0208],
        ["000012", "コンデンセート（NGL）", "tC/GJ", 0.0184],
        [
          "000013",
          "燃料の使用",
          "原油（コンデンセート（NGL）を除く。）",
          "tC/GJ",
          0.0187,
        ],
        ["000014", "ガソリン", "tC/GJ", 0.0183],
        ["000015", "ナフサ", "tC/GJ", 0.0182],
        ["000016", "ジェット燃料油", "tC/GJ", 0.0183],
        ["000017", "灯油", "tC/GJ", 0.0185],
        ["000018", "軽油", "tC/GJ", 0.0187],
        ["000019", "A重油", "tC/GJ", 0.0189],
        ["000020", "B・C重油", "tC/GJ", 0.0195],
        ["000023", "液化石油ガス（LPG）", "tC/GJ", 0.0161],
        ["000024", "石油系炭化水素ガス", "tC/GJ", 0.0142],
        ["000025", "液化天然ガス（LNG）", "tC/GJ", 0.0135],
        [
          "000026",
          "燃料の使用",
          "天然ガス（液化天然ガス（LNG）を除く。）",
          "tC/GJ",
          0.0139,
        ],
        ["000027", "コークス炉ガス", "tC/GJ", 0.011],
        ["000028", "高炉ガス", "tC/GJ", 0.0263],
        ["000029", "転炉ガス", "tC/GJ", 0.0384],
        ["000030", "都市ガス", "tC/GJ", 0.0136],
      ],
    },

    //資本財価格あたりDB
    CAPITAL_MONEY_TABLE: {
      name: [
        "value", //列コード
        "department_name", //部門名
        "coef", //排出係数
      ],
      values: [
        ["00-0000", "合計", 3.5],
        ["01-0000", "農林水産業", 4.07],
        ["01-0010", "耕種農業", 3.56],
        ["01-0020", "畜産", 5.98],
        ["01-0030", "農業サービス", 3.33],
        ["01-0040", "林業", 4.02],
        ["01-0050", "漁業", 4.1],
        ["02-0000", "鉱業", 3.71],
        ["02-0060", "金属鉱物", 3.82],
        ["02-0070", "非金属鉱物", 3.69],
        ["02-0080", "石炭・原油・天然ガス", 3.75],
        ["03-0000", "食料品", 3.15],
        ["03-0090", "食料品", 3.14],
        ["03-0100", "飲料", 3.12],
        ["03-0110", "飼料・有機質肥料（除別掲）", 3.42],
        ["03-0120", "たばこ", 3.25],
        ["04-0000", "繊維製品", 3.07],
        ["04-0130", "繊維工業製品", 3.14],
        ["04-0140", "衣服・その他の繊維既製品", 2.92],
        ["05-0000", "パルプ・紙・木製品", 3.09],
        ["05-0150", "製材・木製品", 3.53],
        ["05-0160", "家具・装備品", 3.4],
        ["05-0170", "パルプ・紙・板紙・加工紙", 2.74],
        ["05-0180", "紙加工品", 3.44],
        ["06-0000", "化学製品", 2.73],
        ["06-0200", "化学肥料", 2.67],
        ["06-0210", "無機化学工業製品", 2.76],
        ["06-0220", "石油化学基礎製品", 2.71],
        ["06-0230", "有機化学工業製品（除石油化学基礎製品）", 2.85],
        ["06-0240", "合成樹脂", 2.71],
        ["06-0250", "化学繊維", 2.6],
        ["06-0260", "医薬品", 2.83],
        ["06-0270", "化学最終製品（除医薬品）", 2.51],
        ["07-0000", "石油・石炭製品", 2.91],
        ["07-0280", "石油製品", 2.85],
        ["07-0290", "石炭製品", 3.41],
        ["08-0000", "窯業・土石製品", 3.24],
        ["08-0330", "ガラス・ガラス製品", 3.46],
        ["08-0340", "セメント・セメント製品", 2.92],
        ["08-0350", "陶磁器", 3.37],
        ["08-0360", "その他の窯業・土石製品", 3.3],
        ["09-0000", "鉄鋼", 3.21],
        ["09-0370", "銑鉄・粗鋼", 3.58],
        ["09-0380", "鋼材", 3.05],
        ["09-0390", "鋳鍛造品", 3.67],
        ["09-0400", "その他の鉄鋼製品", 3.58],
        ["10-0000", "非鉄金属", 3.5],
        ["10-0410", "非鉄金属製錬・精製", 3.47],
        ["10-0420", "非鉄金属加工製品", 3.51],
        ["11-0000", "金属製品", 3.25],
        ["11-0430", "建設・建築用金属製品", 3.28],
        ["11-0440", "その他の金属製品", 3.23],
        ["12-0000", "一般機械", 3.35],
        ["12-0450", "一般産業機械", 3.44],
        ["12-0460", "特殊産業機械", 3.33],
        ["12-0470", "その他の一般機械器具及び部品", 3.2],
        ["12-0480", "事務用・サービス用機器", 3.31],
        ["13-0000", "電気機械", 2.87],
        ["13-0490", "産業用電気機器", 3.01],
        ["13-0500", "電子応用装置・電子計測器", 2.3],
        ["13-0510", "その他の電気機器", 2.74],
        ["13-0520", "民生用電気機器", 3.09],
        ["14-0000", "情報・通信機器", 2.56],
        ["14-0530", "通信機械・同関連機器", 2.72],
        ["14-0540", "電子計算機・同付属装置", 2.27],
        ["15-0000", "電子部品", 2.72],
        ["15-0550", "半導体素子・集積回路", 2.81],
        ["15-0560", "その他の電子部品", 2.46],
        ["16-0000", "輸送機械", 3.43],
        ["16-0570", "乗用車", 3.28],
        ["16-0580", "その他の自動車", 3.67],
        ["16-0590", "自動車部品・同付属品", 3.44],
        ["16-0600", "船舶・同修理", 3.45],
        ["16-0610", "その他の輸送機械・同修理", 3.49],
        ["16-0611", "うち鉄道車両・同修理", 3.35],
        ["17-0000", "精密機械", 2.96],
        ["17-0620", "精密機械", 2.96],
        ["18-0000", "その他の製造工業製品", 3.14],
        ["18-0190", "印刷・製版・製本", 2.76],
        ["18-0300", "プラスチック製品", 3.32],
        ["18-0310", "ゴム製品", 3.19],
        ["18-0320", "なめし革・毛皮・同製品", 3.22],
        ["18-0630", "その他の製造工業製品", 3.2],
        ["18-0640", "再生資源回収・加工処理", 3.51],
        ["19-0000", "建設", 3.41],
        ["19-0650", "建築", 3.39],
        ["19-0660", "建設補修", 3.35],
        ["19-0680", "土木", 3.43],
        ["20-0000", "電力・ガス・熱供給", 3.28],
        ["20-0690", "電力", 3.3],
        ["20-0700", "ガス・熱供給", 3.15],
        ["21-0000", "水道・廃棄物処理", 4.01],
        ["21-0710", "水道", 4.12],
        ["21-0720", "廃棄物処理", 3.83],
        ["22-0000", "商業", 3.35],
        ["22-0731", "卸売", 3.28],
        ["22-0732", "小売", 3.39],
        ["23-0000", "金融・保険", 1.84],
        ["23-0740", "金融・保険", 1.84],
        ["24-0000", "不動産", 3.77],
        ["24-0750", "不動産仲介及び賃貸", 3.42],
        ["24-0760", "住宅賃貸料", 3.79],
        ["25-0000", "運輸", 3.92],
        ["25-0780", "鉄道輸送", 4.2],
        ["25-0790", "道路輸送（除自家輸送）", 3.28],
        ["25-0810", "水運", 4.49],
        ["25-0820", "航空輸送", 2.98],
        ["25-0830", "貨物利用運送", 3.74],
        ["25-0840", "倉庫", 3.38],
        ["25-0850", "運輸付帯サービス", 4.31],
        ["25-0851", "うち水運施設管理", 4.15],
        ["25-0852", "うち航空施設管理", 4.1],
        ["26-0000", "情報通信", 3.12],
        ["26-0860", "通信", 3.31],
        ["26-0870", "放送", 3.46],
        ["26-0880", "情報サービス", 2.28],
        ["26-0890", "インターネット附随サービス", 3.43],
        ["26-0900", "映像・文字情報制作", 3.02],
        ["27-0000", "公務", 3.43],
        ["27-0911", "公務（中央）", 3.95],
        ["27-0912", "公務（地方）", 3.13],
        ["28-0000", "教育・研究", 3.77],
        ["28-0920", "教育", 3.86],
        ["28-0930", "研究", 3.61],
        ["29-0000", "医療・保健・社会保障・介護", 3.34],
        ["29-0940", "医療・保健", 3.29],
        ["29-0950", "社会保障", 3.76],
        ["29-0960", "介護", 3.72],
        ["30-0000", "その他公共サービス", 3.81],
        ["30-0970", "その他の公共サービス", 3.81],
        ["31-0000", "対事業所サービス", 3.13],
        ["31-0980", "広告", 2.29],
        ["31-0990", "物品賃貸サービス", 3.13],
        ["31-1000", "自動車・機械修理", 3.52],
        ["31-1010", "その他の対事業所サービス", 3.4],
        ["32-0000", "対個人サービス", 3.56],
        ["32-1020", "娯楽サービス", 3.71],
        ["32-1030", "飲食店", 3.28],
        ["32-1040", "宿泊業", 3.68],
        ["32-1050", "洗濯・理容・美容・浴場業", 3.46],
        ["32-1060", "その他の対個人サービス", 3.45],
        ["34-0000", "分類不明", 3.02],
        ["34-1080", "分類不明", 3.02],
        ["35-0000", "その他", 3.96],
        ["35-0001", "道路", 4.64],
        ["35-0002", "住宅", 3.43],
        ["35-0003", "環境衛生", 4.45],
        ["35-0004", "国土保全", 4.46],
        ["35-0005", "土地造成", 4.44],
      ],
    },

    //産連関表DB
    INDUSTRY_CONNECTION_TABLE_DB: {
      //noは1から
      name: [
        "value", //列コード
        "label", //部門名
        "material_base", //物量ベースの排出原単位
        "unit", //単位
        "cost_producer_base", //金額生産者価格ベース
        "cost_purchaser_base", //金額購入者価格ベース
      ],
      values: [
        ["11101", "米", "", "", "6.26", "5.37"],
        ["11102", "麦類", "", "", "6.04", "5.10"],
        ["11201", "いも類", "", "", "3.80", "2.85"],
        ["11202", "豆類", "", "", "5.52", "4.69"],
        ["11301", "野菜", "", "", "4.48", "3.28"],
        ["11401", "果実", "", "", "3.64", "2.66"],
        ["11501", "砂糖原料作物", "", "", "4.36", "3.98"],
        ["11502", "飲料用作物", "", "", "12.76", "9.47"],
        ["11509", "その他の食用耕種作物", "", "", "7.56", "6.06"],
        ["11601", "飼料作物", "", "", "10.02", "9.72"],
        ["11602", "種苗", "", "", "4.36", "3.55"],
        ["11603", "花き・花木類", "", "", "8.08", "5.35"],
        ["11609", "その他の非食用耕種作物", "", "", "4.45", "3.99"],
        ["12101", "酪農", "", "", "12.19", "11.11"],
        ["12102", "鶏卵", "", "", "8.54", "7.09"],
        ["12103", "肉鶏", "", "", "10.14", "9.56"],
        ["12104", "豚", "", "", "9.81", "8.85"],
        ["12105", "肉用牛", "", "", "15.43", "14.24"],
        ["12109", "その他の畜産", "", "", "6.25", "4.26"],
        ["13101", "獣医業", "", "", "1.59", "1.59"],
        ["13102", "農業サービス（除獣医業）", "", "", "3.53", "3.53"],
        ["21101", "育林", "", "", "0.50", "0.50"],
        ["21201", "素材", "", "", "1.98", "1.76"],
        ["21301", "特用林産物（含狩猟業）", "", "", "5.69", "4.37"],
        ["31101", "海面漁業", "", "", "9.68", "7.28"],
        ["31104", "海面養殖業", "", "", "5.16", "3.97"],
        ["31201", "内水面漁業・養殖業", "", "", "6.16", "4.30"],
        ["61101", "金属鉱物", "0.539", "含有量t", "7.95", "7.14"],
        ["62101", "窯業原料鉱物", "0.00653", "t", "8.07", "5.99"],
        ["62201", "砂利・採石", "0.0118", "t", "5.86", "3.32"],
        ["62202", "砕石", "6.69", "千t", "5.70", "3.78"],
        ["62909", "その他の非金属鉱物", "0.097", "t", "9.89", "8.04"],
        ["71101", "石炭・原油・天然ガス", "0.168", "t", "8.06", "7.56"],
        ["111101", "と畜（含肉鶏処理）", "6.04", "t", "10.51", "7.75"],
        ["111201", "肉加工品", "9.60", "t", "6.74", "5.38"],
        ["111202", "畜産びん・かん詰", "3.46", "t", "4.25", "3.06"],
        ["111203", "酪農品", "1.71", "kl", "7.72", "6.18"],
        ["111301", "冷凍魚介類", "3.13", "t", "5.68", "4.55"],
        ["111302", "塩・干・くん製品", "5.86", "t", "5.25", "3.77"],
        ["111303", "水産びん・かん詰", "6.02", "t", "4.97", "3.53"],
        ["111304", "ねり製品", "3.01", "t", "4.48", "3.69"],
        ["111309", "その他の水産食品", "5.02", "t", "4.00", "3.27"],
        ["111401", "精穀", "1.73", "t", "5.11", "4.26"],
        ["111402", "製粉", "0.922", "t", "10.73", "8.52"],
        ["111501", "めん類", "3.57", "t", "5.11", "4.13"],
        ["111502", "パン類", "3.91", "t", "4.15", "3.54"],
        ["111503", "菓子類", "4.89", "t", "3.86", "3.26"],
        ["111601", "農産びん・かん詰", "2.11", "t", "4.62", "3.22"],
        [
          "111602",
          "農産保存食料品（除びん・かん詰）",
          "1.78",
          "t",
          "3.33",
          "2.57",
        ],
        ["111701", "砂糖", "1.102", "t", "8.19", "5.85"],
        ["111702", "でん粉", "0.514", "t", "11.87", "9.07"],
        ["111703", "ぶどう糖・水あめ・異性化糖", "0.748", "t", "11.49", "9.15"],
        ["111704", "植物油脂", "0.948", "t", "11.42", "9.22"],
        ["111705", "動物油脂", "0.743", "t", "8.47", "7.31"],
        ["111706", "調味料", "2.22", "t", "4.10", "3.39"],
        ["111901", "冷凍調理食品", "2.47", "t", "5.00", "3.90"],
        ["111902", "レトルト食品", "3.57", "t", "4.75", "3.83"],
        ["111903", "そう菜・すし・弁当", "", "", "4.35", "3.51"],
        ["111904", "学校給食（国公立）★★", "", "", "3.55", ""],
        ["111905", "学校給食（私立）★", "", "", "3.59", ""],
        ["111909", "その他の食料品", "1.76", "t", "5.42", "4.21"],
        ["112101", "清酒", "", "", "2.80", "2.24"],
        ["112102", "ビール", "", "", "1.83", "1.60"],
        ["112103", "ウィスキー類", "", "", "2.16", "1.85"],
        ["112109", "その他の酒類", "", "", "2.60", "2.12"],
        ["112901", "茶・コーヒー", "9.35", "t", "4.14", "3.52"],
        ["112902", "清涼飲料", "0.760", "kl", "3.20", "2.63"],
        ["112903", "製氷", "0.118", "t", "4.95", "4.08"],
        ["113101", "飼料", "0.445", "t", "9.45", "7.76"],
        ["113102", "有機質肥料（除別掲）", "0.149", "t", "5.22", "4.15"],
        ["114101", "たばこ", "", "", "0.89", "0.89"],
        ["151101", "紡績糸", "4.00", "t", "6.35", "5.30"],
        [
          "151201",
          "綿・スフ織物（含合繊短繊維織物）",
          "1.54",
          "千m2",
          "7.36",
          "6.14",
        ],
        [
          "151202",
          "絹・人絹織物（含合繊長繊維織物）",
          "3.27",
          "千m2",
          "6.92",
          "5.57",
        ],
        [
          "151203",
          "毛織物・麻織物・その他の織物",
          "7.43",
          "千m2",
          "6.66",
          "5.46",
        ],
        ["151301", "ニット生地", "4.77", "t", "5.33", "4.97"],
        ["151401", "染色整理", "", "", "9.47", "9.47"],
        ["151901", "綱・網", "4.22", "t", "5.54", "4.50"],
        ["151902", "じゅうたん・床敷物", "5.86", "千m2", "6.06", "4.78"],
        ["151903", "繊維製衛生材料", "", "", "4.18", "3.55"],
        ["151909", "その他の繊維工業製品", "3.19", "t", "5.68", "5.13"],
        ["152101", "織物製衣服", "0.01198", "着", "4.01", "3.23"],
        ["152102", "ニット製衣服", "0.0523", "デカ", "4.64", "3.46"],
        ["152209", "その他の衣服・身の回り品", "0.681", "千点", "4.58", "3.35"],
        ["152901", "寝具", "", "", "3.91", "3.13"],
        ["152909", "その他の繊維既製品", "", "", "3.87", "3.08"],
        ["161101", "製材", "137.6", "千m3", "3.74", "3.08"],
        ["161102", "合板", "0.563", "m3", "4.21", "3.46"],
        ["161103", "木材チップ", "23.1", "千m3", "2.64", "2.24"],
        ["161909", "その他の木製品", "", "", "2.86", "2.40"],
        ["171101", "木製家具・装備品", "", "", "3.16", "2.51"],
        ["171102", "木製建具", "", "", "3.12", "2.37"],
        ["171103", "金属製家具・装備品", "", "", "5.16", "3.61"],
        ["181101", "パルプ", "1.019", "t", "19.40", "16.33"],
        ["181201", "洋紙・和紙", "1.83", "t", "15.45", "11.12"],
        ["181202", "板紙", "0.801", "t", "13.83", "9.99"],
        ["181301", "段ボール", "0.382", "千m2", "7.82", "6.32"],
        ["181302", "塗工紙・建設用加工紙", "", "", "6.05", "5.04"],
        ["182101", "段ボール箱", "", "", "3.83", "3.22"],
        ["182109", "その他の紙製容器", "0.234", "千袋", "4.84", "3.97"],
        ["182901", "紙製衛生材料・用品", "", "", "5.11", "3.95"],
        [
          "182909",
          "その他のパルプ・紙・紙加工品",
          "0.00148",
          "m2",
          "4.62",
          "3.79",
        ],
        ["191101", "印刷・製版・製本", "", "", "3.26", "3.04"],
        ["201101", "化学肥料", "0.719", "t", "17.36", "13.08"],
        ["202101", "ソーダ工業製品", "0.830", "t", "21.23", "17.93"],
        ["202901", "無機顔料", "1.86", "t", "9.75", "8.00"],
        ["202902", "圧縮ガス・液化ガス", "0.107", "千m3", "11.38", "9.31"],
        ["202903", "塩", "", "", "14.27", "10.51"],
        ["202909", "その他の無機化学工業製品", "0.350", "t", "13.73", "11.44"],
        ["203101", "石油化学基礎製品", "0.863", "t", "10.45", "9.98"],
        ["203102", "石油化学系芳香族製品", "0.526", "t", "8.95", "8.39"],
        ["203201", "脂肪族中間物", "1.65", "t", "14.96", "12.96"],
        ["203202", "環式中間物", "1.49", "t", "10.27", "9.84"],
        ["203301", "合成ゴム", "5.91", "t", "18.21", "15.87"],
        ["203901", "メタン誘導品", "1.26", "t", "15.33", "12.97"],
        ["203902", "油脂加工製品", "0.775", "t", "6.21", "5.02"],
        ["203903", "可塑剤", "1.61", "t", "10.18", "8.70"],
        ["203904", "合成染料", "14.2", "t", "13.63", "12.87"],
        ["203909", "その他の有機化学工業製品", "0.742", "t", "9.70", "8.27"],
        ["204101", "熱硬化性樹脂", "3.66", "t", "7.98", "6.86"],
        ["204102", "熱可塑性樹脂", "1.29", "t", "8.98", "7.72"],
        ["204103", "高機能性樹脂", "2.45", "t", "7.88", "6.78"],
        ["204109", "その他の合成樹脂", "2.80", "t", "8.96", "7.67"],
        ["205101", "レーヨン・アセテート", "7.76", "t", "16.63", "11.73"],
        ["205102", "合成繊維", "4.04", "t", "11.68", "9.75"],
        ["206101", "医薬品", "", "", "3.02", "2.56"],
        ["207101", "石けん・合成洗剤・界面活性剤", "1.60", "t", "5.46", "4.65"],
        ["207102", "化粧品・歯磨", "0.0195", "kg", "4.32", "3.50"],
        ["207201", "塗料", "2.30", "t", "6.28", "4.99"],
        ["207202", "印刷インキ", "3.52", "t", "5.64", "4.88"],
        ["207301", "写真感光材料", "0.00235", "m2", "6.55", "5.45"],
        ["207401", "農薬", "11.32", "t", "7.56", "5.86"],
        ["207901", "ゼラチン・接着剤", "0.00223", "kg", "6.15", "5.14"],
        ["207909", "その他の化学最終製品", "5.77", "t", "7.41", "6.36"],
        ["211101", "石油製品", "0.573", "kl", "8.60", "7.13"],
        ["212101", "石炭製品", "0.321", "t", "21.54", "19.54"],
        ["212102", "舗装材料", "", "", "4.25", "3.48"],
        ["221101", "プラスチック製品", "1.95", "t", "4.71", "4.00"],
        ["231101", "タイヤ・チューブ", "", "", "7.14", "6.11"],
        ["231901", "ゴム製履物", "4.94", "千足", "3.36", "2.72"],
        ["231902", "プラスチック製履物", "5.05", "千足", "4.35", "3.37"],
        ["231909", "その他のゴム製品", "1.64", "t", "4.56", "3.96"],
        ["241101", "革製履物", "0.0180", "足", "3.14", "2.57"],
        ["241201", "製革・毛皮", "0.0484", "枚", "6.73", "5.36"],
        [
          "241202",
          "かばん・袋物・その他の革製品",
          "0.0127",
          "個",
          "3.20",
          "2.74",
        ],
        ["251101", "板ガラス・安全ガラス", "0.0322", "m2", "5.81", "4.94"],
        ["251201", "ガラス繊維・同製品", "0.00269", "kg", "9.64", "8.00"],
        ["251909", "その他のガラス製品", "2.46", "t", "6.16", "5.39"],
        ["252101", "セメント", "0.758", "t", "137.71", "101.62"],
        ["252201", "生コンクリート", "0.316", "m3", "27.30", "19.53"],
        ["252301", "セメント製品", "0.232", "t", "10.45", "7.60"],
        ["253101", "陶磁器", "", "", "7.31", "5.78"],
        ["259901", "耐火物", "1.346", "t", "10.24", "7.92"],
        ["259902", "その他の建設用土石製品", "0.257", "t", "10.87", "7.71"],
        ["259903", "炭素・黒鉛製品", "", "", "8.45", "7.21"],
        ["259904", "研磨材", "6.92", "t", "6.25", "4.86"],
        ["259909", "その他の窯業・土石製品", "0.112", "t", "7.15", "5.99"],
        ["261101", "銑鉄", "1.88", "t", "72.59", "67.06"],
        ["261102", "フェロアロイ", "5.13", "t", "19.19", "17.81"],
        ["261103", "粗鋼（転炉）", "2.04", "t", "45.50", "43.74"],
        ["261104", "粗鋼（電気炉）", "0.711", "t", "14.33", "13.78"],
        ["2612011", "鉄屑", "", "", "0.00", ""],
        ["262101", "熱間圧延鋼材", "1.90", "t", "26.80", "23.18"],
        ["262201", "鋼管", "2.40", "t", "17.96", "15.06"],
        ["262301", "冷間仕上鋼材", "2.13", "t", "20.44", "17.70"],
        ["262302", "めっき鋼材", "1.42", "t", "15.50", "12.86"],
        ["263101", "鋳鍛鋼", "4.66", "t", "11.94", "11.18"],
        ["263102", "鋳鉄管", "3.40", "t", "12.80", "11.80"],
        ["263103", "鋳鉄品及び鍛工品（鉄）", "3.49", "t", "16.97", "15.80"],
        ["264901", "鉄鋼シャースリット業", "", "", "14.61", "13.46"],
        ["264909", "その他の鉄鋼製品", "", "", "9.94", "9.29"],
        ["271101", "銅", "4.49", "t", "10.17", "9.50"],
        ["271102", "鉛・亜鉛（含再生）", "1.48", "t", "9.30", "8.77"],
        ["271103", "アルミニウム（含再生）", "1.49", "t", "7.50", "6.41"],
        ["271109", "その他の非鉄金属地金", "13.5", "t", "8.26", "7.45"],
        ["2712011", "非鉄金属屑", "", "", "0.00", ""],
        ["272101", "電線・ケーブル", "8.70", "導体t", "6.28", "5.68"],
        ["272102", "光ファイバケーブル", "0.0556", "Kmcore", "6.27", "5.67"],
        ["272201", "伸銅品", "4.19", "t", "7.29", "6.03"],
        ["272202", "アルミ圧延製品", "5.33", "t", "11.01", "9.42"],
        ["272203", "非鉄金属素形材", "0.00529", "kg", "8.25", "7.26"],
        ["272204", "核燃料", "", "", "4.02", ""],
        ["272209", "その他の非鉄金属製品", "38.1", "t", "10.00", "8.29"],
        ["281101", "建設用金属製品", "1.99", "t", "8.74", "7.34"],
        ["281201", "建築用金属製品", "", "", "6.32", "5.26"],
        [
          "289101",
          "ガス・石油機器及び暖厨房機器",
          "0.261",
          "台",
          "6.87",
          "5.89",
        ],
        [
          "289901",
          "ボルト・ナット・リベット及びスプリング",
          "2.73",
          "t",
          "7.26",
          "5.61",
        ],
        ["289902", "金属製容器及び製缶板金製品", "2.00", "t", "6.50", "5.76"],
        [
          "289903",
          "配管工事付属品・粉末や金製品・道具類",
          "",
          "",
          "5.50",
          "4.55",
        ],
        ["289909", "その他の金属製品", "1.87", "t", "5.83", "5.12"],
        ["301101", "ボイラ", "109.8", "台", "3.22", "2.86"],
        ["301102", "タービン", "2,040", "台", "4.28", "3.83"],
        ["301103", "原動機", "0.436", "台", "5.11", "4.14"],
        ["301201", "運搬機械", "31.8", "台", "4.59", "4.19"],
        ["301301", "冷凍機・温湿調整装置", "0.203", "台", "7.19", "6.46"],
        ["301901", "ポンプ及び圧縮機", "0.388", "台", "4.96", "4.33"],
        ["301902", "機械工具", "", "", "4.65", "3.91"],
        [
          "301909",
          "その他の一般産業機械及び装置",
          "0.189",
          "台",
          "4.73",
          "4.33",
        ],
        ["302101", "建設・鉱山機械", "20.4", "台", "4.53", "4.08"],
        ["302201", "化学機械", "", "", "3.44", "3.27"],
        ["302301", "産業用ロボット", "", "", "3.84", "3.66"],
        ["302401", "金属工作機械", "46.1", "台", "3.70", "3.48"],
        ["302402", "金属加工機械", "44.1", "台", "4.42", "4.12"],
        ["302901", "農業用機械", "3.39", "台", "4.29", "3.88"],
        ["302902", "繊維機械", "0.320", "台", "4.25", "3.68"],
        ["302903", "食品機械・同装置", "4.40", "台", "5.04", "4.63"],
        ["302904", "半導体製造装置", "213", "台", "3.28", "2.96"],
        ["302905", "真空装置・真空機器", "1.62", "台", "4.43", "3.95"],
        ["302909", "その他の特殊産業用機械", "28.9", "台", "3.99", "3.82"],
        ["303101", "金型", "", "", "4.37", "3.97"],
        ["303102", "ベアリング", "", "", "6.87", "6.00"],
        [
          "303109",
          "その他の一般機械器具及び部品",
          "5.46",
          "個",
          "4.61",
          "4.10",
        ],
        ["311101", "複写機", "1.52", "台", "3.82", "3.31"],
        ["311109", "その他の事務用機械", "", "", "3.20", "2.72"],
        ["311201", "サービス用機器", "1.220", "台", "3.87", "3.46"],
        ["321101", "回転電気機械", "0.0915", "台", "5.37", "4.61"],
        ["321102", "変圧器・変成器", "0.394", "台", "5.26", "4.72"],
        ["321103", "開閉制御装置及び配電盤", "", "", "4.46", "4.08"],
        ["321104", "配線器具", "0.391", "千個", "3.77", "3.39"],
        ["321105", "内燃機関電装品", "0.0373", "台", "3.88", "3.42"],
        ["321109", "その他の産業用電気機器", "2.78", "台", "4.01", "3.61"],
        ["322101", "電子応用装置", "0.414", "台", "3.01", "2.71"],
        ["323101", "電気計測器", "", "", "2.74", "2.53"],
        ["324101", "電球類", "1.111", "千個", "3.22", "2.67"],
        ["324102", "電気照明器具", "11.28", "千個", "3.71", "3.14"],
        ["324103", "電池", "0.868", "千個", "5.82", "4.15"],
        ["324109", "その他の電気機械器具", "", "", "5.56", "5.03"],
        ["325101", "民生用エアコンディショナ", "0.307", "台", "4.12", "3.43"],
        [
          "325102",
          "民生用電気機器（除エアコン）",
          "0.1328",
          "台",
          "3.85",
          "3.15",
        ],
        ["331101", "ビデオ機器", "0.1231", "台", "3.83", "3.02"],
        ["331102", "電気音響機器", "", "", "3.50", "3.20"],
        ["331103", "ラジオ・テレビ受信機", "0.499", "台", "3.53", "3.45"],
        ["332101", "有線電気通信機器", "0.159", "台", "3.29", "2.81"],
        ["332102", "携帯電話機", "0.1699", "台", "3.32", "2.50"],
        ["332103", "無線電気通信機器（除携帯電話機）", "", "", "3.42", "3.12"],
        ["332109", "その他の電気通信機器", "", "", "2.97", "2.74"],
        ["333101", "パーソナルコンピュータ", "0.452", "台", "3.50", "3.37"],
        [
          "333102",
          "電子計算機本体（除パソコン）",
          "6.75",
          "台",
          "2.93",
          "2.78",
        ],
        ["333103", "電子計算機付属装置", "0.251", "台", "3.23", "3.09"],
        ["341101", "半導体素子", "0.122", "千個", "8.69", "7.88"],
        ["341102", "集積回路", "0.354", "千個", "4.28", "3.95"],
        ["342101", "電子管", "0.0385", "本", "5.93", "5.30"],
        ["342102", "液晶素子", "13.4", "千個", "4.49", "4.08"],
        ["342103", "磁気テープ・磁気ディスク", "", "", "5.29", "3.99"],
        ["342109", "その他の電子部品", "", "", "4.08", "3.83"],
        ["351101", "乗用車", "", "台", "4.44", ""],
        [
          "352101",
          "トラック・バス・その他の自動車",
          "7.43",
          "台",
          "4.53",
          "3.63",
        ],
        ["353101", "二輪自動車", "", "台", "3.97", ""],
        ["354101", "自動車車体", "7.80", "台", "6.05", "5.93"],
        ["354102", "自動車用内燃機関・同部分品", "0.918", "台", "4.96", "4.69"],
        ["354103", "自動車部品", "", "", "4.72", "4.52"],
        ["361101", "鋼船", "", "", "8.09", "6.96"],
        ["361102", "その他の船舶", "", "", "3.58", "3.27"],
        ["361103", "舶用内燃機関", "58.6", "台", "6.29", "5.91"],
        ["361110", "船舶修理", "", "", "4.43", "4.43"],
        ["362101", "鉄道車両", "", "", "5.45", "5.25"],
        ["362110", "鉄道車両修理", "", "", "7.82", "7.82"],
        ["362201", "航空機", "2,994", "機", "3.16", "2.95"],
        ["362210", "航空機修理", "", "", "2.86", "2.86"],
        ["362901", "自転車", "0.1224", "台", "6.54", "6.47"],
        ["362909", "その他の輸送機械", "11.9", "台", "4.95", "4.56"],
        ["371101", "カメラ", "1.199", "台", "3.00", "2.72"],
        ["371109", "その他の光学機械", "", "", "3.75", "3.22"],
        ["371201", "時計", "0.00283", "個", "3.58", "3.16"],
        ["371901", "理化学機械器具", "", "", "2.80", "2.42"],
        ["371902", "分析器・試験機・計量器・測定器", "", "", "2.81", "2.44"],
        ["371903", "医療用機械器具", "", "", "3.21", "2.49"],
        ["391101", "がん具", "", "", "3.88", "2.72"],
        ["391102", "運動用品", "", "", "4.33", "3.31"],
        ["391901", "楽器", "0.669", "個", "3.11", "2.46"],
        ["391902", "情報記録物", "", "", "2.63", "2.44"],
        ["391903", "筆記具・文具", "0.160", "千本", "3.20", "2.67"],
        ["391904", "身辺細貨品", "0.622", "万本", "4.30", "3.09"],
        ["391905", "畳・わら加工品", "", "", "4.52", "3.38"],
        ["391906", "武器", "1.70", "個", "3.87", "3.74"],
        ["391909", "その他の製造工業製品", "0.00733", "本", "3.30", "2.54"],
        ["392101", "再生資源回収・加工処理", "", "", "3.49", "3.49"],
        ["411101", "住宅建築（木造）", "", "m2", "2.98", ""],
        ["411102", "住宅建築（非木造）", "", "m2", "4.09", ""],
        ["411201", "非住宅建築（木造）", "", "m2", "3.17", ""],
        ["411202", "非住宅建築（非木造）", "", "m2", "4.24", ""],
        ["412101", "建設補修", "", "", "3.73", "3.73"],
        ["413101", "道路関係公共事業", "", "", "4.64", ""],
        ["413102", "河川・下水道・その他の公共事業", "", "", "4.45", ""],
        ["413103", "農林関係公共事業", "", "", "5.22", ""],
        ["413201", "鉄道軌道建設", "", "", "4.98", ""],
        ["413202", "電力施設建設", "", "", "4.62", ""],
        ["413203", "電気通信施設建設", "", "", "3.93", ""],
        ["413209", "その他の土木建設", "", "", "4.44", ""],
        ["511101", "事業用電力", "468", "百万kWh", "29.08", "29.08"],
        ["511104", "自家発電", "589", "百万kWh", "68.82", "68.82"],
        ["512101", "都市ガス", "0.497", "千m2", "5.57", "5.57"],
        ["512201", "熱供給業", "0.099", "GJ", "15.98", "15.98"],
        ["521101", "上水道・簡易水道", "", "", "1.50", "1.50"],
        ["521102", "工業用水", "", "", "1.63", "1.63"],
        ["521103", "下水道★★", "", "", "12.27", "12.27"],
        ["521201", "廃棄物処理（公営）★★", "", "", "16.37", "16.37"],
        ["521202", "廃棄物処理（産業）", "", "", "7.81", "7.81"],
        ["611101", "卸売", "", "", "1.24", "1.24"],
        ["611201", "小売", "", "", "2.28", ""],
        ["621101", "金融", "", "", "0.69", "0.69"],
        ["621201", "生命保険", "", "", "0.86", ""],
        ["621202", "損害保険", "", "", "0.73", "0.73"],
        ["641101", "不動産仲介・管理業", "", "", "1.15", "1.15"],
        ["641102", "不動産賃貸業", "", "", "1.07", "1.07"],
        ["642101", "住宅賃貸料", "", "", "0.57", ""],
        ["642201", "住宅賃貸料（帰属家賃）", "", "", "0.25", ""],
        ["711101", "鉄道旅客輸送", "", "", "3.02", "3.02"],
        ["711201", "鉄道貨物輸送", "", "", "4.90", "4.90"],
        ["712101", "バス", "", "", "3.28", "3.28"],
        ["712102", "ハイヤー・タクシー", "", "", "3.37", "3.37"],
        ["712201", "道路貨物輸送（除自家輸送）", "", "", "3.93", "3.93"],
        ["713101", "自家輸送（旅客自動車）", "", "", "12.21", "12.21"],
        ["713201", "自家輸送（貨物自動車）", "", "", "11.79", "11.79"],
        ["714101", "外洋輸送", "", "", "27.33", "27.33"],
        ["714201", "沿海・内水面輸送", "", "", "13.30", "13.30"],
        ["714301", "港湾運送", "", "", "1.74", "1.74"],
        ["715101", "航空輸送", "", "", "12.14", "12.14"],
        ["716101", "貨物利用運送", "", "", "1.56", "1.56"],
        ["717101", "倉庫", "", "", "2.33", "2.33"],
        ["718101", "こん包", "", "", "2.43", "2.43"],
        ["718901", "道路輸送施設提供", "", "", "1.38", "1.38"],
        ["718902", "水運施設管理★★", "", "", "1.95", "1.95"],
        ["718903", "その他の水運付帯サービス", "", "", "0.76", "0.76"],
        ["718904", "航空施設管理（国公営）★★", "", "", "2.07", "2.07"],
        ["718905", "航空施設管理（産業）", "", "", "2.62", "2.62"],
        ["718906", "その他の航空付帯サービス", "", "", "1.32", "1.32"],
        ["718909", "旅行・その他の運輸付帯サービス", "", "", "0.96", "0.96"],
        ["731101", "郵便・信書便", "", "", "1.19", "1.19"],
        ["731201", "固定電気通信", "", "", "1.16", "1.16"],
        ["731202", "移動電気通信", "", "", "0.85", "0.85"],
        ["731203", "その他の電気通信", "", "", "1.48", "1.48"],
        ["731909", "その他の通信サービス", "", "", "1.42", "1.42"],
        ["732101", "公共放送", "", "", "1.68", "1.68"],
        ["732102", "民間放送", "", "", "1.47", "1.47"],
        ["732103", "有線放送", "", "", "1.28", "1.28"],
        ["733101", "情報サービス", "", "", "1.01", "1.02"],
        ["734101", "インターネット附随サービス", "", "", "1.37", "1.37"],
        ["735101", "映像情報制作・配給業", "", "", "2.14", "2.14"],
        ["735102", "新聞", "", "", "3.68", "3.29"],
        ["735103", "出版", "", "", "2.88", "2.62"],
        ["735104", "ニュース供給・興信所", "", "", "1.01", "1.01"],
        ["811101", "公務（中央）★★", "", "", "1.74", "1.74"],
        ["811201", "公務（地方）★★", "", "", "1.45", "1.45"],
        ["821101", "学校教育（国公立）★★", "", "", "0.73", ""],
        ["821102", "学校教育（私立）★", "", "", "1.31", ""],
        ["821301", "社会教育（国公立）★★", "", "", "2.06", ""],
        ["821302", "社会教育（非営利）★", "", "", "2.99", ""],
        ["821303", "その他の教育訓練機関（国公立）★★", "", "", "4.83", ""],
        ["821304", "その他の教育訓練機関（産業）", "", "", "2.73", "2.73"],
        ["822101", "自然科学研究機関（国公立）★★", "", "", "3.71", "3.71"],
        ["822102", "人文科学研究機関（国公立）★★", "", "", "2.93", "2.93"],
        ["822103", "自然科学研究機関（非営利）★", "", "", "2.57", "2.57"],
        ["822104", "人文科学研究機関（非営利）★", "", "", "1.54", "1.54"],
        ["822105", "自然科学研究機関（産業）", "", "", "5.50", "5.50"],
        ["822106", "人文科学研究機関（産業）", "", "", "1.40", "1.40"],
        ["822201", "企業内研究開発", "", "", "2.63", "2.63"],
        ["831101", "医療（国公立）", "", "", "2.20", ""],
        ["831102", "医療（公益法人等）", "", "", "1.80", ""],
        ["831103", "医療（医療法人等）", "", "", "1.87", "1.87"],
        ["831201", "保健衛生（国公立）★★", "", "", "1.60", "1.60"],
        ["831202", "保健衛生（産業）", "", "", "2.01", "2.01"],
        ["831301", "社会保険事業（国公立）★★", "", "", "1.55", ""],
        ["831302", "社会保険事業（非営利）★", "", "", "1.80", ""],
        ["831303", "社会福祉（国公立）★★", "", "", "1.49", ""],
        ["831304", "社会福祉（非営利）★", "", "", "1.45", ""],
        ["831305", "社会福祉（産業）", "", "", "1.60", ""],
        ["831401", "介護（居宅）", "", "", "1.34", ""],
        ["831402", "介護（施設）", "", "", "1.60", ""],
        ["841101", "対企業民間非営利団体", "", "", "1.84", "1.84"],
        ["841102", "対家計民間非営利団体（除別掲）★", "", "", "1.23", ""],
        ["851101", "広告", "", "", "1.86", "1.86"],
        ["851201", "物品賃貸業（除貸自動車）", "", "", "0.89", "0.89"],
        ["851301", "貸自動車業", "", "", "1.04", "1.04"],
        ["851410", "自動車修理", "", "", "2.65", "2.65"],
        ["851510", "機械修理", "", "", "3.18", "3.18"],
        ["851901", "建物サービス", "", "", "0.83", "0.83"],
        ["851902", "法務・財務・会計サービス", "", "", "0.64", "0.64"],
        ["851903", "土木建築サービス", "", "", "1.30", "1.30"],
        ["851904", "労働者派遣サービス", "", "", "0.15", "0.15"],
        ["851909", "その他の対事業所サービス", "", "", "0.69", "0.69"],
        ["861101", "映画館", "", "", "3.25", ""],
        ["861102", "興行場（除別掲）・興行団", "", "", "1.45", "1.45"],
        ["861103", "遊戯場", "", "", "2.76", ""],
        ["861104", "競輪・競馬等の競走場・競技団", "", "", "1.66", ""],
        ["861105", "スポーツ施設提供業・公園・遊園地", "", "", "1.76", ""],
        ["861109", "その他の娯楽", "", "", "1.91", "1.91"],
        ["861201", "一般飲食店（除喫茶店）", "", "", "3.30", ""],
        ["861202", "喫茶店", "", "", "3.19", ""],
        ["861203", "遊興飲食店", "", "", "2.47", ""],
        ["861301", "宿泊業", "", "", "3.24", ""],
        ["861401", "洗濯業", "", "", "2.30", "2.30"],
        ["861402", "理容業", "", "", "1.39", ""],
        ["861403", "美容業", "", "", "1.21", "1.21"],
        ["861404", "浴場業", "", "", "5.02", ""],
        ["861409", "その他の洗濯・理容・美容・浴場業", "", "", "1.83", "1.83"],
        ["861901", "写真業", "", "", "1.46", "1.46"],
        ["861902", "冠婚葬祭業", "", "", "2.78", "2.78"],
        ["861903", "各種修理業（除別掲）", "", "", "2.19", "2.19"],
        ["861904", "個人教授業", "", "", "1.25", "1.25"],
        ["861909", "その他の対個人サービス", "", "", "1.29", "1.29"],
        ["890000", "事務用品", "", "", "5.40", "5.40"],
        ["900000", "分類不明", "", "", "3.29", "3.19"],
        ["909900", "内生部門計", "", "", "", ""],
        ["911000", "家計外消費支出（列）", "", "", "", ""],
        ["912100", "家計消費支出", "", "", "", ""],
        ["970000", "総合計", "", "", "", ""],
      ],
    },
  }

  inject("mockdata", mockdata)
}
