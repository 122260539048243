export default {
  doSetDrawer: (context, idtoken) => context.commit("SET_DRAWER", idtoken),
  doAddLoadingQueue: function (context, { key, value }) {
    context.commit("ADD_LOADING_QUEUE", {
      key: key,
      value: value,
    })
  },
  doRemoveLoadingQueue: function (context, key) {
    context.commit("REMOVE_LOADING_QUEUE", { key: key })
  },
}
