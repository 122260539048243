import { Amplify } from 'aws-amplify'

export default ({ $config }) => {
  const config = {
    Auth: {
      region: $config.cognitoRegion,
      userPoolId: $config.cognitoUserPoolId,
      userPoolWebClientId: $config.cognitoUserPoolClientId
    }
  }

  Amplify.configure(config)
}