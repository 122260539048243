import { Auth } from "aws-amplify"

export default function ({ $axios, store, redirect, $config }) {
  let co2 = localStorage.getItem('co2')
  let token

  if (co2 && !token) {
    token = (JSON.parse(co2))?.app?.token
  }
  $axios.onRequest((config) => {
    config.baseURL = $config.apiUrl
    if (token || store.app.token) {
      config.headers.Authorization = `Bearer ${token}` || `Bearer ${store.app.token}`
    }
  })

  $axios.onError(async (error) => {
    const code = parseInt(error.response && error.response.status)
    if (code === 403) {
      try {
        const data = await Auth.currentSession()
        token = data.accessToken.jwtToken
        store.commit('app/SET_TOKEN', token)
        error.config.headers.Authorization = token
        return await $axios.request(error.config);
      } catch (error) {
        console.error(error)
        store.commit('app/SET_TOKEN', '')
        localStorage.clear()
        $axios.setToken(false)
        window.location.reload()
      }
    }
    else if (code >= 400 && code < 500) {
      return Promise.reject(error)
    } else if (code >= 500) {
      redirect('/error')
    }
  })
}
