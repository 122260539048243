import moment from "moment"
moment.locale("ja")

import { isPlainObject, camelCase } from "lodash-es"
/**
 * 引数に値が設定されていないかの判定。
 *
 * @param value チェック対象
 * @returns 結果(true:設定なし、false：設定あり)
 */
const isNotParam = (value) => {
  let rtn = false

  if (value === null || value === "") {
    rtn = true
  }
  return rtn
}

// 共通関数
export default (_, inject) => {
  const utils = {
    formatDispDate(
      date,
      toFormat = "YYYY/MM/DD (dd)",
      fromFormat = "YYYY-MM-DD"
    ) {
      if (!date) return date
      try {
        const dateObj = moment(date, fromFormat)
        return dateObj.format(toFormat)
      } catch (e) {
        console.log("err:", e)
        return date
      }
    },
    addComma(num) {
      if (num == null) {
        return "";
      }
      var parts = String(num).toString().split(".");
      parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ",");
      return parts.join(".");
    },
    addBrackets(out, inner) {
      return out + " (" + inner + ")"
    },
    /**
     * @param baseMessage メッセージ
     * @param args 文字配列
     * @param original メッセージ返却フラグ
     * @example baseMessage："[0]しました。", args["ログイン"] ＝＞ "ログインしました。"
     * @returns 置き換えた後のメッセージ
     */
    editMessage(baseMessage, args, original = false) {
      if (isNotParam(baseMessage)) {
        return ""
      }

      if (isNotParam(args) || args.length === 0) {
        if (original) {
          return baseMessage
        } else {
          return ""
        }
      }

      let rtnMessage = baseMessage
      for (let i = 0; i < args.length; i++) {
        rtnMessage = rtnMessage.replace("[" + i + "]", args[i])
      }

      return rtnMessage
    },
    getImgStr(iamgeList) {
      let imageSrc = "/noimage.svg"
      if (iamgeList) {
        for (let i = 0; i < iamgeList.length; i++) {
          imageSrc = iamgeList[i].src
        }
      }
      return imageSrc
    },
    /**
     * キャメルケースへ変換 sampleString
     * @param string
     * @return string
     */
    camelCase(str) {
      str = str.charAt(0).toLowerCase() + str.slice(1)
      return str.replace(/[-_](.)/g, function (match, group1) {
        return group1.toUpperCase()
      })
    },
    /**
     * スネークケースへ変換 sample_string
     * @param string
     * @return string
     */
    snakeCase(str) {
      var camel = this.camelCase(str)
      return camel.replace(/[A-Z]/g, function (s) {
        return "_" + s.charAt(0).toLowerCase()
      })
    },
    toDoubleDigits(num) {
      num += ""
      if (num.length === 1) {
        num = "0" + num
      }
      return num
    },
    getCurrentDatetime(calc) {
      let objDate = new Date()
      if (calc) {
        objDate.setDate(objDate.getDate() + calc)
      }
      const _date =
        "" +
        this.toDoubleDigits(objDate.getFullYear()) +
        "-" +
        this.toDoubleDigits(objDate.getMonth() + 1) +
        "-" +
        this.toDoubleDigits(objDate.getDate())

      const _time =
        "" +
        this.toDoubleDigits(objDate.getHours()) +
        ":" +
        this.toDoubleDigits(objDate.getMinutes())

      return {
        dateStr: _date,
        timeStr: _time,
      }
    },
    getCurrentMonth(calc) {
      let objDate = new Date()
      if (calc) {
        objDate.setDate(objDate.getDate() + calc)
      }
      const _month =
        "" +
        this.toDoubleDigits(objDate.getFullYear()) +
        "-" +
        this.toDoubleDigits(objDate.getMonth() + 1)

      return {
        monthStr: _month,
      }
    },
    /**
     * YYYY-MMのフォマット
     */
    toYYYYMM(calc) {
      if (!calc || calc === "999999") return "";
      return [calc.slice(0, 4), calc.slice(4, 6)].join('-');
    },
    /**
     * 過去年月の確認
     */
    isPastMonth(value) {
      let objDate = new Date();
      let current = objDate.getFullYear().toString() +
        ("0" + (objDate.getMonth() + 1)).slice(-2);
      return parseInt(current) >= parseInt(value.replace("-", ""));
    },
    getCurrentMonth() {
      const d = new Date();
      let month = d.getMonth();
      let year = d.getFullYear();
      return [year, month].join('-');
    },
    /**
     * 受け取ったリストと複数のkeyから値を返す
     * @param list //検索対象リスト
     * @param keys //検索するキー
     * @return string
     */
    mockSelectValue(list, keys) {
      // 検索キーの数だけfilterをかけて抽出する
      let _filteredList = list
      let _returnValue = null
      for (let key in keys) {
        _filteredList = _filteredList.filter((item) => item[key] === keys[key])
      }

      if (_filteredList.length === 1) {
        _returnValue = _filteredList[0]["coef"]
      }
      return _returnValue
    },
    isEmpty(obj) {
      return obj == null || obj.length == 0
    },
    toNumber(val) {
      if (val == null || val == "") {
        return 0;
      }

      // コンマを取り消し
      if (typeof val == "string") {
        val = this.convertToHalfWidth(val).replace(/[^\d\.\-]/g, "");
      }
      //"11111.11111111111111111111"

      //11111.111111111111
      let num = Number(val);
      if (isNaN(num)) {
        return val;
      }
      return num;
    },
    round(val, precision) {
      if (precision === undefined || (!isNaN(precision) && (!val?.toString().includes('.') || val?.toString().split('.')?.[1]?.length < precision))) return this.toNumber(val)
      var step = Math.pow(10, precision);
      var num = Math.round(this.toNumber(val) * step) / step;
      return num;
    },
    formatNumber(val, precision) {
      var num = this.round(val, precision);
      return this.addComma(num);
    },
    formatFixed(val, precision) {
      var num = this.round(val, precision ?? 0);
      if (precision <= 0) {
        return this.addComma(num);
      }
      let largeNum = Math.sign(num) * Math.floor(Math.abs(num));
      let smallNum = num - largeNum;
      return `${this.addComma(largeNum)}.${smallNum.toFixed(precision).split(".")[1]}`;
    },
    convertToHalfWidth(value) {
      if (this.isEmpty(value)) {
        return value;
      }
      return String(value).replace(/[\uff01-\uff5e]/g, function (ch) { return String.fromCharCode(ch.charCodeAt(0) - 0xfee0); });
    },
    stringFormat() {
      if (arguments.length == 0) {
        return "";
      }
      var s = arguments[0];
      for (var i = 0; i < arguments.length - 1; i++) {
        var reg = new RegExp("\\{" + i + "\\}", "gm");
        s = s.replace(reg, arguments[i + 1]);
      }
      return s;
    },
    getDefaultValue(arr) {
      if (this.isEmpty(arr)) {
        return null;
      }
      var item = arr.find(item => item.defaultFlag === "1");
      if (item) {
        return item.value;
      }
      return null;
    },
    getByValue(arr, value) {
      if (this.isEmpty(arr)) {
        return null;
      }
      return arr.find(item => item.value === value);
    },
    generateId() {
      return Date.now().toString(36) + Math.random().toString(36).substring(2)
    },
    flatObject(obj, newObj = {}, currentKey = '') {
      for (let key in obj) {
        const currKey = currentKey ? camelCase(`${currentKey}_${key}`) : camelCase(key);
        if (isPlainObject(obj[key])) {
          this.flatObject(obj[key], newObj, currKey)
        } else {
          newObj[currKey] = obj[key];
        }
      }
      return newObj
    },
    calculateByFormula(item, formula) {
      let result = ''
      for (let i of formula) {
        let itemValue = item[i]
        // let precision
        // if (i === 'emissionIntensity') precision = 10
        // else precision = item[`${i}Precision`]
        if ('()+-*/'.includes(i) || !isNaN(i)) {
          result += i
          // 按分
        } else if (i === 'distribution' && !itemValue) {
          result += '1'
        } else if (itemValue !== undefined && itemValue !== null) {
          if (typeof itemValue === 'string' && itemValue.includes('/')) {
            result += itemValue
          } else {
            result += utils.toNumber(itemValue).toString()
          }
        }
      }
      try {
        // if (item.calculationMethodCode === '0132') console.log(result)
        const resultNumber = eval(result)
        if (resultNumber === Infinity) return 0
        return resultNumber
      } catch (e) {
        console.log(result, item)
        console.error(e)
        return null
      }
    },

    getPrecision(item) {
      let a = item['rules'].split("|")
      for (let i = 0; i < a.length; i++) {
        if (a[i].includes("length_decimal")) {
          return a[i].split(":")[1]
        }
      }
      return 3
    },

    preventScientificNotation(value) {
      if (value && value.toString().includes('e')) {
        const fractionDigits = value.toString().split('-')[1]
        return Number(value).toFixed(fractionDigits)
      } else {
        return value
      }
    },

    roundUp(num, precision) {
      precision = Math.pow(10, precision)
      return Math.ceil(num * precision) / precision
    }
  }

  inject("utils", utils)
}
