const setCodeTable = (_this, context) => {
  const _electricTable = {
    "00": _this.$mock.getElectricTable(),
    "01": _this.$mock.getElectricTableMenuTable(),
  }
  // const _industryConnMaterialCoefTable = _this.$mock.getIndustryConnMaterialCoefTable()
  // const _industryConnProducerCoefTable = _this.$mock.getIndustryConnProducerCoefTable()
  // const _industryConnPurchaserCoefTable = _this.$mock.getIndustryConnPurchaserCoefTable()
  const _industryConnectionTableList = {
    material_base: _this.$mock.getIndustryConnMaterialCoefTable(),
    cost_producer_base: _this.$mock.getIndustryConnProducerCoefTable(),
    cost_purchaser_base: _this.$mock.getIndustryConnPurchaserCoefTable(),
    all: _this.$mock.getIndustryConnCoefTable(),
  }
  const _transportTableList = {
    "02": _this.$mock.getTkAccumRateTable(),
  }
  const _capitalMoneyTable = _this.$mock.getCapitalMoneyTable()
  const _fuelSupplyTable = _this.$mock.getFuelSupplyTable()
  const _fuelTypeUsageCoefTable = _this.$mock.getFuelTypeUsageCoefTable()
  const _fuelTypeCalorificTable = _this.$mock.getFuelTypeCalorificTable()
  const _trashRecycleTransportTable = _this.$mock.getTrashRecycleTransportTable()
  const _trashRecycleNotTransportTable = _this.$mock.getTrashRecycleNotTransportTable()
  const _trashTypeTransportTable = _this.$mock.getTrashTypeTransportTable()
  const _trashTypeNotTransportTable = _this.$mock.getTrashTypeNotTransportTable()
  const _trashRecycleTableList = {
    recycleTransport: _trashRecycleTransportTable,
    recycleNotTransport: _trashRecycleNotTransportTable,
    typeTransport: _trashTypeTransportTable,
    typeNotTransport: _trashTypeNotTransportTable,
  }

  const _trafficFeeTable = _this.$mock.getTrafficFeeTable()
  const _fuelEconomyTable = {
    coef: _this.$mock.getFuelEconomyTable(),
    maximum_payload: _this.$mock.getMaximumPayloadList(),
  }

  context.commit("SET_ELECTRIC_TABLE", _electricTable)
  context.commit(
    "SET_INDUSTRY_CONNECTION_TABLE_LIST",
    _industryConnectionTableList
  )
  context.commit("SET_TRANSPORT_TABLE_LIST", _transportTableList)
  context.commit("SET_CAPITAL_MONEY_TABLE", _capitalMoneyTable)
  context.commit("SET_FUEL_SUPPLY_TABLE", _fuelSupplyTable)
  context.commit("SET_FUEL_TYPE_USAGE_COEF_TABLE", _fuelTypeUsageCoefTable)
  context.commit("SET_FUEL_TYPE_CALORIFIC_TABLE", _fuelTypeCalorificTable)
  context.commit("SET_TRASH_RECYCLE_TABLE_LIST", _trashRecycleTableList)
  context.commit("SET_TRAFFIC_FEE_TABLE", _trafficFeeTable)
  context.commit("SET_FUEL_ECONOMY_TABLE", _fuelEconomyTable)
}

export default {
  doSetCodeStore: function (context) {
    const _scopeList = [
      { label: "SCOPE1", value: 1 },
      { label: "SCOPE2", value: 2 },
      { label: "SCOPE3", value: 3 },
    ]
    const _divisionList = [
      { label: "拠点", value: "0" },
      { label: "事業部", value: "1" },
    ]

    const _categoryList = [
      {
        value: "01",
        label: "カテゴリ1",
        icon: "mdi-basket",
        title: "購入した製品・サービス",
        caption: "原材料・部品、容器・包装などが製造されるまでの活動に伴う排出",
      },
      {
        value: "02",
        label: "カテゴリ2",
        icon: "mdi-office-building",
        title: "資本財",
        caption: "自社の資本財の建設・製造に伴う排出",
      },
      {
        value: "03",
        label: "カテゴリ3",
        icon: "mdi-shovel",
        title: "スコープ１、２に含まれない燃料及びエネルギー活動",
        caption:
          "調達している燃料の上流工程（採掘、精製など）に伴う排出調達している電力の上流工程（発電に使用する燃料の採掘、精製など）に伴う排出",
      },
      {
        value: "04",
        label: "カテゴリ4",
        icon: "mdi-dump-truck",
        title: "輸送、配送(上流)",
        caption:
          "①報告対象年度に購入した製品・サービスのサプライヤーから自社への物流（輸送、荷役、保管）に伴う排出 ②報告対象年度に購入した①以外の物流サービス（輸送、荷役、保管）に伴う排出（自社が費用負担している物流に伴う排出）",
      },
      {
        value: "05",
        label: "カテゴリ5",
        icon: "mdi-trash-can",
        title: "事業から出る廃棄物",
        caption:
          "自社で発生した廃棄物の輸送(輸送は任意算定対象)、処理に伴う排出"
      },
      {
        value: "06",
        label: "カテゴリ6",
        icon: "mdi-airplane",
        title: "出張",
        caption: "従業員の出張に伴う排出",
      },
      {
        value: "07",
        label: "カテゴリ7",
        icon: "mdi-train-car",
        title: "雇用者の通勤",
        caption: "従業員が通勤する際の移動に伴う排出",
      },
      {
        value: "08",
        label: "カテゴリ8",
        icon: "mdi-car-clock",
        title: "リース資産(上流)",
        caption:
          "自社が賃借しているリース資産の操業に伴う排出（スコープ1、2で算定する場合を除く）",
      },
      {
        value: "09",
        label: "カテゴリ9",
        icon: "mdi-dump-truck",
        title: "輸送、配送(下流)",
        caption:
          "自社が販売した製品の最終消費者までの物流（輸送、荷役、保管、販売）に伴う排出（自社が費用負担していないものに限る。）",
      },
      {
        value: "10",
        label: "カテゴリ10",
        icon: "mdi-cogs",
        title: "販売した製品の加工",
        caption: "事業者による中間製品の加工に伴う排出",
      },
      {
        value: "11",
        label: "カテゴリ11",
        icon: "mdi-human-dolly",
        title: "販売した製品の使用",
        caption: "使用者（消費者・事業者）による製品の使用に伴う排出",
      },
      {
        value: "12",
        label: "カテゴリ12",
        icon: "mdi-trash-can",
        title: "販売した製品の廃棄",
        caption:
          "使用者（消費者・事業者）による製品の廃棄時の処理に伴う排出(輸送は任意算定対象)",
      },
      {
        value: "13",
        label: "カテゴリ13",
        icon: "mdi-car-clock",
        title: "リース資産(下流)",
        caption:
          "自社が賃貸事業者として所有し、他者に賃貸しているリース資産の運用に伴う排出",
      },
      {
        value: "14",
        label: "カテゴリ14",
        icon: "mdi-storefront",
        title: "フランチャイズ",
        caption: "フランチャイズ加盟者における排出",
      },
      {
        value: "15",
        label: "カテゴリ15",
        icon: "mdi-cash-multiple",
        title: "投資",
        caption: "投資の運用に伴う排出",
      }
    ]

    const _mockMasterCodelist = {
      scopeOne: [{ label: "算定・報告・公表制度", value: "00" }],
      scopeTwo: [
        { label: "電気の使用", value: "00" },
        { label: "熱の使用", value: "01" },
      ],
      electricUseCoefList: [
        { label: "基礎排出係数", value: "00" },
        { label: "調整後排出係数", value: "01" },
      ],
      transport: [
        {
          value: "00",
          label: "燃料法",
        },
        {
          value: "01",
          label: "燃費法",
        },
        {
          value: "02",
          label: "トンキロ法",
        },
      ],
      gasorin: [
        { label: "ガソリン", value: "00" },
        { label: "軽油", value: "01" },
      ],
      carUsecase: [
        { label: "営業用", value: "00" },
        { label: "自家用", value: "01" },
      ],
      transport_mode: [
        { label: "自動車", value: "00", coef: "" },
        { label: "鉄道", value: "01", coef: 22 },
        { label: "船舶", value: "02", coef: 39 },
        { label: "航空", value: "03", coef: 1490 },
      ],
      calc_amount_base: [
        { label: "金額(生産者価格)ベース", value: "cost_producer_base" },
        { label: "金額(購入者価格)ベース", value: "cost_purchaser_base" },
        { label: "物量ベース", value: "material_base" },
      ],
      capital_calc_method: [
        { label: "金額ベース", value: "00" },
        { label: "物量ベース", value: "01" },
      ],
      disposal_method: [
        { label: "焼却", value: "00" },
        { label: "埋立", value: "01" },
        { label: "リサイクル", value: "02" },
      ],
      business_trip_method: [
        { label: "移動時燃料使用量", value: "00" },
        { label: "交通費支給額", value: "01" },
        { label: "宿泊日数", value: "02" },
        { label: "従業員あたり出張日数", value: "03" },
        { label: "従業員数", value: "03" },
      ],
      work_come_method: [
        { label: "移動時燃料使用量", value: "00" },
        { label: "交通費支給額", value: "01" },
        { label: "従業員数・営業日数", value: "02" },
      ],
      lease_assets: [
        { label: "燃料消費", value: "00" },
        { label: "建物用途別燃料消費", value: "01" },
        { label: "建物単位面積あたり", value: "02" },
      ],
      processing: [
        { label: "販売先提供燃料消費量別", value: "00" },
        { label: "入手できない", value: "01" },
      ],
      trash_calc_method: [
        { label: "把握している", value: "00" },
        { label: "把握していない", value: "01" },
      ],
    }

    const _operatorList = [
      { label: "+", value: "00" },
      { label: "-", value: "01" },
      { label: "×", value: "02" },
      { label: "÷", value: "03" },
    ]

    setCodeTable(this, context)

    context.commit("SET_SCOPE_LIST", _scopeList)
    context.commit("SET_DIVISION_LIST", _divisionList)
    context.commit("SET_CATEGORY_LIST", _categoryList)
    context.commit("MOCK_MASTER_CODE_LIST", _mockMasterCodelist)
  },

  /** 拠点組織一覧にセット */
  doSetUnitList: function (context, value) { context.commit("SET_UNIT_LIST", value) },
  doSetBaseOrganizationList: function (context, value) { context.commit("SET_BASE_ORGANIZATION_LIST", value) },
  doSetOrganizationList: function (context, value) { context.commit("SET_ORGANIZATION_LIST", value) },
  doSetBaseList: function (context, value) { context.commit("SET_BASE_LIST", value) },
  doSetEmissionActivityList: function (context, value) { context.commit("SET_EMISSION_ACTIVITY_LIST", value) },
  doSetElectricCompanyList: function (context, value) { context.commit("SET_ELECTRIC_COMPANY_LIST", value) },
  doSetEmissionCoefficientList: function (context, value) { context.commit("SET_EMISSION_COEFFICIENT_LIST", value) },
  doSetEmissionIntensityList: function (context, value) { context.commit("SET_EMISSION_INTENSITY_LIST", value) },
  doSetTkgBasicUnitList: function (context, value) { context.commit("SET_TKG_BASIC_UNIT_LIST", value) },
  doSetCarFuelEconomyList: function (context, value) { context.commit("SET_CAR_FUEL_ECONOMY_LIST", value) },

  doSetDivisionTypeList: function (context, value) { context.commit("SET_DIVISION_TYPE_LIST", value) },
  doSetDivisionList: function (context, divisionList) { context.commit("SET_DIVISION_LIST", divisionList) },

  doSetLocationList: function (context, locationList) {
    context.commit("SET_LOCATION_LIST", locationList)
  },
  doSetLocationList: function (context) {
    const _locationList = [
      {
        value: "01",
        label: "本社",
        invalid: "0",
      },
      {
        value: "02",
        label: "大分工場",
        invalid: "0",
      },
      {
        value: "03",
        label: "研究開発センター",
        invalid: "0",
      },
    ]
    context.commit("SET_LOCATION_LIST", _locationList)
  },
  doSetDeptList: function (context, locationList) {
    const _deptList = [
      {
        loc_id: "01",
        dept_id: "honbu",
        value: "honbu",
        label: "管理本部",
        invalid: "0",
      },
      {
        loc_id: "01",
        dept_id: "daiichi",
        value: "daiichi",
        label: "第一事業部",
        invalid: "0",
      },
      {
        loc_id: "02",
        dept_id: "seizoubu",
        value: "seizoubu",
        label: "製造事業部",
        invalid: "1",
      },
      {
        loc_id: "03",
        dept_id: "kaihatsu",
        value: "kaihatsu",
        label: "研究開発事業部",
        invalid: "0",
      },
    ]
    context.commit("SET_DEPT_LIST", _deptList)
  },
  doSetHasMeisaiUpdateData: function (context, value) { context.commit("SET_MEISAI_UPDATE_DATA", value) },
  updateLocationList: function (context, { index, label }) {
    context.commit("UPDATE_LOCATION_LIST", { index, label })
  },
  updateDeptList: function (context, { index, label }) {
    context.commit("UPDATE_DEPT_LIST", { index, label })
  },
  updateLocationListInvalid: function (context, index) {
    context.commit("UPDATE_LOCATION_LIST_INVALID", index)
  },
  updateDeptListInvalid: function (context, index) {
    context.commit("UPDATE_DEPT_LIST_INVALID", index)
  },
  addLocationList: function (context, value) {
    context.commit("ADD_LOCATION_LIST", value)
  },
  addDeptList: function (context, value) {
    context.commit("ADD_DEPT_LIST", value)
  },
}
