export default {
  doSetActivityFilterCriteria: function (context, filterCriteria) {
    context.commit("SET_FILTER_CRITERIA", filterCriteria)
  },
  doSetTotalScore: function (context, score) { context.commit("SET_TOTAL_SCORE", score); },
  doSetTotalScorePerLocList: function (context) {
    let _returnTotalScorePerLocList = {}

    const _totalScorePerLocList = context.rootState.codes.locationList

    for (let i = 0; i < _totalScorePerLocList.length; i++) {
      let _key = _totalScorePerLocList[i]["value"]
      _returnTotalScorePerLocList[_key] = 0
    }

    context.commit("SET_TOTAL_SCORE_PER_LOC_LIST", _returnTotalScorePerLocList)
  },
  updateTotalScorePerLocList: function (context, { locId, score }) {
    context.commit("UPDATE_TOTAL_SCORE_PER_LOC_LIST", { locId, score })
  },
  doSetScopeOneActivityInputList: function (
    context,
    scopeOneActivityInputList
  ) {
    context.commit(
      "SET_SCOPE_ONE_ACTIVITY_INPUT_LIST",
      scopeOneActivityInputList
    )
  },
  doSetActivityInputList: function (context) {
    let _mockMasterCodeLabelList =
      context.rootGetters["codes/getMockMasterCodeLabelList"]
    let _scopeOneCalcList = _mockMasterCodeLabelList["scopeOne"]
  },
  doSetScreenItemList: function (context, value) { context.commit("SET_SCREEN_ITEM_LIST", value) },
  doSetScreenRelationList: function (context, value) { context.commit("SET_SCREEN_RELATION_LIST", value) },
  doSetCategoryGroupList: function (context, value) { context.commit("SET_CATEGORY_GROUP_LIST", value) },
  doSetglobalWarmingPotentialList: function (context, value) { context.commit("SET_GLOBAL_WARMING_POTENTIAL_LIST", value) },
}
