import ActivityService from "./services/ActivityService";
import OrganizationService from "./services/OrganizationService";
import CompanyService from "./services/CompanyService";
import DashboardService from "./services/DashboardService";
import UserSettingService from "./services/UserSettingService";
import SignService from "./services/SignService";
import FiscalYearService from "./services/FiscalYearService";
import MasterCSVExportService from "./services/MasterCSVExportService";

export default function ({ $axios }, inject) {
  const api = {
    activity: new ActivityService($axios),
    company: new CompanyService($axios),
    user: new UserSettingService($axios),
    signIn: new SignService($axios),
    organization: new OrganizationService($axios),
    dashboard: new DashboardService($axios),
    fiscalYear: new FiscalYearService($axios),
    masterregistercsvexport: new MasterCSVExportService($axios),
  }
  inject('api', api)
}