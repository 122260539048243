// CSV出力ヘッダー行
const EXPORT_HEADERS_MAP = new Map([
  ["date_from", "日付From"],
  ["date_to", "日付To"],
  ["loc", "拠点"],
  ["dept", "事業部"],
  ["scope", "SCOPE"],
  ["category", "カテゴリ"],
  ["detail_type", "明細区分"],
  ["activity_name", "明細名"],
  ["remark", "備考"],
  ["calc_source", "明細1"],
  ["value1", "明細2"],
  ["value2", "明細3"],
  ["value3", "明細4"],
  ["coef", "排出係数"],
  ["unit", "排出係数単位"],
  ["score", "排出量"],
  ["total_unit", "排出量単位"],
])

let _totalScorePerAllScope = 0

export default (context, inject) => {
  const mock = {
    // 出力用にmockdataのInputListを加工
    // API実装後置き換え
    getExportInputListScopeOne() {
      let _returnExportInputList = []
      let _totalScore = 0
      // 見出し行
      _returnExportInputList.push({
        scope: "SCOPE1",
        detail_type: "0",
        activity_name: "算定対象",
        calc_source: "算定方法",
        value1: "活動量",
      })

      const _inputList = context.app.$mockdata.scopeOneActivityInputList

      for (let i = 0; i < _inputList.length; i++) {
        let _details = {}
        let _activityValues = _inputList[i]["activity_values"]
        let _exportActivityValues = {}
        for (let j = 0; j < _activityValues.length; j++) {
          _exportActivityValues["value" + String(j + 1)] =
            String(_activityValues[j]["value"]) +
            (_activityValues[j]["suffix"] ? _activityValues[j]["suffix"] : "")
        }
        _details = {
          scope: "SCOPE1",
          detail_type: "1",
          activity_name: _inputList[i]["activity_name"],
          calc_source: _inputList[i]["calc_source"],
          ..._exportActivityValues,
          coef: _inputList[i]["coef"],
          unit: _inputList[i]["unit"],
          score: _inputList[i]["score"],
        }
        _totalScore += Number(_inputList[i]["score"])
        _returnExportInputList.push(_details)
      }

      _returnExportInputList.push({
        scope: "SCOPE1",
        detail_type: "2",
        activity_name: "合計",
        score: _totalScore,
      })

      _totalScorePerAllScope += Number(_totalScore)

      return _returnExportInputList
    },
    getExportInputListScopeTwo() {
      let _returnExportInputList = []
      let _totalScore = 0
      // 見出し行
      _returnExportInputList.push({
        scope: "SCOPE2",
        detail_type: "0",
        activity_name: "算定対象",
        calc_source: "算定方法",
        value1: "電力会社",
        value2: "供給メニュー",
        value3: "活動量",
      })

      const _inputList = context.app.$mockdata.scopeTwoActivityInputList

      for (let i = 0; i < _inputList.length; i++) {
        let _details = {}
        let _activityValues = _inputList[i]["activity_values"]
        let _exportActivityValues = {}
        for (let j = 0; j < _activityValues.length; j++) {
          _exportActivityValues["value" + String(j + 3)] =
            String(_activityValues[j]["value"]) +
            (_activityValues[j]["suffix"] ? _activityValues[j]["suffix"] : "")
        }
        _details = {
          scope: "SCOPE2",
          detail_type: "1",
          activity_name: _inputList[i]["activity_name"],
          calc_source: _inputList[i]["calc_source"],
          value1: _inputList[i]["electric_company"]
            ? _inputList[i]["electric_company"]
            : "-",
          value2: _inputList[i]["electric_menu"]
            ? _inputList[i]["electric_menu"]
            : "-",
          ..._exportActivityValues,
          coef: _inputList[i]["coef"],
          unit: _inputList[i]["unit"],
          score: _inputList[i]["score"],
        }
        _totalScore += Number(_inputList[i]["score"])
        _returnExportInputList.push(_details)
      }

      _returnExportInputList.push({
        scope: "SCOPE2",
        detail_type: "2",
        activity_name: "合計",
        score: _totalScore,
      })

      _totalScorePerAllScope += Number(_totalScore)

      return _returnExportInputList
    },
    getExportInputListScopeThree(filterCategoryInputs) {
      let _returnExportInputList = []
      let _totalScore = 0

      const _inputList = filterCategoryInputs

      for (let cateIndex = 0; cateIndex < _inputList.length; cateIndex++) {
        let categoryName = _inputList[cateIndex]["title"]

        let _headline = {
          scope: "SCOPE3",
          category: categoryName,
          detail_type: "0",
        }

        let category = _inputList[cateIndex]["category"]

        let addCnt = 1
        switch (category) {
          case "01":
            addCnt = 2
            _headline = {
              ..._headline,
              activity_name: "製品名",
              calc_source: "算定方法",
              value1: "部門名",
              value2: "活動量",
            }
            break
          case "02":
            addCnt = 2
            _headline = {
              ..._headline,
              activity_name: "資本財の種類",
              calc_source: "算定方法",
              value1: "資本財",
              value2: "活動量",
            }
            break
          case "03":
            addCnt = 2
            _headline = {
              ..._headline,
              activity_name: "活動名",
              calc_source: "算定方法",
              value1: "燃料種別",
              value2: "活動量",
            }
            break
          case "04":
            _headline = {
              ..._headline,
              activity_name: "活動名",
              calc_source: "算定方法",
              value1: "活動量1",
              value2: "活動量2",
              value3: "活動量3",
            }
            break
          case "05":
            addCnt = 3
            _headline = {
              ..._headline,
              activity_name: "活動名",
              calc_source: "算定方法(委託先での処理方法)",
              value1: "廃棄物種別",
              value2: "輸送段階",
              value3: "活動量",
            }
            break
          case "06":
            _headline = {
              ..._headline,
              activity_name: "活動名",
              calc_source: "算定方法",
              value1: "活動量",
            }
            break
          case "07":
            _headline = {
              ..._headline,
              activity_name: "活動名",
              calc_source: "算定方法",
              value1: "活動量",
            }
            break
          case "08":
            _headline = {
              ..._headline,
              activity_name: "活動名",
              calc_source: "算定方法",
              value1: "活動量",
            }
            break
          case "09":
            _headline = {
              ..._headline,
              activity_name: "活動名",
              calc_source: "算定方法",
              value1: "活動量1",
              value2: "活動量2",
              value3: "活動量3",
            }
            break
          case "10":
            _headline = {
              ..._headline,
              activity_name: "活動名",
              calc_source: "算定方法",
              value1: "活動量",
            }
            break
          case "11":
            _headline = {
              ..._headline,
              activity_name: "活動名",
              calc_source: "算定方法",
              value1: "活動量",
            }
            break
          case "12":
            addCnt = 3
            _headline = {
              ..._headline,
              activity_name: "活動名",
              calc_source: "算定方法(委託先での処理方法)",
              value1: "廃棄物種別",
              value2: "輸送段階",
              value3: "活動量",
            }
            break
          case "13":
            _headline = {
              ..._headline,
              activity_name: "活動名",
              calc_source: "算定方法",
              value1: "活動量",
            }
            break
          case "14":
            _headline = {
              ..._headline,
              activity_name: "活動名",
              calc_source: "算定方法",
              value1: "活動量",
            }
            break
          case "15":
            _headline = {
              ..._headline,
              activity_name: "活動名",
              calc_source: "算定方法",
              value1: "活動量",
            }
            break
          case "other":
            _headline = {
              ..._headline,
              activity_name: "活動名",
              calc_source: "算定方法",
              value1: "活動量",
            }
            break
        }
        _returnExportInputList.push(_headline)

        let _inputValues = _inputList[cateIndex]["input_values"]
        for (let ivIndex = 0; ivIndex < _inputValues.length; ivIndex++) {
          let _inputs = _inputValues[ivIndex]["inputs"]
          for (
            let inputsIndex = 0;
            inputsIndex < _inputs.length;
            inputsIndex++
          ) {
            let _details = {
              scope: "SCOPE3",
              category: categoryName,
              detail_type: "1",
              activity_name: _inputs[inputsIndex]["activity_name"],
              remark: _inputs[inputsIndex]["remark"]
                ? _inputs[inputsIndex]["remark"]
                : "",
              calc_source: _inputs[inputsIndex]["calc_source"],
              coef: _inputs[inputsIndex]["coef"],
              unit: _inputs[inputsIndex]["unit"],
              score: _inputs[inputsIndex]["score"],
            }
            _totalScore += Number(_inputs[inputsIndex]["score"])
            let _activityValues = _inputs[inputsIndex]["activity_values"]
            let _exportActivityValues = {}

            for (let avIndex = 0; avIndex < _activityValues.length; avIndex++) {
              _exportActivityValues["value" + String(avIndex + addCnt)] =
                _activityValues[avIndex]["labelKey"] +
                ":" +
                String(_activityValues[avIndex]["value"]) +
                (_activityValues[avIndex]["suffix"]
                  ? _activityValues[avIndex]["suffix"]
                  : "")
            }

            switch (category) {
              case "01":
                // 見出し行
                _details = {
                  ..._details,
                  value1: _inputs[inputsIndex]["department_name"]
                    ? _inputs[inputsIndex]["department_name"]
                    : "",
                  ..._exportActivityValues,
                }
                break
              case "02":
                _details = {
                  ..._details,
                  value1: _inputs[inputsIndex]["capital_name"]
                    ? _inputs[inputsIndex]["capital_name"]
                    : "",
                  ..._exportActivityValues,
                }
                break
              case "03":
                _details = {
                  ..._details,
                  value1: _inputs[inputsIndex]["fuel_type"]
                    ? _inputs[inputsIndex]["fuel_type"]
                    : "",
                  ..._exportActivityValues,
                }
                break
              case "04":
                _details = {
                  ..._details,
                  ..._exportActivityValues,
                }
                break
              case "05":
                _details = {
                  ..._details,
                  value1: _inputs[inputsIndex]["trash_type"]
                    ? _inputs[inputsIndex]["trash_type"]
                    : "",
                  value2: _inputs[inputsIndex]["transport_stage"]
                    ? _inputs[inputsIndex]["transport_stage"]
                    : "",
                  ..._exportActivityValues,
                }
                break
              case "06":
                _details = {
                  ..._details,
                  ..._exportActivityValues,
                }
                break
              case "07":
                _details = {
                  ..._details,
                  ..._exportActivityValues,
                }
                break
              case "08":
                _details = {
                  ..._details,
                  ..._exportActivityValues,
                }
                break
              case "09":
                _details = {
                  ..._details,
                  ..._exportActivityValues,
                }
                break
              case "10":
                _details = {
                  ..._details,
                  ..._exportActivityValues,
                }
                break
              case "11":
                _details = {
                  ..._details,
                  ..._exportActivityValues,
                }
                break
              case "12":
                _details = {
                  ..._details,
                  value1: _inputs[inputsIndex]["trash_type"]
                    ? _inputs[inputsIndex]["trash_type"]
                    : "",
                  value2: _inputs[inputsIndex]["transport_stage"]
                    ? _inputs[inputsIndex]["transport_stage"]
                    : "",
                  ..._exportActivityValues,
                }
                break
              case "13":
                _details = {
                  ..._details,
                  ..._exportActivityValues,
                }
                break
              case "14":
                _details = {
                  ..._details,
                  ..._exportActivityValues,
                }
                break
              case "15":
                _details = {
                  ..._details,
                  ..._exportActivityValues,
                }
                break
              case "other":
                _details = {
                  ..._details,
                  ..._exportActivityValues,
                }
                break
            }

            _totalScorePerAllScope += Number(_totalScore)

            _returnExportInputList.push(_details)
          }
        }
      }

      _returnExportInputList.push({
        scope: "SCOPE3",
        detail_type: "2",
        activity_name: "合計",
        score: _totalScore,
      })

      return _returnExportInputList
    },
    getExportInputList(
      dataFrom,
      dateTo,
      locName,
      deptName,
      selectScopeList,
      filterCategoryInputs
    ) {
      _totalScorePerAllScope = 0
      let _returnExportInputList = []
      let _exportInputListScopeOne = selectScopeList.includes(1)
        ? this.getExportInputListScopeOne()
        : []
      let _exportInputListScopeTwo = selectScopeList.includes(2)
        ? this.getExportInputListScopeTwo()
        : []
      let _exportInputListScopeThree = selectScopeList.includes(3)
        ? this.getExportInputListScopeThree(filterCategoryInputs)
        : []
      let _exportInputList = [
        ..._exportInputListScopeOne,
        ..._exportInputListScopeTwo,
        ..._exportInputListScopeThree,
        {
          scope: "",
          detail_type: "9",
          activity_name: "総合計",
          score: _totalScorePerAllScope,
        },
      ]

      for (let i = 0; i < _exportInputList.length; i++) {
        let _details = {}
        _exportInputList[i] = {
          date_from: dataFrom,
          date_to: dateTo,
          loc: locName,
          dept: deptName,
          ..._exportInputList[i],
        }
        if (
          _exportInputList[i]["score"] !== "" &&
          _exportInputList[i]["score"] !== null &&
          _exportInputList[i]["score"] !== undefined
        ) {
          _exportInputList[i]["total_unit"] = "t-CO2"
        }
        EXPORT_HEADERS_MAP.forEach(function (value, key) {
          let _headerKey = value
          if (!_exportInputList[i][key] && _exportInputList[i][key] !== 0) {
            _exportInputList[i][key] = ""
          }
          _details[_headerKey] = _exportInputList[i][key]
        })
        _returnExportInputList.push(_details)
      }

      return _returnExportInputList
    },
    // 産業連関表テーブル 物量ベース
    getIndustryConnMaterialCoefTable() {
      let _returnIndustryConnMaterialCoefTable = []

      const _industryConnectionTable =
        context.app.$mockdata.INDUSTRY_CONNECTION_TABLE_DB
      let _values = _industryConnectionTable["values"]
      for (let i = 0; i < _values.length; i++) {
        let _item = {}
        for (let j = 0; j < _values[i].length; j++) {
          let _key = _industryConnectionTable["name"][j]
          if (_key === "value" || _key === "unit" || _key === "label") {
            _item[_key] = _values[i][j]
          } else if (_key === "material_base") {
            _key = "coef"
            _item[_key] = _values[i][j]
          }
        }
        _returnIndustryConnMaterialCoefTable.push(_item)
      }

      return _returnIndustryConnMaterialCoefTable
    },

    // 産業連関表テーブル 金額(生産者)ベース
    getIndustryConnProducerCoefTable() {
      let _returnIndustryConnProducerCoefTable = []

      const _industryConnectionTable =
        context.app.$mockdata.INDUSTRY_CONNECTION_TABLE_DB
      let _values = _industryConnectionTable["values"]
      for (let i = 0; i < _values.length; i++) {
        let _item = {}
        for (let j = 0; j < _values[i].length; j++) {
          let _key = _industryConnectionTable["name"][j]
          if (_key === "value" || _key === "unit" || _key === "label") {
            _item[_key] = _values[i][j]
          } else if (_key === "cost_producer_base") {
            _key = "coef"
            _item[_key] = _values[i][j]
          }
        }
        _item["unit"] = "t-CO2eq/百万円"
        _returnIndustryConnProducerCoefTable.push(_item)
      }

      return _returnIndustryConnProducerCoefTable
    },

    // 産業連関表テーブル 金額(購入者)ベース
    getIndustryConnPurchaserCoefTable() {
      let _returnIndustryConnPurchaserCoefTable = []

      const _industryConnectionTable =
        context.app.$mockdata.INDUSTRY_CONNECTION_TABLE_DB
      let _values = _industryConnectionTable["values"]
      for (let i = 0; i < _values.length; i++) {
        let _item = {}
        for (let j = 0; j < _values[i].length; j++) {
          let _key = _industryConnectionTable["name"][j]
          if (_key === "value" || _key === "unit" || _key === "label") {
            _item[_key] = _values[i][j]
          } else if (_key === "cost_purchaser_base") {
            _key = "coef"
            _item[_key] = _values[i][j]
          }
        }
        _item["unit"] = "t-CO2eq/百万円"
        _returnIndustryConnPurchaserCoefTable.push(_item)
      }

      return _returnIndustryConnPurchaserCoefTable
    },
    // 産業連関表テーブル 金額(購入者)ベース
    getIndustryConnCoefTable() {
      let _returnIndustryConnCoefTable = []

      const _industryConnectionTable =
        context.app.$mockdata.INDUSTRY_CONNECTION_TABLE_DB
      let _values = _industryConnectionTable["values"]
      for (let i = 0; i < _values.length; i++) {
        let _item = {}
        for (let j = 0; j < _values[i].length; j++) {
          let _key = _industryConnectionTable["name"][j]
          _item[_key] = _values[i][j]
        }
        _returnIndustryConnCoefTable.push(_item)
      }

      return _returnIndustryConnCoefTable
    },

    getCapitalMoneyTable() {
      let _returnCapitalMoneyTable = []

      const _capitalMoneyTable = context.app.$mockdata.CAPITAL_MONEY_TABLE
      let _values = _capitalMoneyTable["values"]
      for (let i = 0; i < _values.length; i++) {
        let _item = {}
        for (let j = 0; j < _values[i].length; j++) {
          let _key = _capitalMoneyTable["name"][j]
          if (_key === "department_name") {
            _key = "label"
          }
          _item[_key] = _values[i][j]
        }
        _returnCapitalMoneyTable.push(_item)
      }

      return _returnCapitalMoneyTable
    },

    getFuelSupplyTable() {
      let _returnFuelSupplyTable = []

      const _fuelSupplyTable = context.app.$mockdata.FUEL_SUPPLY_TABLE
      let _values = _fuelSupplyTable["values"]
      for (let i = 0; i < _values.length; i++) {
        let _item = {}
        for (let j = 0; j < _values[i].length; j++) {
          let _key = _fuelSupplyTable["name"][j]
          if (_key === "fuel_name") {
            _key = "label"
          }
          _item[_key] = _values[i][j]
        }
        _returnFuelSupplyTable.push(_item)
      }

      return _returnFuelSupplyTable
    },

    getFuelTypeUsageCoefTable() {
      let _returnFuelTypeUsageCoefTable = []

      const _fuelTypeUsageCoefTable =
        context.app.$mockdata.FUEL_TYPE_USAGE_COEF_TABLE
      let _values = _fuelTypeUsageCoefTable["values"]

      for (let i = 0; i < _values.length; i++) {
        let _item = {}
        for (let j = 0; j < _values[i].length; j++) {
          let _key = _fuelTypeUsageCoefTable["name"][j]
          if (_key === "fuel_name") {
            _key = "label"
          }
          _item[_key] = _values[i][j]
        }
        _returnFuelTypeUsageCoefTable.push(_item)
      }

      return _returnFuelTypeUsageCoefTable
    },

    getFuelTypeCalorificTable() {
      let _returnFuelTypeCalorificTable = []

      const _fuelTypeCalorificTable =
        context.app.$mockdata.FUEL_TYPE_CALORIFIC_TABLE
      let _values = _fuelTypeCalorificTable["values"]

      for (let i = 0; i < _values.length; i++) {
        let _item = {}
        for (let j = 0; j < _values[i].length; j++) {
          let _key = _fuelTypeCalorificTable["name"][j]
          _item[_key] = _values[i][j]
        }
        _returnFuelTypeCalorificTable.push(_item)
      }

      return _returnFuelTypeCalorificTable
    },

    getTrashRecycleTransportTable() {
      let _returnTrashRecycleTransportTable = []

      const _trashRecycleTable = context.app.$mockdata.TRASH_RECYCLE_TABLE
      let _values = _trashRecycleTable["values"]

      for (let i = 0; i < _values.length; i++) {
        let _item = {}
        for (let j = 0; j < _values[i].length; j++) {
          let _key = _trashRecycleTable["name"][j]
          if (_key === "label" || _key === "value") {
            _item[_key] = _values[i][j]
          } else if (_key === "transport_coef") {
            _key = "coef"
            _item[_key] = _values[i][j]
          } else if (_key === "transport_unit") {
            _key = "unit"
            _item[_key] = _values[i][j]
          }
        }
        _returnTrashRecycleTransportTable.push(_item)
      }

      return _returnTrashRecycleTransportTable
    },
    getTrashRecycleNotTransportTable() {
      let _returnTrashRecycleNotTransportTable = []

      const _trashRecycleTable = context.app.$mockdata.TRASH_RECYCLE_TABLE
      let _values = _trashRecycleTable["values"]

      for (let i = 0; i < _values.length; i++) {
        let _item = {}
        for (let j = 0; j < _values[i].length; j++) {
          let _key = _trashRecycleTable["name"][j]
          _item[_key] = _values[i][j]
        }
        _returnTrashRecycleNotTransportTable.push(_item)
      }

      return _returnTrashRecycleNotTransportTable
    },
    getTrashTypeTransportTable() {
      let _returnTrashTypeTransportTable = []

      const _trashTypeTable = context.app.$mockdata.TRASH_TYPE_TABLE
      let _values = _trashTypeTable["values"]

      for (let i = 0; i < _values.length; i++) {
        let _item = {}
        for (let j = 0; j < _values[i].length; j++) {
          let _key = _trashTypeTable["name"][j]
          if (_key === "unit" || _key === "label" || _key === "value") {
            _item[_key] = _values[i][j]
          } else if (_key === "transport_coef") {
            _key = "coef"
            _item[_key] = _values[i][j]
          }
        }
        _returnTrashTypeTransportTable.push(_item)
      }

      return _returnTrashTypeTransportTable
    },
    getTrashTypeNotTransportTable() {
      let _returnTrashTypeNotTransportTable = []

      const _trashTypeTable = context.app.$mockdata.TRASH_TYPE_TABLE
      let _values = _trashTypeTable["values"]

      for (let i = 0; i < _values.length; i++) {
        let _item = {}
        for (let j = 0; j < _values[i].length; j++) {
          let _key = _trashTypeTable["name"][j]
          _item[_key] = _values[i][j]
        }
        _returnTrashTypeNotTransportTable.push(_item)
      }

      return _returnTrashTypeNotTransportTable
    },

    getTkAccumRateTable() {
      let _returnTkAccumRateTable = []

      const tkAccumRateTable = context.app.$mockdata.TK_ACCUM_RATE_TABLE
      let _values = tkAccumRateTable["values"]

      for (let i = 0; i < _values.length; i++) {
        let _item = {}
        for (let j = 0; j < _values[i].length; j++) {
          let _key = tkAccumRateTable["name"][j]
          if (_key === "value" || _key === "coef" || _key === "label") {
            _item[_key] = _values[i][j]
          }
        }
        _returnTkAccumRateTable.push(_item)
      }

      return _returnTkAccumRateTable
    },

    getTrafficFeeTable() {
      let _returnTrafficFeeTable = []

      const trafficFeeTable = context.app.$mockdata.TRAFFIC_FEE_TABLE
      let _values = trafficFeeTable["values"]

      for (let i = 0; i < _values.length; i++) {
        let _item = {}
        for (let j = 0; j < _values[i].length; j++) {
          let _key = trafficFeeTable["name"][j]
          _item[_key] = _values[i][j]
        }
        _returnTrafficFeeTable.push(_item)
      }

      return _returnTrafficFeeTable
    },

    getElectricTable() {
      let _returnElectricTable = []

      const _electricTable = context.app.$mockdata.ELECTRIC_TABLE

      let _values = _electricTable["values"]

      for (let i = 0; i < _values.length; i++) {
        let _item = {}
        // 電力会社はvalueとlabelを組み合わせてlabelとする
        let _value = ""
        for (let j = 0; j < _values[i].length; j++) {
          let _key = _electricTable["name"][j]
          _item[_key] = _values[i][j]
          if (_key == "value") {
            _value = _values[i][j]
          } else if (_key == "label") {
            _item[_key] = _value + "  " + _values[i][j]
          }
        }
        _returnElectricTable.push(_item)
      }

      return _returnElectricTable
    },
    getElectricTableMenuTable() {
      let _returnElectricTableMenuTable = []

      const electricTableMenuTable =
        context.app.$mockdata.ELECTRIC_TABLE_MENU_TABLE
      let _values = electricTableMenuTable["values"]

      for (let i = 0; i < _values.length; i++) {
        let _item = {}
        for (let j = 0; j < _values[i].length; j++) {
          let _key = electricTableMenuTable["name"][j]
          _item[_key] = _values[i][j]
        }
        _returnElectricTableMenuTable.push(_item)
      }

      return _returnElectricTableMenuTable
    },

    getFuelEconomyTable() {
      let _returnFuelEconomyTable = []
      const _fuelEconomyTable = context.app.$mockdata.FUEL_ECONOMY_TABLE
      let _values = _fuelEconomyTable["values"]

      for (let i = 0; i < _values.length; i++) {
        let _item = {}
        for (let j = 0; j < _values[i].length; j++) {
          let _key = _fuelEconomyTable["name"][j]
          _item[_key] = _values[i][j]
        }
        _returnFuelEconomyTable.push(_item)
      }

      return _returnFuelEconomyTable
    },

    getFuelEconomySpecificTable() {
      let _returnFuelEconomySpecificTable = []
      const _fuelEconomySpecificTable =
        context.app.$mockdata.FUEL_ECONOMY_SPECIFIC_TABLE
      let _values = _fuelEconomySpecificTable["values"]

      for (let i = 0; i < _values.length; i++) {
        let _item = {}
        for (let j = 0; j < _values[i].length; j++) {
          let _key = _fuelEconomySpecificTable["name"][j]
          _item[_key] = _values[i][j]
        }
        _returnFuelEconomySpecificTable.push(_item)
      }

      return _returnFuelEconomySpecificTable
    },

    getMaximumPayloadList() {
      let _returnMaximumPayloadList = { 0: [], 1: [] }
      const _fuelEconomySpecificTable =
        context.app.$mockdata.FUEL_ECONOMY_SPECIFIC_TABLE
      let _values = _fuelEconomySpecificTable["values"]

      for (let i = 0; i < _values.length; i = i + 2) {
        let _item = {}
        let _target = ""
        for (let j = 0; j < _values[i].length; j++) {
          let _key = _fuelEconomySpecificTable["name"][j]
          if (_key === "maximum_value") {
            _key = "value"
            _item[_key] = _values[i][j]
          } else if (_key === "maximum_label") {
            _key = "label"
            _item[_key] = _values[i][j]
          } else if (_key === "fuel_type") {
            _target = _values[i][j]
          }
        }
        _returnMaximumPayloadList[_target].push(_item)
      }

      return _returnMaximumPayloadList
    },
  }

  inject("mock", mock)
}
